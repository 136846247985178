import React , {  useState, useEffect } from "react";
import  UserSidbar from '../../components/Layout/UserSidbar';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from "../../redux/action/Auth";
import { PostContactUs } from "../../redux/action/UserApi";
import { toast } from "react-toastify";
import {  useNavigate} from "react-router-dom";
import Loader from "../Pages/Loader";


  const HelpAndSupport = ({ isAuthenticated = false, user }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [category, setCategory] = useState("")
    const [user_message, setMessage] = useState("")
    

    const { message, error, loading } = useSelector(
      state => state.user
    );

    useEffect(() => {
      if(!isAuthenticated){
        navigate('/');
      }else{

      }

      if (error) {
        toast.error(error);
        dispatch({ type: 'clearError' });
      }
  
      if (message) {
        toast.success(message);
        dispatch({ type: 'clearMessage' });
        var postUserdata = {
        userId: localStorage.getItem('userId'),
        hash_token:localStorage.getItem('hashToken')
        };

        dispatch(loadUser(postUserdata));
      }

   
    }, [navigate,dispatch,isAuthenticated, error, message]);

  
    const inputChangeHandler = async (setFunction, event) => {
      setFunction(event.target.value)
    }


   let userId = localStorage.getItem('userId');

   const onSubmitHandler = (e) => {
    e.preventDefault();
    if(formValidate() && userId){
      var postcommentdata = {
        subject: category,
        description: user_message,
        email: user.email,
        mobile:user.mobile_no,
        userId:userId,
        hash_token: localStorage.getItem('hashToken'),
      }; 
    var myData=  dispatch(PostContactUs(postcommentdata));
     if(myData){
        toast.success('Your request has been submitted successfully !');
     }

    }
  }
  

  const formValidate=()=>{
    let result =true;

      if(category==='' || category===null){
        result =false;
        toast.error("Please select category.");    
      } 
      else if(user_message==='' || user_message===null){
        result =false;
        toast.error("Please enter message.");    
      } 
      
    return result;
  }

return (
<>
<section className="wallet_page">
   <div className="container-fluid">
      <div className="row">
         <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <UserSidbar user={user} />
         </div>
         <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12 tabs_bar_user">
            { loading ? 
            <Loader />
            :
            <form onSubmit={onSubmitHandler}>
               <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                     <div className="card">
                     <div className="card-header"><b>Contact Us</b>
                      <div>Leave us a message, we will get in contact with you as soon as possible.</div>
                     </div>

                        <div className="card-body">

                           <div className="row mt-2 ">
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <label>Choose a category <span className="text-danger">*</span></label>
                                    <select className="form-control" onChange={(e)=>inputChangeHandler(setCategory, e)} >
                                        <option>Payment related issue</option>
                                        <option>Astrologer related issue</option>
                                        <option>App related issue</option>
                                        <option>Others</option>
                                    </select>
                                 </div>
                              </div>
                           </div>

                           <div className="row mt-2 pt-2">
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <textarea type="text" rows="5" className="form-control" onChange={(e)=>inputChangeHandler(setMessage, e)} name=""  placeholder="What do you tell to us about ? *"></textarea>
                                 </div>
                              </div>
                           </div>

                           <div className="row mt-2 pt-4">
                              <div className="col-md-12">
                                 <button className="btn btn-primary primary-button pull-right">Submit</button>
                              </div>
                           </div>


                        </div>
                     </div>
                  </div>
                  <div className="col-md-1"></div>
               </div>
            </form>
            }
         </div>
      </div>
   </div>
</section>

</>
);
}

export default HelpAndSupport;