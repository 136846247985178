import React , {  useState, useEffect, useCallback } from "react";
import  UserSidbar from '../../components/Layout/UserSidbar';
// import { NavLink, useNavigate, useParams} from "react-router-dom";
import {  useNavigate} from "react-router-dom";
import UserService from '../../services/UserServices'
import PaytmService from '../../services/PaytmService'
import { toast } from "react-toastify";
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from 'react-redux';
import {getUserwallet, addMoney  } from "../../redux/action/UserApi";
import Loader from "../Pages/Loader";


  const Wallet = ({ isAuthenticated = false, user }) => {


    const { coupunLists, rechargeLists, walletAmount, paymentHistory, message, error, loading } = useSelector(
      state => state.user
    );

// function Wallet() {
  const Razorpay = useRazorpay();

  // const [coupunlists, setCoupunlists] = useState([]);
  // const [rechargelists, setRechargelists] = useState([]);
  // const [walletamount, setWalletamount] = useState('0');
  // const [payment_history, setPayment_history] = useState([]);

  const [recharge_price, setRecharge_price] = useState('0');
  const [gst, setGst] = useState('0');
  const [total_recharge_amount, setTotal_recharge_amount] = useState('0');

  const [coupons_id, setCoupons_id] = useState('');
  const [coupon_value, setCoupon_value] = useState('');
  const [coupon_title, setCoupon_title] = useState('');

  // const { isAuthenticated,  user} = useSelector(
  //   state => state.custom
  // );

  // console.log(user);
  

  const [paymentPaytmData, setPaymentPaytmData] = useState({
    token: "", 
    order: '', 
    mid: 'yqVfTx48233240873220',
    amount: total_recharge_amount
  });


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const walletDetails = useCallback(() => {

 //   const walletDetails = () => {
      var postdata = {
          userId: localStorage.getItem('userId'),
          hash_token: localStorage.getItem('hashToken'),
      };

      dispatch(getUserwallet(postdata));

      setRecharge_price('0');
      setGst('0');
      setTotal_recharge_amount('0');
      setCoupons_id('');
      setCoupon_value('');
      setCoupon_title('')    

    //   UserService.getUserwallet(postdata)
    //   .then(function (response) {

    //     if (response.data.responseCode==='200') {
    //       setCoupunlists(response.data.couponlist);
    //       setRechargelists(response.data.walletRecharge );
    //       setWalletamount(response.data.amount);
    //       setPayment_history(response.data.payment_history);
    //     }else{
    //       toast.error(response.data.responseMessage);
    //       navigate('/');

    //     }
      
    //     //handle success
    //  console.log(response.data);

    //   })
    //   .catch(function (response) {
    //     //handle error
    //     console.log(response);
    //   });
 }, [dispatch]);

    useEffect(() => {
      if(!isAuthenticated){
        navigate('/');
      }else{

        walletDetails();
      }
      // console.log('i fire once');

      if (error) {
        toast.error(error);
        dispatch({ type: 'clearError' });
      }
  
      if (message) {
        toast.success(message);
        dispatch({ type: 'clearMessage' });
      }

      


    }, [navigate,dispatch,walletDetails,isAuthenticated, error, message]);

  

  
    var postpaydata = {
      userId: localStorage.getItem('userId'),
      hash_token: localStorage.getItem('hashToken'),
      amount: recharge_price,
      gst: gst,
      transaction_id: '',
      status: 'success',
      razorpay_response: 'success',
      payment_by: '',
      coupon_id: coupons_id,
      discount: coupon_value,
      };



    function myFunction(e) {
          var totalgst = ((e*18)/100).toFixed(2);
          var total_amount = (Number(totalgst) + Number(e)).toFixed(2);
          setRecharge_price(e);
          setGst(totalgst);
          setTotal_recharge_amount(total_amount);
          setCoupons_id('');
          setCoupon_value('');
          setCoupon_title('')    
    }
   
    function couponApply(couponsTitle) {
     if(recharge_price > 0){
      var postcoupondata = {
        userId: localStorage.getItem('userId'),
        hash_token: localStorage.getItem('hashToken'),
        coupon_code: couponsTitle,
      };
  
      UserService.checkCoupon(postcoupondata)
      .then(function (response) {

        if (response.data.responseCode==='200') {
          // console.log(recharge_price);
          // console.log(response.data.coupon.min_amount);
          // console.log(response.data.coupon.max_amount);
         var minAmount=Number(response.data.coupon.min_amount).toFixed(0);
         var maxAmount=Number(response.data.coupon.max_amount).toFixed(0);
            if(recharge_price  >= minAmount && maxAmount >= recharge_price){
              if(response.data.coupon.discount_type==="percent"){
                    var discountValue = (recharge_price * response.data.coupon.discount_value)/100;
                    console.log(discountValue);
                    setCoupon_value(discountValue);
                  }else{
                    ///console.log(couponsValue);
                      setCoupon_value(response.data.coupon.discount_value);
                  }

                  setCoupon_title(response.data.coupon.title)
                  setCoupons_id(response.data.coupon.id);
                  toast.success(response.data.responseMessage);
                }else{
                  toast.error('coupon code not for this recharge');
                  setCoupons_id('');
                  setCoupon_value('');
                  setCoupon_title('')
                }
        }else{
          toast.error(response.data.responseMessage);
        }
          //handle success
          console.log(response.data.coupon);
      })
      .catch(function (response) { //handle error
        console.log(response);
      });


      // setCoupon_value(couponsValue);
     } else{
        toast.error('please select recharge amount');
        setCoupons_id('');
        setCoupon_value('');
        setCoupon_title('')

     }

    }
    


    let orderId = 'Order_'+new Date().getTime();
// console.log(user.mobile_no);
    var postPaytmdata = {
      ORDER_ID:  orderId ,
      TXN_AMOUNT: total_recharge_amount,
      CUST_ID: localStorage.getItem('userId'),
      mobile:user.mobile_no,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
    };

    const handlePayClick = async (e) => {
    //  setIsLoading(true);
    e.preventDefault()
    var mode =e.target.payment_type.value;

      if(total_recharge_amount>0){
       
            switch(mode){
                  case "paytm":
              //  console.log(postPaytmdata)

                        PaytmService.create_checksum(postPaytmdata)
                        .then(function (res) {
                           setPaymentPaytmData({
                                ...paymentPaytmData,
                                token: res.data.CHECKSUMHASH, 
                                order: res.data.ORDER_ID
                            })

                         postpaydata.payment_by=mode;
                         makePaytmPayment();

                        })
                        .catch(function (response) {
                        console.log(response);
                        });


                  break;

                  case "razorpay":


                      const options = {
                        key: 'rzp_test_Noh7ZNsqGOvGsS',
                        amount: total_recharge_amount*100,
                        // amount: total_recharge_amount,
                        currency: "INR",
                        name: "T-Shirt",
                        description: "This is business!",
                        image:"http://localhost:3000/assets/images/logo-main.svg",
                    //   order_id: "order_9A33XWu170gYtm",
                        handler: function (response) {
                            console.log(response);

                            postpaydata.transaction_id= response.razorpay_payment_id;
                            postpaydata.payment_by=mode;
                            // console.log('razorpay')
                            // console.log(postpaydata)
                            walletPaymentUpdate();

                            //   alert(response.razorpay_payment_id);
                            //   alert(response.razorpay_order_id);
                            //   alert(response.razorpay_signature);
                        },
                        theme: {
                          color: "#32a86d",
                        },
                      };

                      const rzpay = new Razorpay(options);
                    //   const rzpay = new window.Razorpay(options);
                      rzpay.open();
                     

                  break;

                  default:

            }
      }else{
        toast.error('please select recharge amount');

      }
    };


  // console.log(total_recharge_amount);  
 const walletPaymentUpdate = () => {
    
  
  dispatch(addMoney(postpaydata));

    //   UserService.addMoney(postpaydata)
    //   .then(function (response) {

    //     if (response.data.responseCode==='200') {
    //       // $response["responseCode"] = "200";
    //       // $response["responseMessage"] = "Recharged successfully";
    //       // $response["updatedBal"] = $updateAmount['balance'];
    //       toast.success(response.data.responseMessage);
    //       // navigate('/user/wallet');
    //       walletDetails();

    //     }else{
    //       toast.error(response.data.responseMessage);

    //     }
      
    //     //handle success
    //  console.log(response.data);

    //   })
    //   .catch(function (response) {
    //     //handle error
    //     console.log(response);
    //   });
 };


 
 const makePaytmPayment = () => {
  // setLoading(true);
  var config = {
      "root":"",
      "style": {
        "bodyBackgroundColor": "#fafafb",
        "bodyColor": "",
        "themeBackgroundColor": "#FF7010",
        "themeColor": "#ffffff",
        "headerBackgroundColor": "#FF7010",
        "headerColor": "#ffffff",
        "errorColor": "",
        "successColor": "",
        "card": {
          "padding": "",
          "backgroundColor": ""
        }
      },
      "data": {
        "orderId": paymentPaytmData.order,
        "token": paymentPaytmData.token,
        "tokenType": "TXN_TOKEN",
        "amount": paymentPaytmData.amount /* update amount */
      },
      "payMode": {
        "labels": {},
        "filter": {
          "exclude": []
        },
        "order": [
            "CC",
            "DC",
            "NB",
            "UPI",
            "PPBL",
            "PPI",
            "BALANCE"
        ]
      },
      "website": "WEBSTAGING",
      "flow": "DEFAULT",
      "merchant": {
        "name": 'Mibhagya',
        "mid": paymentPaytmData.mid,
        "redirect": false
      },
      "handler": {
        "transactionStatus":function transactionStatus(paymentStatus){
          // console.log("paymentStatus => ",paymentStatus);
          // console.log(paymentStatus.TXNID);


          postpaydata.transaction_id= paymentStatus.TXNID;
        
          if(paymentStatus.STATUS==='TXN_SUCCESS'){
            postpaydata.status='success';
            postpaydata.razorpay_response='success';          
          }else{
            postpaydata.status='faild';
            postpaydata.razorpay_response='faild';

            console.log("faild");
          }
          console.log("faisssld");
          document.getElementById("app-close-btn").click()

          walletPaymentUpdate();

          // setLoading(false);
        },
        "notifyMerchant":function notifyMerchant(eventName,data){
          console.log("Closed");
          // setLoading(false);
        }
      }
  };

  if (window.Paytm && window.Paytm.CheckoutJS) {
  // initialze configuration using init method
  window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
      // console.log('Before JS Checkout invoke');
      // after successfully update configuration invoke checkoutjs
      window.Paytm.CheckoutJS.invoke();
  }).catch(function onError(error) {
      console.log("Error => ", error);
  });
  }
}
// console.log(postpaydata);

    
return (
<>
{ loading ? <Loader /> :'' }

<section className="wallet_page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <UserSidbar user={user} />
          </div>
          <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12">
            <div className="wallet_right">
              <div className="wallet_heading row">

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" >
                <h5>Recharges <span><i className="fa fa-inr" aria-hidden="true"></i></span></h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ textAlign: 'right' }}>
                <h5>Your Wallet <span><i className="fa fa-inr" aria-hidden="true"></i></span> {walletAmount}</h5>
                </div>
                
              </div>
              
              <div className="row recharge_row"> 

  {/* <div key={'dsds'} className="col-lg-2 col-sm-4 col-xs-6 wallet_recharge">
    <div className="rech_box" onClick={(e) => myFunction(1)}   >
       <span className="as_sign"><h5>{'sddsdwsd'}</h5></span>
       <div className="wallet_price">
       <h5><i className="fa fa-inr" aria-hidden="true"></i> {1}</h5>
       <p><strong>Bonus</strong></p>
       <a href={() => false} className="as_link">Recharge Now</a>
       </div>
    </div>
  </div> */}


              {rechargeLists.length > 0 ? (
              rechargeLists.map((rechargelist,index) => (
                  // <Rechargelist
                  // index={index}
                  // discount_type={rechargelist.discount_type}
                  // discount_value={rechargelist.discount_value}
                  // id={rechargelist.id}
                  // recharge_amount={rechargelist.recharge_amount}
                  // title={rechargelist.title}
                  // lable={rechargelist.lable}
                  // /> 
              <div key={index} className="col-lg-2 col-sm-4 col-xs-6 wallet_recharge">
                <div className="rech_box" onClick={(e) => myFunction(rechargelist.recharge_amount)}   >
                  <span className="as_sign"><h5>{rechargelist.lable}</h5></span>
                  <div className="wallet_price">
                  <h5><i className="fa fa-inr" aria-hidden="true"></i> {rechargelist.recharge_amount}</h5>
                  <p><strong>Bonus</strong></p>
                  <p className="card-offer">{rechargelist.title==="FreeCallPrice" ?rechargelist.discount_type: rechargelist.discount_type==='amount'?'₹'+rechargelist.discount_value:rechargelist.discount_value+'%' }</p>
                  <a href={() => false} className="as_link">Recharge Now</a>
                  </div>
                </div>
              </div>

              ))
              ) : (
                  <h1 mt="4">Not Found</h1>
              )}

              </div>
             
              <div className="row coupon_txt">
                 <div className="col-md-12 coupon_heading">
                 <h5 className="">Coupon <img src="./assets/images/discount.svg" alt=""/></h5>
                 <h5>  <a href={() => false} className="view">View All</a></h5>
              </div>


              {coupunLists.length > 0 ? (
           coupunLists.map((coupunlist,index) => (
            (recharge_price >0)?
              (recharge_price  >= Number(coupunlist.min_amount).toFixed(0) && Number(coupunlist.max_amount).toFixed(0) >= recharge_price)?(
                <div key={index} className="row box_design" >
                <div className="col-md-1 coupon_rows">
                    <div className="cou text-center">
                    <p>{coupunlist.title}</p>
                    </div>
                </div>
                <div className="col-md-2 coupon_rows">
                    <div className="couu text-center">
                    <p>Extra {coupunlist.discount_type==='amount'?'₹'+coupunlist.discount_value:coupunlist.discount_value+'%' }</p>
                    </div>
                </div>
                <div className="col-md-5 coupon_rows">
                    <div className="coup">
                    <p>{coupunlist.discount_type==='amount'?'₹'+coupunlist.discount_value:coupunlist.discount_value+'%' } On Minimum Recharge ₹{coupunlist.min_amount}</p>
                    </div>
                </div>
                <div className="col-md-3 coupon_rows">
                    <div className="coupp">
                    <p>Expiring in <span> {coupunlist.end_date}</span></p>
                </div>

                </div>
                <div className="col-md-1 coupon_rows">
                {/* <div className="coupp11_applied"> */}
                {
                  (coupons_id===coupunlist.id)?(

                    <div className="coupp11_applied">
                    <a href={() => false}  className="applyBtn">Applied</a>
                    </div>
                  ):(

                    <div className="coupp11">
                    <a href={() => false} onClick={(e) => couponApply(coupunlist.title)} className="applyBtn">Apply</a>
                    </div>
                  )

                }
              
                </div>
                </div>)
              :
              ( '' )
            :
            ( 
              <div key={index} className="row box_design" >
                <div className="col-md-1 coupon_rows">
                    <div className="cou text-center">
                    <p>{coupunlist.title}</p>
                    </div>
                </div>
                <div className="col-md-2 coupon_rows">
                    <div className="couu text-center">
                    <p>Extra {coupunlist.discount_type==='amount'?'₹'+coupunlist.discount_value:coupunlist.discount_value+'%' }</p>
                    </div>
                </div>
                <div className="col-md-5 coupon_rows">
                    <div className="coup">
                    <p>{coupunlist.discount_type==='amount'?'₹'+coupunlist.discount_value:coupunlist.discount_value+'%' } On Minimum Recharge ₹{coupunlist.min_amount}</p>
                    </div>
                </div>
                <div className="col-md-3 coupon_rows">
                    <div className="coupp">
                    <p>Expiring in <span> {coupunlist.end_date}</span></p>
                </div>

                </div>
                <div className="col-md-1 coupon_rows">
                {/* <div className="coupp11_applied"> */}
                {
                  (coupons_id===coupunlist.id)?(

                    <div className="coupp11_applied">
                    <a href={() => false}  className="applyBtn">Applied</a>
                    </div>
                  ):(

                    <div className="coupp11">
                    <a href={() => false} onClick={(e) => couponApply(coupunlist.title)} className="applyBtn">Apply</a>
                    </div>
                  )

                }
              
                </div>
                </div>
             )
            // <Coupunlist
            // index={index}
            // discount_value={coupunlist.discount_value}
            //         end_date={coupunlist.end_date}
            //         id={coupunlist.id}
            //         max_amount={coupunlist.max_amount}
            //         min_amount={coupunlist.min_amount}
            //         start_date={coupunlist.start_date}
            //         title={coupunlist.title}
            //         user_restrictions={coupunlist.user_restrictions}

            //     /> 
                  ))
                ) : (
                  <h1 mt="4">Not Found</h1>
                )}

            
              </div>


              <div className="row wow_sec">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="wow_box">
                  <div className="wow">
                    <div className="wow_cou">
                      <p className="text-center">
                        {coupon_title} </p>
                    </div>
                    <div className="wow_app">
                      <a href={() => false}>{ coupon_title?'applied':''}</a>
                    </div>
                  </div>
                  <form>
                    <div className="recharge_input">
                      <div className="form-group">
                        <label>Recharge Amount</label>
                        <input type="text" value={recharge_price} readOnly name=""/>
                      </div>
                      <div className="form-group">
                        <label>Bonus</label>
                        <input type="text" readOnly name=""/>
                      </div>
                      <div className="form-group">
                        <label>Credited In Wallet</label>
                        <input type="text" value={recharge_price} readOnly name=""/>
                      </div>
                    </div>
                  </form>
                  <div className="wow_extra">
                    <p>Wow <span>You Geting 50% Extra</span></p>
                  </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                 <form  onSubmit={handlePayClick} className="login-form">

                  <div className="rec_summary">
                    <h6><strong>Recharge Summary</strong></h6>
                    <ul>
                      <li><p>Amount</p></li>
                      <li><p className="dot">:</p></li>
                      <li><span>₹<span id="summary_amount">{recharge_price}</span></span></li>
                    </ul>
                    <ul>
                      <li><p>GST (18%)</p></li>
                      <li><p className="dot">:</p></li>
                      <li><span>₹<span id="summary_amount">{gst}</span></span></li>
                    </ul>
                    <ul>
                      <li><p>Ammount To Pay</p></li>
                      <li><p className="dot">:</p></li>
                      <li><span>₹<span id="summary_amount">{total_recharge_amount}</span></span></li>
                    </ul>
           

                    <div className="select_payment">
                      <div className="razorpay">
                        <span>Select a Payment gateway</span>
                        <div className="paytm">
                          <input type="radio" name="payment_type" value="paytm" defaultChecked={true} />
                          <label><img src="./assets/images/paytm.svg" alt=""/></label>
                        </div>
                        <div className="razor">
                             <input type="radio" name="payment_type" value="razorpay" />
                          <label><img src="./assets/images/razor.svg" alt=""/></label>
                        </div>
                      </div>
                      <div className="pay_now">
                        <button type="submit"  className="btn">Pay Now</button>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
         
         
         
              <div className="row charts_sec">
               <div className="col-md-12 recharge_his">
                 <h5 className="">Recharge History <img src="./assets/images/recharge.svg" alt=""/></h5>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="recharge_history">
              
              
                {paymentHistory.length > 0 ? (
                paymentHistory.map((paylist,index) => (
           
                  <>         
                  <div key={index} className={  index % 2 ?"recharge_box":"recharge_box or_c" }>
                  <div className="rec_date">
                  <span className="grey-cir"></span>
                  <span>{paylist.date}</span>
                  </div>
                  <p className="rs">₹{paylist.credit}</p>
                  <p className="gst">GST (18%) Rs. {paylist.gst}</p>
                  <span>₹{paylist.balance}</span>
                  </div>
                  </>

                  ))
                ) : (
                  <h1 mt="4">Not Found</h1>
                )}

           

                <div className="recharge_all">
                  <a href={() => false}>View All</a>
                </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="spend_box">
                  <h6>Spend in Value Chart</h6>
                  <p>Last 3 Months</p>
                  <div className="speed_graph">
                    <div className="total_box">
                      <div className="total">
                                        <h6 className="text-center">499.50</h6>
                                      </div>
                                      <div className="total1">
                                        <p className="text-center">Total Spent</p>
                                     </div> 
                                     </div>
                                     <div className="arrow">
                                        <img src="./assets/images/arrow_r.svg" alt=""/>
                                     </div>
                                     <div className="total_box">
                                       
                                     
                                     <div className="total">
                                        <h6 className="text-center">499.50</h6>
                                     </div>
                                     <div className="total1">
                                        <p className="text-center">Extra Bonus (30%)</p>
                                     </div>
                                     </div>
                  </div>
                </div>
              </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
</>
);
}

export default Wallet;