import React ,{useEffect} from "react";
import { NavLink } from "react-router-dom";
// import { toast } from "react-toastify";
//  import AuthService from '../../services/AuthService'
 import { useDispatch } from "react-redux";
 import UserService from '../../services/UserServices'

import { logoutUser } from "../../redux/action/Auth";

 const Header = ({ isAuthenticated = false, isAstro, user }) => {

  const dispatch = useDispatch();
  useEffect(  () => {
    const hashToken = localStorage.getItem('hashToken');
    if (hashToken) {
   //   getUserwallet
   var postdata = {
    userId: localStorage.getItem('userId'),
    hash_token:localStorage.getItem('hashToken')
    };

      UserService.walletMoney(postdata).then(function (response) {
            //handle success
            if (response.data.responseCode==='200') {
              console.log(response.data);
              dispatch({ type:"walletmoney", payload:response.data.amount });
            }
         
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

    }
  }, [dispatch]);

  // console.log(user);
  
    function logout() {
      var postlogoutdata = {
        userId: localStorage.getItem('userId'),
        hash_token:localStorage.getItem('hashToken')
        };

        dispatch(logoutUser(postlogoutdata));

    }
  
    function showLoginModal() {
      // setLoginModalIsOpen(true);
      dispatch({
        type:"openLoginModal",
      });

    }
    function showRegisModel() {
      dispatch({
        type:"openRegisModel",
      });

    }

  return (
    <>
    {/* <div id="preloader">
      <div id="status">&nbsp;</div>
    </div> */}
    <section className="top_header">
    <div className="container ">
      <div className="row">
        <div className="col-md-12">
          <div className="as_info_detail text-center">
            <div className="as_infobox">
              <p className="as_orange">Talk To Premium Astrologer </p>
              <NavLink href="/#" className="as_btn as_btn1">
                Connect
             </NavLink >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <header>
    <nav className="navbar navbar-expand-lg ">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img alt="test.jpg" src="./assets/images/logo-main.svg" />
       </NavLink >
        <button className="navbar_button">
          <span className="top_n" />
          <span className="mid_n" />
          <span className="bot_n" />
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              
              <NavLink className="nav-link active" to="/">
                Home
              </NavLink >
            </li>
            <li className="nav-item">
              
              <NavLink className="nav-link" to="our-inspiration">
                Our Inspiration
             </NavLink >
            </li>
            {!isAstro ?
            <li className="nav-item">
              <NavLink className="nav-link" to="expert">
                Talk To Astrologer
             </NavLink >
            </li>
            :''}
            <li className="nav-item">
              
              <NavLink className="nav-link" to="Kundli">
                Kundli
             </NavLink >
            </li>

            {/* <li className="nav-item">
              <NavLink className="nav-link" to="#" >
                Matching Kundli
                </NavLink>
            </li> */}

            <li className="nav-item">
              <NavLink className="nav-link" to="/panchang" >
                Panchang
                </NavLink>
            </li>

            <li className="nav-item">
              
              <NavLink className="nav-link" to="horoscope">
                Horoscope
             </NavLink >
            </li>
            <li className="nav-item">
              
              <NavLink className="nav-link" to="blog">
                Astro Blog
             </NavLink >
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="#">
                <img alt="test.jpg" src="./assets/images/offers.svg" />
             </NavLink >
            </li>
          </ul>
          <div className="d-flex">
          
          {isAuthenticated   ? 
                <> 
              {isAstro ?
              <NavLink  to="/astro/profile" className="login-btn as_btn as_btn2 wallet-btn" >
              Profile
              </NavLink >:
              
              <NavLink  to="user/wallet" className="login-btn as_btn as_btn2 wallet-btn" >
              Wallet
              </NavLink >
               }

                <button
                type="button"
                className="register-btn as_btn as_btn3"
                onClick={logout}
                >logout
                </button>

                </>
                :
                <>
                <button
                type="button"
                id="myButton"
                className="login-btn"
                onClick={showLoginModal}
                > Log In </button>
                <button
                type="button"
                id="myButton1"
                className="register-btn as_btn as_btn3"
                onClick={showRegisModel}
                >  Register  </button>
                </>


              }

         
          </div>
        </div>
      </div>
    </nav>
  </header>


    </>
  );
}
export default Header;