import React, {useState, useEffect} from 'react';
import moment from "moment";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import  CountUp  from "react-countup";

const WeeklyPrediction = ({ zodiacTitle = '', weeklydata = '' }) => {

    
const [todayPercentageTitle, setTodayPercentageTitle] = useState('');

  async function todayPercentageFn(title,text){
    console.log(title);
    console.log(text);
    // await setTodayPercentageTitle(title);
  }
  useEffect(() => {

     setTodayPercentageTitle('');

  }, [weeklydata]);



        const todayDate = moment().format("dddd, DD MMM, YYYY")
// console.log(weeklydata);
  return (
    <>
    
    <div className="row prediction_main">
    <div className="col-md-2">
        <div className="prediction_logos">
          <h3 className="as_subheading">{zodiacTitle?zodiacTitle[0].toUpperCase()+ zodiacTitle.slice(1):''}</h3>
          <div className="imgg text-center">
            <img alt="test.jpg" src={"./assets/images/c"+zodiacTitle+".svg"}  />
          </div>
          <div className="txtt">
            <p>({todayDate})</p>
          </div>
        </div>
      </div>
    <div className="col-md-10">
      <div className="prediction_content">
        <div className="pre_content_txt">
        <h6>
        <p>
        Lucky Color ( {weeklydata?weeklydata.lucky_color:''} )
        {/* <span>
            <img alt="test.jpg" src="./assets/images/lucky_color.svg" />
        </span> */}
        <span className="color_circle" 
            style={{ color: weeklydata?weeklydata.lucky_color_code:'', background: weeklydata?weeklydata.lucky_color_code:'' }} >C</span>
        </p>
        <p>
        Lucky Number{" "}
        <span className="pre_content_span">{weeklydata.lucky_number.join(",")}</span>
        </p>
    </h6>
          <h5>
            <p>
              Overall Day
              <span className="dayspercent1"></span>
            </p>
            <div className="dayspercent1" style={{ width:"50", height: 50 }}>
            <CircularProgressbar value={weeklydata.total_score}  text={`${weeklydata.total_score}%`}  
             styles={{
              text: {
              // Text color
              fill: '#161A66',
              // Text size
              fontSize: '3rem',
              fontWeight: '600',
            },
            path: {
              stroke: `rgb(255, 172, 117)`,
            },
          }}
          />
        </div>
            {/* <div className="progress mx-auto" data-value={weeklydata.total_score}>
              <span className="progress-left">
                <span
                  className="progress-bar border-primary"
                  style={{ transform: "rotate(108deg)" }}
                />
              </span>
              <span className="progress-right">
                <span
                  className="progress-bar border-primary"
                  style={{ transform: "rotate(180deg)" }}
                />
              </span>
              <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                <div className="h2 font-weight-bold">{weeklydata.total_score}</div>
              </div>
            </div> */}
            <p />
          </h5>
        </div>
        <p className="pre_aliqua">
         {weeklydata.bot_response}
        </p>
      </div>
    </div>
  </div>
  
  
    <div className="row customers_percentage">
        <div className="col-md-2">
        <div className={todayPercentageTitle==="career"?"as_sign_box active":"as_sign_box"}
            onClick={e=>todayPercentageFn('career',weeklydata.career)}>
            <div className="as_sign_txt">
            <span>
                <img alt="test.jpg" src="./assets/images/per_career.svg" />
            </span>
            <p>
            <CountUp start={0} end={weeklydata?weeklydata.career:''} delay={2}
            enableScrollSpy={true}
            scrollSpyDelay={0}
            />%</p>
            </div>
            <h4>career</h4>
            </div>
        </div>
        <div className="col-md-2">
        <div className={todayPercentageTitle==="family"?"as_sign_box active":"as_sign_box"}
            onClick={e=>todayPercentageFn('family',weeklydata.family)}>     
            <div className="as_sign_txt">
            <span>
                <img alt="test.jpg" src="./assets/images/per_family.svg" />
            </span>
            <p>
            <CountUp start={0} end={weeklydata?weeklydata.family:''} delay={2}
            enableScrollSpy={true}
            scrollSpyDelay={0}
            />%</p>
            </div>
            <h4>family</h4>
        </div>
        </div>
        <div className="col-md-2">
        <div className={todayPercentageTitle==="love"?"as_sign_box active":"as_sign_box"}
            onClick={e=>todayPercentageFn('love',weeklydata.relationship)}>    
            <div className="as_sign_txt">
            <span>
                <img alt="test.jpg" src="./assets/images/per_love.svg" />
            </span>
            <p>
            <CountUp start={0} end={weeklydata?weeklydata.relationship:''} delay={2}
            enableScrollSpy={true}
            scrollSpyDelay={0}
            />%</p>
            </div>
            <h4>Love</h4>
        </div>
        </div>
        <div className="col-md-2">
        <div className={todayPercentageTitle==="friends"?"as_sign_box active":"as_sign_box"}
            onClick={e=>todayPercentageFn('friends',weeklydata.friends)}>    
            <div className="as_sign_txt">
            <span>
                <img alt="test.jpg" src="./assets/images/per_friends.svg" />
            </span>
            <p>
            <CountUp start={0} end={weeklydata?weeklydata.friends:''} delay={2}
            enableScrollSpy={true}
            scrollSpyDelay={0}
            />%</p>
            </div>
            <h4>friends</h4>
        </div>
        </div>
        <div className="col-md-2">
        <div className={todayPercentageTitle==="finances"?"as_sign_box active":"as_sign_box"}
            onClick={e=>todayPercentageFn('finances',weeklydata.finances)}>    
            <div className="as_sign_txt">
            <span>
                <img alt="test.jpg" src="./assets/images/per_finances.svg" />
            </span>
            <p>
            <CountUp start={0} end={weeklydata?weeklydata.finances:''} delay={2}
            enableScrollSpy={true}
            scrollSpyDelay={0}
            />%</p>
            </div>
            <h4>finances</h4>
        </div>
        </div>
        <div className="col-md-2">
        <div className={todayPercentageTitle==="travel"?"as_sign_box active":"as_sign_box"}
            onClick={e=>todayPercentageFn('travel',weeklydata.travel)}>    
            <div className="as_sign_txt">
            <span>
                <img alt="test.jpg" src="./assets/images/per_travel.svg" />
            </span>
            <p>
            <CountUp start={0} end={weeklydata?weeklydata.travel:''} delay={2}
            enableScrollSpy={true}
            scrollSpyDelay={0}
            />%</p>
            </div>
            <h4>travel</h4>
        </div>
        </div>
    </div>
  </>
    
  );
};

export default WeeklyPrediction;
