import { server } from '../store';
import axios from 'axios';


export const dailySun = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'horoscopeLoadingRequest' });
  
     await axios.get('https://api.vedicastroapi.com/v3-json/prediction/daily-sun', {
        params:  postdata  
      })
      .then(function (res) {
        if (res.data.status===200) {
            console.log('dailySunSuccess');

            dispatch({ type: 'dailySunSuccess', payload: res.data });
        }else{
            console.log('dailySunFail');

            dispatch({ type: 'dailySunFail', payload: res.data.response });
        }

      })

  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }

  };

export const yearlyPrediction = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'horoscopeLoadingRequest' });
  
     await axios.get('https://api.vedicastroapi.com/v3-json/prediction/yearly', {
        params:  postdata  
      })
      .then(function (res) {
        if (res.data.status===200) {
            console.log('yearlyPredictionSuccess');

            dispatch({ type: 'yearlyPredictionSuccess', payload: res.data });
        }else{
            console.log('yearlyPredictionFail');

            dispatch({ type: 'yearlyPredictionFail', payload: res.data.response });
        }

      })

  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }

  };

export const weeklyPrediction = (postdata) => async dispatch => {
try {
    dispatch({ type: 'horoscopeLoadingRequest' });

    await axios.get('https://api.vedicastroapi.com/v3-json/prediction/weekly-sun', {
    params:  postdata  
    })
    .then(function (res) {
    if (res.data.status===200) {
        console.log('weeklyPredictionSuccess');

        dispatch({ type: 'weeklyPredictionSuccess', payload: res.data });
    }else{
        console.log('weeklyPredictionFail');

        dispatch({ type: 'weeklyPredictionFail', payload: res.data.response });
    }

    })

} catch (error) {
console.log(error);
// dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
}

};


export const dailyPanchang = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'horoscopeLoadingRequest' });

   await axios.get('https://api.vedicastroapi.com/v3-json/panchang/panchang', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
        //  console.log('dailypanchangSunSuccess');

          dispatch({ type: 'dailypanchangSunSuccess', payload: res.data });
      }else{
       //   console.log('dailypanchangSunFail');

          dispatch({ type: 'dailypanchangSunFail', payload: res.data.response });
      }

    })

  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }

};


export const panchangChartImage = (postdata1) => async dispatch => {
  try {
    dispatch({ type: 'horoscopeLoadingRequest' });

   await axios.get('https://api.vedicastroapi.com/v3-json/horoscope/chart-image', {
      params:  postdata1  
    })
    .then(function (res) {
     // console.log( res.data);
      dispatch({ type: 'chartSuccess', payload: res.data });

      // if (res.data.status===200) {
      //   console.log('chartSuccess');
      //   console.log( res.data);

      //     dispatch({ type: 'chartSuccess', payload: res.data });
      // }else{
      //     console.log('chartFail');

      //     dispatch({ type: 'chartFail', payload: res.data.response });
      // }

    })

  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }

};

export const panchangChartImageD9 = (postdata1) => async dispatch => {
  try {
    dispatch({ type: 'horoscopeLoadingRequest' });

   await axios.get('https://api.vedicastroapi.com/v3-json/horoscope/chart-image', {
      params:  postdata1  
    })
    .then(function (res) {
    //  console.log( res);
      dispatch({ type: 'chartD9Success', payload: res.data });

      // if (res.data.status===200) {
      //   console.log('chartSuccess');
      //   console.log( res.data);

      //     dispatch({ type: 'chartSuccess', payload: res.data });
      // }else{
      //     console.log('chartFail');

      //     dispatch({ type: 'chartD9Fail', payload: res.data.response });
      // }

    })

  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }

};

export const getKundliBasic = (postdata) => async dispatch => {

  // console.log(postdata)
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/horoscope/planet-details', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {

          dispatch({ type: 'kundliBasicSuccess', payload: res.data });

      }else{
          dispatch({ type: 'kundliBasicFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};


export const postKundliData = (postdata) => async dispatch => {

  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/postKundliData",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'kundliDataSuccess', payload: data.user_data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'kundliDataFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

export const getKundliData = (postdata) => async dispatch => {

  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/getKundliData",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'kundliDataGetSuccess', payload: data.user_data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'kundliDataGetFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

export const getKundliDataById = (postdata) => async dispatch => {

  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/getKundliDataById",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
        
            dispatch({ type: 'kundliDataById', payload: data.user_data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'kundliDataByIdFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

export const deleteKundliDataById = (postdata) => async dispatch => {

  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/deleteKundliDataById",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
        
            dispatch({ type: 'kundliDataGetSuccess', payload: data.user_data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'kundliDataGetFail', payload: data.responseMessage });
        }

  } catch (error) {
    console.log(error);
  }
};

export const getKundliMahaDasha = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dashas/maha-dasha', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'KundliMahaDashaSuccess', payload: res.data });
      }else{
          dispatch({ type: 'KundliMahaDashaFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};



export const getKundliAntarDasha = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dashas/antar-dasha', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'KundliAntarDashaSuccess', payload: res.data });
      }else{
          dispatch({ type: 'KundliAntarDashaFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};



export const getKundliMahaDashaPredictions = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dashas/maha-dasha-predictions', {
      params:  postdata  
    })
    .then(function (res) {
      // console.log(res);
      if (res.data.status===200) {
          dispatch({ type: 'KundliMahaDashaPredictionsSuccess', payload: res.data });
      }else{
          dispatch({ type: 'KundliMahaDashaPredictionsFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};



export const getAshtakvarga = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/horoscope/ashtakvarga', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'AshtakvargaSuccess', payload: res.data });
      }else{
          dispatch({ type: 'AshtakvargaFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};

export const getPlanetreport = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/horoscope/planet-report', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'PlanetreportSuccess', payload: res.data });
      }else{
          dispatch({ type: 'PlanetreportFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};

export const getPersonalcharacteristics = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/horoscope/personal-characteristics', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'CharacteristicsSuccess', payload: res.data });
      }else{
          dispatch({ type: 'CharacteristicsFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};

export const getMangalDosh = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dosha/mangal-dosh', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'MangalDoshSuccess', payload: res.data });
      }else{
          dispatch({ type: 'MangalDoshFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};
export const getManglikDosh = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dosha/manglik-dosh', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'ManglikDoshSuccess', payload: res.data });
      }else{
          dispatch({ type: 'ManglikDoshFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};
export const getPitraDosh = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dosha/pitra-dosh', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'PitraDoshSuccess', payload: res.data });
      }else{
          dispatch({ type: 'PitraDoshFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};

export const getKaalsarpDosh = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/dosha/kaalsarp-dosh', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'KaalsarpDoshSuccess', payload: res.data });
      }else{
          dispatch({ type: 'KaalsarpDoshFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};

////////////////

export const getGemSuggestion = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/extended-horoscope/gem-suggestion', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'GemSuggestionSuccess', payload: res.data });
      }else{
          dispatch({ type: 'GemSuggestionFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};

export const getGemNumeroTable = (postdata) => async dispatch => {
  try {
   dispatch({ type: 'horoscopeLoadingRequest' });
   await axios.get('https://api.vedicastroapi.com/v3-json/extended-horoscope/numero-table', {
      params:  postdata  
    })
    .then(function (res) {
      if (res.data.status===200) {
          dispatch({ type: 'GemNumeroTableSuccess', payload: res.data });
      }else{
          dispatch({ type: 'GemNumeroTableFail', payload: res.data.response });
      }
    })

  } catch (error) {
    console.log(error);
  }

};
