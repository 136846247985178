// import http from "../http-common";
import axios from "axios";

const baseURL ="https://manageonline.in/demo/mibhagya/WEB_V21/";


// const getCategory = postdata => {
//   return axios({
//     method: "post",
//     url: baseURL+"UsersApi/getCategory_v2",
//     data: postdata,
//     headers: { "Content-Type": "multipart/form-data" },
//   })
// };


const checkCoupon = postdata => {
return axios({
  method: "post",
  url: baseURL+"UsersApi/checkCoupon",
  data: postdata,
  headers: { "Content-Type": "multipart/form-data" },
})
};

const walletMoney = postdata => {
return axios({
  method: "post",
  url: baseURL+"UsersApi/walletMoney",
  data: postdata,
  headers: { "Content-Type": "multipart/form-data" },
})
};

const callNow = callpostdata => {
return axios({
  method: "post",
  url: baseURL+"UsersApi/callNow",
  data: callpostdata,
  headers: { "Content-Type": "multipart/form-data" },
})

};


// const getCounsellor = postdata => {
//     return axios({
//         method: "post",
//         url: baseURL+"UsersApi/getCounsellor_v2",
//         data: postdata,
//         headers: { "Content-Type": "multipart/form-data" },
//       })

//   };



// const getAllCons = postdata => {
//     return axios({
//         method: "post",
//         url: baseURL+"UsersApi/getCategory_testapi",
//         data: postdata,
//         headers: { "Content-Type": "multipart/form-data" },
//       })

//    // return http.post("/UsersApi/getCategory_testapi", postdata);
//   //  return http.get("/test.php", postdata);
  // };
// const notification = postdata => { //userId, hash_token
//     return http.post("/UsersApi/notification", postdata);
//   };
// const callHistory = postdata => {
//     return http.post("/UsersApi/callHistory", postdata);
//   };
  

  // const userDashboard= postdata => {
  //   return http.post("/UsersApi/userDashboard_v2", postdata);
  // };
  

 
  // const getUserwallet = postdata => {
  //   return axios({
  //     method: "post",
  //     url: baseURL+"UsersApi/getUserwallet",
  //     data: postdata,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  // };


  

// const addMoney = postdata => {
//   return axios({
//     method: "post",
//     url: baseURL+"UsersApi/addMoney_v3",
//     data: postdata,
//     headers: { "Content-Type": "multipart/form-data" },
//   })
// };



// const walletMoney = postdata => {
//     return http.post("/UsersApi/walletMoney", postdata);
//   };
// const getCounsReview = postdata => {//latitude,longitude
//     return http.post("/UsersApi/getCounsReview", postdata);
//   };

// const callEnd = postdata => {
//     return http.post("/UsersApi/callEnd", postdata);
//   };

//   const notify = postdata => {
//     return http.post("/UsersApi/notify", postdata);
//   };

//   const giveReview = postdata => {
//     return http.post("/UsersApi/giveReview", postdata);
//   };

//   const getUserTransaction = postdata => {
//     return http.post("/UsersApi/getUserTransaction", postdata);
//   };

//   const getStartCallId = postdata => {
//     return http.post("/UsersApi/getStartCallId", postdata);
//   };
//   const getRechargeAmountsList = postdata => {
//     return http.post("/UsersApi/getRechargeAmountsList", postdata);
//   };
//   const contact_us = postdata => {
//     return http.post("/UsersApi/contact_us", postdata);
//   };
//   const getUserMobile = postdata => {
//     return http.post("/UsersApi/getUserMobile", postdata);
//   };
//   const sendOtp = postdata => {
//     return http.post("/UsersApi/sendOtp", postdata);
//   };
//   const getCouponList = postdata => {
//     return http.post("/UsersApi/getCouponList", postdata);
//   };

//   const updateUserMobileWithOtp = postdata => {
//     return http.post("/UsersApi/updateUserMobileWithOtp", postdata);
//   };




////  
// const get = id => {
//   return http.get(`/UsersApi/getCategory_testapi/${id}`);
// };

// const create = data => {
//   return http.post("/UsersApi/getCategory_testapi", data);
// };

// const update = (id, data) => {
//   return http.put(`/UsersApi/getCategory_testapi/${id}`, data);
// };

// const remove = id => {
//   return http.delete(`/UsersApi/getCategory_testapi/${id}`);
// };

// const removeAll = () => {
//   return http.delete(`/UsersApi/getCategory_testapi`);
// };

// const findByTitle = title => {
//   return http.get(`/UsersApi/getCategory_testapi?title=${title}`);
// };

const UserService = {
//   getAll,
//   get,
//   create,
//   update,
//   remove,
//   removeAll,
//   findByTitle,
// userDashboard,
    // getAllCons,
    // getCounsellor,
    // notification,
    // callHistory,
    // getCategory,
    // addMoney,
     walletMoney,
    // getCounsReview,
    callNow,
    // callEnd,
    // notify,
    // giveReview,
    // getUserTransaction,
    // getStartCallId,
    // getRechargeAmountsList,
    // contact_us,
    // getUserMobile,
    // sendOtp,
    // getCouponList,
    checkCoupon,
    // updateUserMobileWithOtp,
    // getUserwallet,
};

export default UserService;
