import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';

// import {
//   BrowserRouter as Router,
//   // Routes,
//   // Route,
//   useRoutes,Navigate
// } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import PageNotFound from './components/Pages/PageNotFound.js';
import Panchang from './components/Pages/Panchang.js';
import  AuthModel from './components/Model/AuthModel.js';
import  Header from './components/Layout/Header.js';
import  Footer from './components/Layout/Footer.js';
import  Home   from './components/Home/Home.js';
// import  Expert from './components/Expert/Expert.js';
import Expert from './components/Expert/Expert.js';
// import OwlCarousel from './components/Carousel/OwlCarousel.js';
import  Detail from './components/Expert/Detail.js';
import  Blog   from './components/Blog/Blog.js';
import  BlogDetails from './components/Blog/BlogDetails.js';
import  DetailPage from './components/Home/HomeContentDetail.js';
import  Horoscope      from './components/Pages/Horoscope.js';
import  OurInspiration from './components/Pages/OurInspiration.js';
import  Wallet from './components/User/Wallet.js';
import  Profile from './components/User/Profile.js';

import  HelpAndSupport from './components/User/HelpAndSupport.js';

import  Kundli from './components/Pages/Kundli.js';
import Astrologin from './components/Astro/Astrologin.js';
import Astroregistration from './components/Astro/Astroregistration.js';
import Astroprofile from './components/Astro/Astroprofile.js';

import Loader from './components/Pages/Loader.js';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { loadUser } from './redux/action/Auth.js';

import { ProtectedRoute } from 'protected-route-react';

function App() {
  // window.addEventListener('contextmenu', e => {
  //   e.preventDefault();
  // });

  const { isAuthenticated,   user, message, error, loading } = useSelector(
    state => state.auth
  );

  const isAstroAuthenticated = localStorage.getItem('isAstroLogedIn');

  // console.log(isAuthenticated);
  // console.log(message);
  // console.log(loading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }
  }, [dispatch, error, message]);

  useEffect(() => {
    var postdata = {
      userId: localStorage.getItem('userId'),
      hash_token: localStorage.getItem('hashToken'),
  };
  if(localStorage.getItem('isLogedIn')){
      if(!user){
        console.log('get user')
        dispatch(loadUser(postdata));
      }
  }
    
  }, [dispatch,user]);


  return (
    <Router>
        { loading ? <Loader /> :'' }

        <>
        <ToastContainer theme='colored' position='top-right'></ToastContainer>

          <Header isAuthenticated={isAuthenticated} isAstro={isAstroAuthenticated} user={user} />
          <Routes>

            <Route  path="/" element={<Home />} isAstro={isAstroAuthenticated} />
            <Route  path="/404"  element={<PageNotFound /> }/>
            <Route  path="*" element={<Navigate to="/404" /> }/>

            
            <Route  path="/Kundli" element={<Kundli /> }/>
            <Route  path="/astro-login" element={<Astrologin isAuthenticated={isAuthenticated} /> }/>
            <Route  path="/astro-registration" element={<Astroregistration isAuthenticated={isAuthenticated} /> }/>

            <Route  path="/panchang" element= {<Panchang /> }/>
            <Route  path="/our-inspiration" element= {<OurInspiration /> }/>
            <Route  path="/expert" element={<Expert isAuthenticated={isAuthenticated} user={user} /> }/>
            <Route  path="/expert-detail/:id" element={<Detail isAuthenticated={isAuthenticated} user={user} /> }/>
            <Route  path="/horoscope" element={<Horoscope /> }/>
            <Route  path="/blog" element={<Blog /> }/>
            <Route  path="/blog/:slug" element={<Blog /> }/>
            <Route  path="/blog/view/:slug" element={<BlogDetails /> }/>
            <Route  path="/detail-page/:slug" element={<DetailPage /> }/>
           

            <Route
              path="/user/wallet"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}  redirect="/">
                  <Wallet  isAuthenticated={isAuthenticated} user={user} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user/profile"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}  redirect="/">
                  <Profile  isAuthenticated={isAuthenticated} user={user} />
                </ProtectedRoute>
              }
            />

         <Route
              path="/user/help-and-support"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}  redirect="/">
                  <HelpAndSupport  isAuthenticated={isAuthenticated} user={user} />
                </ProtectedRoute>
              }
            />

          <Route
              path="/user/profile"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}  redirect="/">
                  <Profile  isAuthenticated={isAuthenticated} user={user} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/astro/profile"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}  isAstroAuthenticated={isAstroAuthenticated} redirect="/">
                  <Astroprofile  isAuthenticated={isAuthenticated} isAstroAuthenticated={isAstroAuthenticated} user={user} />
                </ProtectedRoute>
              }
            />

{/* 
          <Route
              path="/user/wallet"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect="/"
                >
                  <Wallet user={user} />
                </ProtectedRoute>
              }
            /> */}

           


           
            {/* <Route
              path="/profile"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Profile user={user} />
                </ProtectedRoute>
              }
            />
            */}
            {/* <Route
              path="/login"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect="/profile"
                >
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect="/profile"
                >
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              path="/forgetpassword"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect="/profile"
                >
                  <ForgetPassword />
                </ProtectedRoute>
              }
            />

            <Route
              path="/resetpassword/:token"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect="/profile"
                >
                  <ResetPassword />
                </ProtectedRoute>
              }
            /> */}

           
         
          </Routes>

          { !isAuthenticated ? <AuthModel /> :'' }

          <Footer />
        </>
    
    </Router>
  );
}

export default App;

// Admin admincourses
