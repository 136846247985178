import { createReducer } from "@reduxjs/toolkit";

const initialState ={
    totalRows:0,
    perPage:5,
    blogList:[],
    recentBlogs:[],
    blogCategory:[],
    loading:false,
    blogDetais:[],

    blogComments : [],
    blogIpLike : 0,
    blogtotalLike :0,
    isCommentModel:false,

}

export const BlogReducer = createReducer(initialState,{
   
    openCommnetModal : (state,action) =>{
        state.isCommentModel=true;
    }, 

    closeCommentModel : (state,action) =>{
        state.isCommentModel=false;
    }, 


       getBlogListSuccess: (state, action) => {
          //  console.log(action.payload);
            state.blogList = action.payload.blogs;
            state.recentBlogs = action.payload.recent_blogs;
            state.blogCategory = action.payload.blog_category;
            state.totalRows = action.payload.total_rows;
            state.perPage = action.payload.per_page;
            
            state.loading = false;
        },
        
        getBlogListFail: (state, action) => {
            state.loading = false;
            state.bloglist =[];
            state.error = action.payload;
          },


        getBlogDetailsSuccess: (state, action) => {
               //  console.log(action.payload);
                state.blogDetais = action.payload.blog;
                state.recentBlogs = action.payload.recent_blogs;
                state.blogCategory = action.payload.blog_category;

                state.blogComments = action.payload.comments;
                state.blogIpLike = action.payload.ip_like;
                state.blogtotalLike = action.payload.total_like;

                state.loading = false;
          },
          
          getBlogDetailsFail: (state, action) => {
              state.loading = false;
              state.blogDetais =[];
              state.error = action.payload;
            },


            addCommentSuccess: (state, action) => {
                 //console.log(action.payload);
                state.blogComments = action.payload.comments;
                state.message = action.payload.responseMessage;
                state.loading = false;
                state.isCommentModel=false;

            },
          
            addCommentFail: (state, action) => {
                state.loading = false;
                state.error = action.payload;
                },



            addLikeSuccess: (state, action) => {
                 console.log(action.payload);
                 state.blogIpLike = action.payload.ip_like;
                 state.blogtotalLike = action.payload.total_like;
                 state.message = action.payload.responseMessage;
                 state.loading = false;

            },
          
            addLikeFail: (state, action) => {
                state.loading = false;
                state.error = action.payload;
                },


        loadingRequest: state => {
            state.loading = true;
        },

        clearError: state => {
        state.error = null;
        },
        clearMessage: state => {
        state.message = null;
        },
});