import { server } from '../store';
import axios from 'axios';

export const getBlogList = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"BlogApi/index",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'getBlogListSuccess', payload: data });
        }else{
            dispatch({ type: 'getBlogListFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};


export const getBlogDetails = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'loadingRequest' });
  
      const { data } = await axios({
          method: "post",
          url: server+"BlogApi/view",
          data: postdata,
          headers: { "Content-Type": "multipart/form-data" },
        })
          if (data.responseCode==='200') {
              dispatch({ type: 'getBlogDetailsSuccess', payload: data });
          }else{
              dispatch({ type: 'getBlogDetailsFail', payload: data.responseMessage });
          }
    } catch (error) {
      console.log(error);
    }
  };


  export const addComment = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'loadingRequest' });
  
      const { data } = await axios({
          method: "post",
          url: server+"BlogApi/addComment",
          data: postdata,
          headers: { "Content-Type": "multipart/form-data" },
        })
          if (data.responseCode==='200') {
              dispatch({ type: 'addCommentSuccess', payload: data });
          }else{
              dispatch({ type: 'addCommentFail', payload: data.responseMessage });
          }
    } catch (error) {
      console.log(error);
    }
  };


  export const addUserLike = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'loadingRequest' });
      //  console.log('error');

      const { data } = await axios({
          method: "post",
          url: server+"BlogApi/addLike",
          data: postdata,
          headers: { "Content-Type": "multipart/form-data" },
        })
          if (data.responseCode==='200') {
              dispatch({ type: 'addLikeSuccess', payload: data });
          }else{
              dispatch({ type: 'addLikeFail', payload: data.responseMessage });
          }
    } catch (error) {
      console.log(error);
    }
  };

