import { server } from '../store';
import axios from 'axios';

export const getHomeContentList = (postdata) => async dispatch => {
  try {
    // dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "get",
        url: server+"HomeApi/index",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'getHomeContentListSuccess', payload: data });
        }else{
            dispatch({ type: 'getHomeContentListFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

export const getHomeContentDetail = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"HomeApi/contentDetail",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
      
        if (data.responseCode==='200') {
            dispatch({ type: 'getHomeContentDetailSuccess', payload: data });
        }else{
            dispatch({ type: 'getHomeContentDetailFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

