import { createReducer } from "@reduxjs/toolkit";

const initialState ={
        loading:false,
        counslists: [], 
        counsellor_detail: [], 
        counsellor_review: [], 
        total_review: 0, 
        total_session: 0, 

        coupunLists : [], 
        rechargeLists : [], 
        walletAmount : 0, 
        paymentHistory : [], 

        categoryList:[],
        languageList:[], 
        updatedUser:[]
}

export const userapiReducer = createReducer(initialState,{


    getCategoryLangSuccess: (state, action) => {
        state.loading = false;
        state.categoryList= action.payload.category; 
        state.languageList= action.payload.language; 
         // state.message = action.payload.responseMessage;
        console.log(action.payload.responseMessage)
    },

    getCategoryLangFail: (state, action) => {
        state.loading = false;
        state.categoryList= []; 
        state.languageList=[]; 
        state.error = action.payload;
    },

        getCounsellorsSuccess: (state, action) => {
            state.loading = false;
            state.counslists = action.payload.counsellor_detail;
            // state.message = action.payload.responseMessage;
             console.log(action.payload)
        },

        getCounsellorsFail: (state, action) => {
            state.loading = false;
            state.counslists =[];
            state.error = action.payload;
        },

     
        getCounsellorDetailSuccess: (state, action) => {
            state.loading = false;
            state.counslists = action.payload.counsellor_detail;
            // state.message = action.payload.responseMessage;
           
            state.counsellor_detail=action.payload.counsellor_detail[0];
            state.counsellor_review=action.payload.counsellor_review; 
            state.total_review= action.payload.total_review;
            state.total_session= action.payload.session;

            console.log(action.payload.responseMessage)
        },

        getUserwalletSuccess: (state, action) => {
            state.loading = false;
            state.coupunLists = action.payload.couponlist;
            state.rechargeLists = action.payload.walletRecharge;
            state.walletAmount = action.payload.amount;
            state.paymentHistory = action.payload.payment_history;
            // state.message = action.payload.responseMessage;
            console.log(action.payload.responseMessage)
        },

        getUserwalletFail: (state, action) => {
            state.loading = false;
            state.coupunLists =[];
            state.rechargeLists = [];
            state.walletAmount = [];
            state.paymentHistory = [];
            state.error = action.payload;
        },

        getAddMoneySuccess: (state, action) => {
            state.loading = false;
            state.message = action.payload.responseMessage;
            console.log(action.payload.responseMessage)
        },

        getAddMoneyFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        getUserUpdateSuccess: (state, action) => {
            // console.log(action.payload.user);
            state.loading = false;
            state.message = action.payload.responseMessage;
            state.updatedUser = action.payload.user;
            // console.log(action.payload.responseMessage)
        },

        getUserUpdateFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },


        ///////common /////////
        loadingRequest: state => {
            state.loading = true;
        },

        clearError: state => {
            state.error = null;
        },
        clearMessage: state => {
            state.message = null;
        },

        SessionExpired: (state, action) => { //Session expired.
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
            state.user ='';
            window.localStorage.clear();
            window.sessionStorage.clear();
        },
        

});