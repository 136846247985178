import React ,{useEffect,useState, useRef} from "react";

import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch,useSelector } from "react-redux";
import moment from "moment";
import Basic from "../Kundli/Basic";
import KundliChart from "../Kundli/KundliChart";
import Dashas from "../Kundli/Dashas";
import Ashtakvarga from "../Kundli/Ashtakvarga";
import KundliReport from "../Kundli/KundliReport";
// import KundliDetail from "../Kundli/KundliDetail"
import { postKundliData, getKundliData,getKundliDataById,deleteKundliDataById} from "../../redux/action/HoroscopeApi";

function Kundli() {
    const { kundliForm, kundliFormNext,kundliDataGetSuccess,GetKundliDataById } = useSelector(
        state => state.horoscope
      );

//   console.log(kundliDataGetSuccess.length)

    const [currentTab, setTab] = useState('KundliDetail');
 
    const [birthtimeminutes, kbirthtimeminutes] = useState('');
    const [birthtimehours, kbirthtimehours] = useState('');

    const [dobday, kdobday] = useState('');
    const [gender, kgender] = useState('');
    const [name, kname] = useState('');
    const [birthplace, kbirthplace] = useState('');
    
    var postdata = {
        birthplace:birthplace, //api not required
        username:name, //api not required
        usergender:gender, //api not required
        // dob:dobday+'/'+dobmonth+'/'+dobyear, ///Date of Birth - DD/MM/YYYY
        dob:moment(dobday).format("DD/MM/YYYY")  , ///Date of Birth - 
        tob: birthtimehours+':'+birthtimeminutes , //Birth time Time of Birth in Hours - HH:MM
        lat:'28.6139',//latno, //Latitude
        lon:'77.209', //lngno, //Longitude
        api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e', //Your API key
        lang:'en', //language
        tz:'5.5', //TimeZone
        userId: localStorage.getItem('userId'),
        hash_token:localStorage.getItem('hashToken')
};

const dispatch = useDispatch();

let userId = localStorage.getItem('userId');

      const Proceed = (e) => {
        e.preventDefault();
        if(formvalidate()){
        
             dispatch({
                type:"kundliFormSet",
                data:postdata
              });


            if(userId !=null){
                // submit into server DB
                if(kundliDataGetSuccess.length < 4){
                    dispatch(postKundliData(postdata));
                }else{
                    toast.error("Your limit is reached please delete old record !");
                }
                
            }   
        
        }
      }

    const formvalidate=()=>{

        let result =true;
        if(name==='' || name===null){
          result =false;
          toast.error("The name field is required.");
        }
        else if(gender==='' || gender===null){
        result =false;
        toast.error("The gender field is required.");
        }
        else if(dobday==='' || dobday===null){
        result =false;
        toast.error("The day field is required.");
        } 
  
        else if(birthtimehours==='' || birthtimehours===null){
        result =false;

        toast.error("The hours field is required.");
        } 
         else if(birthtimeminutes==='' || birthtimeminutes===null){
        result =false;

        toast.error("The minutes field is required.");
        } 
 
         else if(birthplace==='' || birthplace===null){
        result =false;

        toast.error("The birth place field is required.");
        }
  
        return result;
      }
  

      const buttonRef = useRef(null);


      useEffect(() => {
        if(userId){
            var postdata1 = {
                userId:userId 
            };
            dispatch(getKundliData(postdata1));
        }


        if (kundliFormNext===true) {

          dispatch({ type: 'kundliFormNextStatus' });
        //   nextTab.current.click();

        buttonRef.current.click();

        //    buttonRef.current.click();
        }
    
        
        if(GetKundliDataById !=''){
            kname(GetKundliDataById.name);
            kgender(GetKundliDataById.gender);
            let bdday = moment(GetKundliDataById.dob).format("YYYY-MM-DD");
            kdobday(bdday);

            let bddayMIns = GetKundliDataById.birth_time;
            let MIns = bddayMIns.split(":")[1];
            kbirthtimeminutes(MIns);

            let Hrs = bddayMIns.split(":")[0];
            kbirthtimehours(Hrs);
            kbirthplace(GetKundliDataById.birth_place);
        }

      }, [dispatch, userId, kundliForm,GetKundliDataById, kundliFormNext]);

 
      const handleAutofillClick = event => {
       event.preventDefault();

       let dataId =event.currentTarget.id;
       var rowData = {
        id:dataId 
        };

       
       dispatch(getKundliDataById(rowData));
      
      };

      const handleDeleteData = event => {
        event.preventDefault();
 
        let dataId =event.currentTarget.id;
        var rowData = {
         id:dataId ,
         userId: localStorage.getItem('userId')
         };
 
        
        dispatch(deleteKundliDataById(rowData));
       
       };
  

  return (
<>


<section className="horoscope_top kundali_report">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h1>KUNDLI <span></span></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus purus a varius consequat.
                        Mauris ligula ex, auctor vel tortor at, vulputate porta justo.</p>
                </div>
            </div>
        </div>

    </section>

<section className="tabs_section_bar">
    <div>
    <div className="row ">
    <div className="col-lg-12 col-md-12 col-12  ">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" onClick={(e)=>setTab('KundliDetail')} id="pills-home-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                    aria-selected="true">Kundli detail</button>
            </li>
            <li className="nav-item" role="presentation">
                <button ref={buttonRef} className={kundliForm === true?'nav-link':'nav-link disabled' } onClick={(e)=>setTab('KundliBasic')} id="pills-profile-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                    aria-selected="false">Basic</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={kundliForm === true?'nav-link':'nav-link disabled' } onClick={(e)=>setTab('KundliChart')} id="pills-contact-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                    aria-selected="false">Kundli Chart</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={kundliForm === true?'nav-link':'nav-link disabled' } onClick={(e)=>setTab('KundliDashas')} id="pills-dasha-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-dasha" type="button" role="tab" aria-controls="pills-dasha"
                    aria-selected="false">Dashas</button>
            </li>
           
            <li className="nav-item" role="presentation">
                <button className={kundliForm === true?'nav-link':'nav-link disabled' } onClick={(e)=>setTab('KundliAshtakvarga')} id="pills-ashtakvarga-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-ashtakvarga" type="button" role="tab"
                    aria-controls="pills-ashtakvarga" aria-selected="false">Ashtakvarga</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={kundliForm === true?'nav-link':'nav-link disabled' } onClick={(e)=>setTab('KundliReport')} id="pills-last_tab-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-last_tab" type="button" role="tab" aria-controls="pills-last_tab"
                    aria-selected="false">kundli Report</button>
            </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">

            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  {/* <KundliDetail /> 
                   */}
                {currentTab === 'KundliDetail' ? (
                <>
                <div className="row tabs_section_bar_row">

                <div className="col-lg-6 col-md-6 col-12">
                    <div className="chart_bar">
                        <div className="chart_heading">
                            <h2>kundli / <span className="birth_chart">Birth Chart</span></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                        <div className="kundlidate">
                            <form>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label  className="form-label">Name*</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="name" 
                                        onChange={e=>kname(e.target.value)}
                                        value={name}
                                        placeholder="Enter Your Name" />
                                    </div>
                                    <div className="col">
                                        <label  className="form-label">Gender*</label>
                                        <select onChange={e=>kgender(e.target.value)}  className="form-select" id="gender">
                                            <option value="">Enter Your Gender</option>
                                            <option selected={gender === 'male'} value="male">Male</option>
                                            <option selected={gender === 'female'} value="female">Female</option>
                                            <option selected={gender === 'other'} value="other">Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Date Of Birth*</label>
                                    <div className="row">
                                        <div className="col">
                                            <input 
                                            type="date" 
                                            className="form-control" 
                                            onChange={e=>kdobday(e.target.value)}
                                            value={dobday}
                                            max={new Date().toJSON().slice(0, 10)}
                                            placeholder="Date of Birth"/>
                                        </div>
                                    
                                    </div>
                                </div>

                                <div className="mb-3 time_birth">
                                    <label className="form-label">Birth Time*</label>
                                    <div className="row">
                                        <div className="col">
                                            <input 
                                            type="number"
                                            min={1} 
                                            max={12} 
                                            className="form-control" 
                                            onChange={e=>kbirthtimehours(e.target.value)}
                                            value={birthtimehours}
                                            placeholder="Hours"/>
                                        </div>
                                        <div className="col">
                                            <input 
                                            type="number" 
                                            min={0} 
                                            max={60} 
                                            className="form-control" 
                                            onChange={e=>kbirthtimeminutes(e.target.value)}
                                            value={birthtimeminutes}
                                            placeholder="Minutes"/>
                                        </div>
                                    
                                    </div>
                                </div> 

                                <div className="mb-3">
                                    <label  className="form-label">Birth Place*</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="birthPlace"
                                    onChange={e=>kbirthplace(e.target.value)} 
                                    value={birthplace}
                                    placeholder="Place" />
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="second_section__kundli">
                        <div className="second_section__kundli_button-bar">
                            <button onClick={Proceed} >
                                Get Kundli
                            </button>
                        </div>
                        <div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat. Duis aute irure dolor
                                in
                            </p>
                        </div>
                    </div>

              {userId !=null ? (

                <>
                    <div className="row">

                    {
                    kundliDataGetSuccess.map((comments,index) => (

                       <>       
                        <div className="col-md-3 autofill" >
                         <div  className="formData">
                         <label className="radio-inline">
                              {comments.name}
                              <span className="deleteAutofill" id={comments.id} onClick={handleDeleteData} > &#x274C;</span>
                          </label>
                          <p>{comments.gender}</p>
                          <p>{comments.dob}</p>
                          <p>{comments.birth_time}</p>
                          <p>{comments.birth_place}</p>
                          <p className="useThis autoclick" id={comments.id} onClick={handleAutofillClick}>Use</p>
                         </div>
                        </div>
                        </>

                      ))
                    }

                    </div>
                    </>
                
                ) : ('')}

                </div>

                </div>
                </>
                ) : ('')
                }


            </div>

            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                {currentTab === 'KundliBasic' && kundliForm === true ? (
                <>
                    <Basic />
                </>
                ) : ('')
                }
            </div>

            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-profile-tab">
                {currentTab === 'KundliChart' && kundliForm === true ? (
                <>
                    <KundliChart />
                </>
                ) : ('')
                } 
            </div>

            <div className="tab-pane fade" id="pills-dasha" role="tabpanel" aria-labelledby="pills-profile-tab">
                {currentTab === 'KundliDashas' && kundliForm === true ? (
                <>
                    <Dashas />
                </>
                ) : ('')
                } 
            </div>

            <div className="tab-pane fade" id="pills-chart" role="tabpanel" aria-labelledby="pills-profile-tab">
                <p>Chart</p>
            </div>

            <div className="tab-pane fade" id="pills-ashtakvarga" role="tabpanel" aria-labelledby="pills-profile-tab">
                {currentTab === 'KundliAshtakvarga' && kundliForm === true ? (
                <>
                    <Ashtakvarga />
                </>
                ) : ('')
                }
           </div>

            <div className="tab-pane fade" id="pills-last_tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                {currentTab === 'KundliReport' && kundliForm === true ? (
                <>
                    <KundliReport />
                </>
                ) : ('')
                }
               
            </div>




        </div>
    </div>
    </div>  
    </div>
</section>


<section className="our_astrologers">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 text-center astro as_padderTop50">
          <div className="astroo1">
            <p>
              {"For detail horoscope analysis, speak to our astrologers"}
              
              <br />
              <NavLink  to="/expert" >Click Here</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

</>

  );
}
export default Kundli;