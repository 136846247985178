
import React, {useEffect,useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { getKundliMahaDasha, getKundliAntarDasha, getKundliMahaDashaPredictions } from "../../redux/action/HoroscopeApi";
import Loader from "../Pages/Loader";
import moment from "moment";

function Dashas() {

    const { KundliMahaDashaPredictions, KundliAntarDasha, KundliMahaDasha, kundliFormdata } = useSelector(
        state => state.horoscope
      );
      const [StartYear, SetStartYear] = useState('');
    //   const [YearDiff, SetYearDiff] = useState('0');
    //   const [dateA, SetDateA] = useState('');
    //   const [dateB, SetDateB] = useState('');

      const dispatch = useDispatch();

      useEffect(() => { /// het panchang
        // console.log(kundliFormdata.data);
        var postdata = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz //TimeZone
        };
        const loadbasic1 = async () => {
            dispatch(getKundliMahaDashaPredictions(postdata));
        }
        loadbasic1();

        const loadbasic = async () => {
            dispatch(getKundliMahaDasha(postdata));
        }
        loadbasic();
        const loadbasic2 = async () => {
            dispatch(getKundliAntarDasha(postdata));
        }
        loadbasic2();
        // console.log(postdata); 
        console.log('i fire once');
        SetStartYear(kundliFormdata.data.dob);

  }, [dispatch, kundliFormdata]);
        
            if (!KundliMahaDasha) {
              return <Loader />;
            }
          if (!KundliAntarDasha) {
            return <Loader />;
          }
          if (!KundliMahaDashaPredictions) {
            return <Loader />;
          }

          
          function handleClick(i) {
 
            if(i>0){
                const dateA = moment(KundliMahaDasha.mahadasha_order[i-1]).format("DD/MM/YYYY");
                const dateB=moment(KundliMahaDasha.mahadasha_order[i]).format("DD/MM/YYYY");
                // const dateA = moment(dateAA);
                // const dateB =moment(dateBB);
                var startDate = moment(dateA, "DD.MM.YYYY");
                var endDate = moment(dateB, "DD.MM.YYYY");
            }else{
                // const dateA= moment(StartYear).format("YYYY-MM-DD");
                const dateB=moment(KundliMahaDasha.mahadasha_order[i]).format("DD/MM/YYYY");
                
                // const dateA = moment(dateAA);
                // const dateB =moment(dateBB);
                var startDate = moment(StartYear, "DD.MM.YYYY");
                var endDate = moment(dateB, "DD.MM.YYYY");
            }
   
            // var startDate = moment("13/04/2016", "DD.MM.YYYY");
            // var endDate = moment("28.04.2016", "DD.MM.YYYY");
    // console.log(`Difference is ${dateA.diff(dateB, 'years')} week(s)`);
    // console.log(`Difference is ${dateA.diff(dateB, 'years')} Years(s)`);
    var result = endDate.diff(startDate, 'years');

    // console.log(KundliMahaDasha.mahadasha_order[i])
    console.log(result)
    // console.log(startDate)  
    // console.log(endDate)  
return result;
            // SetYearDiff(result);
          //  return true;
          }
 

    // console.log(KundliAntarDasha);
    // console.log(MahaDasha);
    // console.log(Mahadasha_order);
    // console.log(KundliMahaDashaPredictions);

  return (
<>
{ KundliMahaDasha === '' && KundliAntarDasha === '' && KundliMahaDashaPredictions === '' ? <Loader /> :
<>
    <div className="details-container">
        <div className="first_name first_name_paragraph_bar">
        <p className="">Dasha’s</p>
        </div>
        <div className="second_name">
        <p>{ kundliFormdata.data.username }</p>
        </div>
    </div>
   
    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12 position-relative">
                    <div className="dasha_Design_img">
                        <img src={"./assets/images/dasha_design.svg"} alt="" />
                    </div>
                    <div className="dasha_Design_heading">
                        <p>Dasha Remaining Period At Birth - {KundliMahaDasha.dasha_remaining_at_birth}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="year_dasha">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                <div className=" dasha_full_detail_section">
                <div className="row">

                             {/* ////////////// */}
                        {KundliMahaDasha.mahadasha.length > 0 ? (
                         KundliMahaDasha.mahadasha.map((mahadash,index) =>   (
                            <div className="col-3" style={{ paddingTop: '30px'}}>
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">

                          
                                <h6 >{mahadash} {handleClick(index)} Years</h6>
                                

                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        {index > 0 ? (
                                        <h6>{ moment(KundliMahaDasha.mahadasha_order[index-1]).format("MMM DD/YYYY") }</h6>     
                                        ) : (
                                        <h6> { moment(StartYear).format("MMM DD/YYYY") }</h6>
                                        )}                     
                                    </div>
                                    <div className="venus_and_other_section">

                                        {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[index].map(antardashas => ( 
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                     <h6>{ moment(KundliMahaDasha.mahadasha_order[index]).format("MMM DD/YYYY") }</h6>                                             
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[index].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                        ))
                        ) : (
                        <p></p>
                        )}
                        {/* /////////////// */}
                        </div>

                </div>
                </div>
             </div>
        </div>
   
    </section>
{/* 
    <section className="year_dasha">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className=" dasha_full_detail_section">
                       
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">

                                        {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[0].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[0].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                    {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[1].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                   
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                 
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[1].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas[2].length > 0 ? (
                                        KundliAntarDasha.antardashas[2].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order[2].length > 0 ? (
                                    KundliAntarDasha.antardasha_order[2].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[3].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[3].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[4].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[4].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row second_year_table" style={{ justifyContent: 'start'}}>
                <div className="col-12">
                    <div className=" dasha_full_detail_section">
                      
                    <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[5].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[5].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[6].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[6].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[7].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[7].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="twenty_year_section_bar">
                            <div className="year_twenty_h_bar">
                                <h6>Venus 20 Years</h6>
                            </div>
                            <div className="dasha_remaining">
                                <div className="dasha_remaining_venus_bar">
                                    <div className="dasha_remaining_venus_bar_h_six">
                                        <h6>07/10/2023</h6>
                                    </div>
                                    <div className="venus_and_other_section">
                                       {KundliAntarDasha.antardashas.length > 0 ? (
                                        KundliAntarDasha.antardashas[8].map(antardashas => (
                                        <p>{antardashas}</p>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}
                                    </div>
                                </div>
                                <div className="dasha_remaining_date_bar">
                                    <h6>07/10/2023</h6>
                                    {KundliAntarDasha.antardasha_order.length > 0 ? (
                                    KundliAntarDasha.antardasha_order[8].map(antardasha_order => (
                                    <p>{antardasha_order}</p>
                                    ))
                                    ) : (
                                    <p></p>
                                    )}
                                </div>
                            </div>
                        </div>
                     

                    </div>
                </div>
            </div>
        </div>
   
    </section>
        */}
    <div className="details-container">
        <div className="first_name">
            <p className="first_name_paragraph">Dasha Prediction</p>
            </div>
        
    </div>

    {KundliMahaDashaPredictions.dashas.length > 0 ? (
           KundliMahaDashaPredictions.dashas.map(mahaDashaPredictions => (
    <section className="year_dasha">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="dasha_and_period_bar">
                        <div>
                            <p> <strong>Dasha : </strong>{mahaDashaPredictions.dasha}</p>
                        </div>
                        <div>
                            <p> <strong>Dasha Period :</strong> {mahaDashaPredictions.dasha_start_year}/{mahaDashaPredictions.dasha_end_year}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-12">
                    <div className="prediction_bar">
                        <h6>
                            Prediction :
                        </h6>
                    </div>
                </div>
                <div className="col-lg-10 col-md-10 col-12">
                    <div className="prediction_bar_paragraph">
                        <p>{mahaDashaPredictions.prediction}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-12">
                    <div className="planet_bar_section">
                        <h6>
                            planet in <br />zodiac :
                        </h6>
                    </div>
                </div>
                <div className="col-lg-10 col-md-10 col-12">
                    <div className="planet_bar_section_paragraph">
                    <p>{mahaDashaPredictions.planet_in_zodiac}</p>

                    </div>
                </div>
            </div>
        </div>
    </section>
    ))
    ) : (
    <h1 mt="4"> Not Found</h1>
    )}

</>
}
</>

  );
}
export default Dashas;