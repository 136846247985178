import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// import UserService from '../../services/UserServices'
//  import axios from "axios";
//  import InfiniteScroll from 'react-infinite-scroller'; //new
import { useDispatch, useSelector } from 'react-redux';
import { getCounsellors ,getCategoryLang } from "../../redux/action/UserApi";
import Loader from "../Pages/Loader";

 const Expertlist = ({
  session,
  address,
  category,
  chat_minute_price,
  chat_status,
  couns_id,
  discount,
  experiance,
  isVerified,
  language,
  live_status,
  minute_price,
  name,
  online,
  pic,
  pro_content,
  rating,
  sub_category,
}) => {
  return (
            <div key={couns_id} className="expert_list_box">
              <div className="expert_main_box">
                <div className="left_expert_img">
                  <span>
                    <img alt="test.jpg" src={pic} />
                  </span>
                </div>
                <div className="expert_details">
                  <div className="online_offline_btn">

                {
                online === '1'
                ? 
                  //  <img alt="test.jpg" src="./assets/images/online_btn.svg"/>
                   <img alt="test.jpg" src="./assets/images/expert.png"/>

                  : 
                online === '2'
                ? 
                  <><NavLink to="/expert-detail">Busy</NavLink > 
                  <img alt="test.jpg" src="./assets/images/expert.png"/></>
                :
                  <><NavLink to="/expert-detail">Notifiy</NavLink > 
                  {/* <img alt="test.jpg" src="./assets/images/offline_btn.svg"/></> */}
                  <img alt="test.jpg" src="./assets/images/red_online.png"/></>
                  

                }
             </div>
        
                  <div className="expert_names">
                    <span className="name">
                      <span className="first-name">{name}</span>
                      {/* <span className="last-name">Mahadik</span> */}
                      <div className="verify-icon">
                        <img alt="test.jpg" src="./assets/images/verified_icon.svg"  />
                      </div>
                    </span>
                    <div className="star-rating">
                      <i className="fa fa-star" />
                      <span className="rating-count">{rating}</span>
                    </div>
                    <div className="share-btn">
                    <img alt="test.jpg" src="./assets/images/share_btn.svg"  />
                    </div>
                  </div>
                  <p className="exper-role">
                    Professional Astrologer, - {category}
                  </p>
                  <div className="expert_features">
                    <a href="/expert-detail" className="feature-btn">
                      <span className="feature-icon">
                        <img alt="test.jpg" src="./assets/images/documents_icon.svg"  />
                      </span>
                      <span className="feature-text">Exp- {experiance} yr+</span>
                    </a>
                    <a href="/expert-detail" className="feature-btn">
                      <span className="feature-icon">
                        <img alt="test.jpg" src="./assets/images/session_icon.svg"  />
                      </span>
                      <span className="feature-text">{session}+ Session</span>
                    </a>
                    <a href="/expert-detail" className="feature-btn">
                      <span className="feature-icon">
                        <img alt="test.jpg" src="./assets/images/hindi_eng_icon.svg"  />
                      </span>
                      <span className="feature-text">{language}
                      </span>
                    </a>
                    <a href="/expert-detail" className="feature-btn">
                      <span className="feature-icon">
                        <img alt="test.jpg" src="./assets/images/location_icon.svg"  />
                      </span>
                      <span className="feature-text">{address}</span>
                    </a>
                  </div>
                </div>
              </div>
              <p className="expert_box_description">
             { pro_content.substring(0, 170) + "..." }
             <NavLink to={"/expert-detail/"+couns_id }>Show More</NavLink > 
              </p>
              <div className="experts_footers">
                <div className="experts_footers_btn">

                {sub_category &&
                sub_category.map(item => 
                <a href="/" className="expert-btn">
                {item}
                </a>
                )
                }        
                 
                </div>
                <div className="expert_call">
                  <span className="price_values">
                    <i className="fa fa-inr" aria-hidden="true" />
                    <span className="rate">{minute_price}</span>
                    <span>/</span>
                    <span className="minute">Min</span>
                  </span>
                  <a href={'/expert-detail/'+couns_id} className="call-btn">
                    <img alt="test.jpg" src="./assets/images/calling-icon.png"  />
                  </a>
                </div>
              </div>
            </div>
  );
};



function Expert() {


  const { counslists,loading , categoryList , languageList} = useSelector(
    state => state.user
  );
  
  const [language, setLanguage] = useState('');
  const [status, setStatus] = useState('');
  const [pricetype, setPricetype] = useState('');
  const [sub_category, setSub_category] = useState('');
  
  // const [counslist, setCounslist] = useState([]);
  // const [languagelist, setLanguagelist] = useState([]);
  // const [catlist, setCatlist] = useState([]);
  /// const [counsellor_list, setcounsellor_list] = useState([]);
  

  

  // var postdata = {
  //   longitude: 'test',
  //   latitude: 'testsmdlkasm',
  //   language: language,
  //   online:status,
  //   price:pricetype,
  //   sub_category:sub_category
  // };
 
  //console.log(postdata)
  const dispatch = useDispatch();

  //  const retrieveList = (postdata) => {
    
  //   dispatch(getCounsellors(postdata));

  //   // UserService.getCounsellor(postdata)
  //   // .then(function (response) {
  //   //      //handle success
  //   //      if(response.data.responseCode==="200"){
  //   //      setCounslist(response.data.counsellor_detail);
  //   //      }
  //   //      else{
  //   //       setCounslist([]);
  //   //      }
  //   //   //console.log(response);
  //   //    })
  //   //    .catch(function (response) {
  //   //      //handle error
  //   //      console.log(response);
  //   //    });
  //   };

    

    // const categoryList = () => {

    //   UserService.getCategory(postcatdata)
    //     .then(function (catsres) {
    //        //handle success
    //        setCatlist(catsres.data.category);
    //        setLanguagelist(catsres.data.language);

    //       // console.log(catsres);
    //     })
    //     .catch(function (catsres) {
    //         //handle error
    //         console.log(catsres);
    //     });
    // };
    // console.log(catlist);

    
    const handlechange = async (id) => {
        await  setLanguage(id);
        // postdata.language =  id ;
        // retrieveList();
    };

    const handlestatuschange =  (id) => {
        setStatus(id);
         console.log(id)
      //  postdata.online =  id ;
      // retrieveList();
    };

    
    const handlepricechange = async (id) => {
        await  setPricetype(id);
        // postdata.price =  id ;
        // retrieveList();
    };

    
    const handlecatchange = async (id) => {
      await  setSub_category(id);
      // postdata.sub_category =  id ;
      // retrieveList();
  };
    

   useEffect(() => {
        var postcatdata={
          category_id:1
        }
        dispatch(getCategoryLang(postcatdata));
   }, [dispatch]);
   
   useEffect(() => {
    // retrieveList();
    // categoryList();
    var getcounsdata = {
      longitude: 'test',
      latitude: 'testsmdlkasm',
      language: language,
      online:status,
      price:pricetype,
      sub_category:sub_category
    };
    dispatch(getCounsellors(getcounsdata));


   }, [dispatch,language,status,pricetype,sub_category]);
   
   

    
// console.log(language);
  return (
<>
{ loading ? <Loader /> :'' }

  {/* <section className="event_sliders">
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={0}
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={1}
          aria-label="Slide 2"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={2}
          aria-label="Slide 3"
        />
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img alt="test.jpg" src="./assets/images/banner1.jpg" className="d-block w-100"  />
          <div className="carousel-caption d-none d-md-block">
            <h5>
              <span>Talk To</span>
              <br /> Astrologer
            </h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat.....
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img alt="test.jpg" src="./assets/images/banner1.jpg" className="d-block w-100"  />
          <div className="carousel-caption d-none d-md-block">
            <h5>
              <span>Talk To</span>
              <br /> Astrologer
            </h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat.....
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img alt="test.jpg" src="./assets/images/banner1.jpg" className="d-block w-100"  />
          <div className="carousel-caption d-none d-md-block">
            <h5>
              <span>Talk To</span>
              <br /> Astrologer
            </h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat.....
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </section>
   */}
   <section className="event_sliders">
         <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
               <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="" aria-label="Slide 1"></button>
               <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2" className="active" aria-current="true"></button>
               <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3" className=""></button>
            </div>
            <div className="carousel-inner">
               <div className="carousel-item">
                  <img src="./assets/images/experttopbg.svg" className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                     <h5><span>Talk To</span><br/> Astrologer</h5>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus purus a varius consequat.....</p>
                  </div>
               </div>
               <div className="carousel-item active">
                  <img src="./assets/images/experttopbg.svg" className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                     <h5><span>Talk To</span><br/> Astrologer</h5>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus purus a varius consequat.....</p>
                  </div>
               </div>
               <div className="carousel-item">
                  <img src="./assets/images/experttopbg.svg" className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                     <h5><span>Talk To</span><br/> Astrologer</h5>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus purus a varius consequat.....</p>
                  </div>
               </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
            </button>
         </div>
      </section>

  <section className="expert_list">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
          <div className="filters">
            <div className="filters_heading">
              <h4>Filters</h4>
              <img alt="test.jpg" src="./assets/images/filter_btn.svg" />
            </div>
            <div className="filters_values">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <span>Skills</span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="filter_content">
                        <form>
                      
                        {categoryList.length > 0 ? (
                            categoryList.map((cat,index) => (
                          index >0 && 
                          <> <div className="form-check">
                            <input
                              type="checkbox"
                              className={sub_category===cat.id?"filtered checked":"filtered check-radio"} 
                              onClick={() => {
                                handlecatchange(sub_category!==cat.id?cat.id:'');
                              }}       
                              defaultValue={sub_category!==cat.id?cat.id:''}
                              defaultChecked={sub_category===cat.id?true:false}
                              />
                            <label>{cat.category}</label>
                          </div>
                          </>
                          ))
                          ) : '' }
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <span>Status</span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="filter_content">
                        <form>
                          

                          <div className="form-check">
                          <input
                          type="checkbox"
                          className={status==='1'?"filtered checked":"filtered check-radio"} 
                          onClick={() => {
                          handlestatuschange(status!=='1'?'1':'');
                          }}       
                          defaultValue={status!=='1'?'1':''}
                          defaultChecked={status==='1'?true:false}
                          />
                          <label>{'Online'}</label>
                          </div>
                          
                          <div className="form-check">
                          <input
                          type="checkbox"
                          className={status==='0'?"filtered checked":"filtered check-radio"} 
                          onClick={() => {
                          handlestatuschange(status!=='0'?'0':'');
                          }}       
                          defaultValue={status!=='0'?'0':''}
                          defaultChecked={status==='0'?true:false}
                          />
                          <label>{'offline'}</label>
                          </div>
                          
                          <div className="form-check">
                          <input
                          type="checkbox"
                          className={status==='2'?"filtered checked":"filtered check-radio"} 
                          onClick={() => {
                          handlestatuschange(status!=='2'?'2':'');
                          }}       
                          defaultValue={status!=='2'?'2':''}
                          defaultChecked={status==='2'?true:false}
                          />
                          <label>{'Busy'}</label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <span> Price Range</span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="filter_content">
                        <form>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className={pricetype==='2'?"filtered checked":"filtered check-radio"} 
                            onClick={() => {
                            handlepricechange(pricetype!=='2'?'2':'');
                            }}       
                            defaultValue={pricetype!=='2'?'2':''}
                            defaultChecked={pricetype==='2'?true:false}
                          />                         
                           <label>High to Low</label>
                          </div>

                        <div className="form-check">
                          <input
                              type="checkbox"
                              className={pricetype==='1'?"filtered checked":"filtered check-radio"} 
                              onClick={() => {
                                handlepricechange(pricetype!=='1'?'1':'');
                            }}       
                          defaultValue={pricetype!=='1'?'1':''}
                          defaultChecked={pricetype==='1'?true:false}
                          />
                            <label>Low to High</label>
                          </div>
                         
                          
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <span> Language</span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="filter_content">
                        <form>

                          {languageList.length > 0 ? (
                            languageList.map((d,index) => (
                          index >0 && 
                          <> <div className="form-check">

                            <input
                              type="checkbox"
                              className={language===d.id?"filtered checked":"filtered check-radio"} 
                              onClick={() => {
                                handlechange(language!==d.id?d.id:'');
                              }}       
                              defaultValue={language!==d.id?d.id:''}
                              defaultChecked={language===d.id?true:false}
                              />
                            <label>{d.name}</label>
                          </div>
                          </>
                          ))
                          ) : '' }

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      <span> Rating</span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="filter_content">
                        <form>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>5</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>4+</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>3 to 3.9</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>Numerologist</label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      <span>Problems</span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="filter_content">
                        <form>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>Love</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>Carrer</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>Relatisonship</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>Wealth</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="filtered"
                              data="language"
                              defaultValue="hindi"
                            />
                            <label>Health</label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-6 col-xs-12">
          <div className="expert_all_list">

          {counslists.length > 0 ? (
           counslists.map(expert_list => (
            <Expertlist
                address={expert_list.address}
                session={expert_list.session}
                category={expert_list.category}
                chat_minute_price={expert_list.chat_minute_price}
                chat_status={expert_list.chat_status}
                couns_id={expert_list.couns_id}
                discount={expert_list.discount}
                experiance={expert_list.experiance}
                isVerified={expert_list.isVerified}
                language={expert_list.language}
                live_status={expert_list.live_status}
                minute_price={expert_list.minute_price}
                name={expert_list.name}
                online={expert_list.online}
                pic={expert_list.pic}
                pro_content={expert_list.pro_content}
                rating={expert_list.rating}
                sub_category={expert_list.sub_category.split(' ')}
                /> 
                  ))
                ) : (
                  <h1 mt="4">Expert Not Found</h1>
                )}

          </div>
        </div>
      </div>
    </div>
  </section>
</>

  );
}
export default Expert;