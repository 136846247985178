import { createReducer } from "@reduxjs/toolkit";

const initialState ={
    totalRows:0,
    perPage:5,
    contentList:[],
    contentDetail:[],
    loading:false,
}

export const HomeContentReducer = createReducer(initialState,{
   
    
       getHomeContentListSuccess: (state, action) => {
          //  console.log(action.payload);
          state.loading = false;

          state.contentList = action.payload.content;
        },
        
        getHomeContentListFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },
     
        getHomeContentDetailSuccess: (state, action) => {
            console.log(action.payload);
            state.contentDetail = action.payload.content;
            state.loading = false;
        },
        
        getHomeContentDetailFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.contentDetail=[];

          },  
          loadingRequest: state => {
            state.loading = true;
        },
});