import React from 'react';

const Loader = ({ color = 'yellow.500' }) => {
  return (
    <div style={{ textAlign: 'center'}} >
    <img alt="test.jpg" style={{height: '100px'}} src="./assets/images/loader.gif"  />
    </div>
    // <div id="preloader" ><div id="status" >&nbsp;</div></div>
  );
};

export default Loader;
