// import http from "../http-common";
import axios from "axios";

const baseURL ="https://manageonline.in/demo/mibhagya/WEB_V21/";


const create_checksum  = postdata => {
    return axios({
        method: "post",
        url: baseURL+"paytm/live",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })

  };


const PaytmService = {
    create_checksum,
   
};

export default PaytmService;