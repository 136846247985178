import React, {  useEffect } from "react";
import { NavLink } from "react-router-dom";
//  import UserService from '../../services/UserServices'

 import { useDispatch, useSelector } from 'react-redux';
import { getCounsellors } from "../../redux/action/UserApi";
 


function Expertlist() {
  ///const [counslist, setCounslist] = useState([]);
 /// const [counsellor_list, setcounsellor_list] = useState([]);
 const { counslists } = useSelector(
  state => state.user
);

const dispatch = useDispatch();

useEffect(() => {
      var postdata = {
        longitude: 'test',
        latitude: 'testsmdlkasm'
      };
      dispatch(getCounsellors(postdata));
   
}, [dispatch]);



  // useEffect(() => {
     

  //   const retrieveCons = () => {

  //     UserService.getCounsellor(postdata)
  //     .then(function (response) {
  //         //handle success
  //              // setCons(response.data.counsellors);
  //              setCounslist(response.data.counsellor_detail);
  
  //        // console.log(response.data.counsellors);
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log(response);
  //       });
  
       
  //   };

  //   retrieveCons();

  // }, []);

 
  return (
<>

  <section className="our_astrlozaer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 heading_astro text-center">
          <h1 className="as_heading as_heading_center">
            Our <span>Astrologer</span>
          </h1>
          <p className="mbzero">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            purus a varius
            <br /> consequat. Mauris ligula ex, auctor vel tortor at, vulputate
            porta justo.
          </p>
        </div>
      </div>
      <div className="row">

      {counslists.length > 0 ? (
    counslists.slice(0, 5).map((expert_list,index) => (
      
        <div key={index}  className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <div className="services_box">
          <a href={"/expert-detail/"+expert_list.couns_id } className="services_box_online">
            <div className="on">
              
              <span className="online-icon">
                <span className="online-text">{expert_list.online==='1' || expert_list.online==='2' ? 'online' : 'offline' }</span>
                <span className={expert_list.online==='1' || expert_list.online==='2' ? 'btn-on' : 'btn-off' } />
              </span>
            </div>
            <span className="as_icon">
              <img alt="test.jpg" src={expert_list.pic}  />
            </span>
            <h4 className="as_subheading"> {expert_list.name}</h4>
            <p>Astrologer</p>
            <div className="experiences">
              
              <img alt="test.jpg"
                src="https://testapplication.in/mibhagya-web/new_assets/images/Vector.png"
                className="ast"
              />
              <span> Exp- {expert_list.experiance} yr+ </span>
              <img alt="test.jpg"
                src="https://testapplication.in/mibhagya-web/new_assets/images/astro2.png"
                className="ast"
              />
              <span>{expert_list.language}</span>
            </div>
            <div className="rate">
              
              <img alt="test.jpg"
                src="https://testapplication.in/mibhagya-web/new_assets/images/rating2.png"
              />
            </div>
            <b className="pricing">Rs.{expert_list.minute_price}/Min</b>
            <span className="callings">
              <img alt="test.jpg"
                src="https://testapplication.in/mibhagya-web/new_assets/images/call1.png"
                
              />
            </span>
          </a >
          <div className="astroo text-center">
            <h4>
            {expert_list.category} <span> Astro </span>
            </h4>
          </div>
        </div>
      </div>
                ))
                ) : (
                  <h1 mt="4">Expert Not Found</h1>
                )}
       {/* astro loop */ }
    

      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="explore_text">
            <NavLink to="/expert">Explore More</NavLink >
          </div>
        </div>
      </div>
    </div>
  </section>


</>

  );
}
export default Expertlist;