import React, {useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { getAshtakvarga} from "../../redux/action/HoroscopeApi";
import Loader from "../Pages/Loader";

function Ashtakvarga() {
    const { Ashtakvarga,  kundliFormdata} = useSelector(
        state => state.horoscope
      );
      const dispatch = useDispatch();

      useEffect(() => { /// het panchang
        // console.log(kundliFormdata.data);
        var postdata = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz //TimeZone
        };
        const loadbasic = async () => {
            dispatch(getAshtakvarga(postdata));
        }
        loadbasic();

        // console.log(postdata); 
        console.log('i fire once');

  }, [dispatch,kundliFormdata]);

  const myArray = ['first_text', 'third_text', 'second_text', 'four_text', 'five_text', 'six_text', 'seven_text', 'eight_text', 'nine_text', 'ten_text', 'eleven_text', 'thirteen_text'];

  console.log(Ashtakvarga);

  return (
<>
{ Ashtakvarga === ''? <Loader /> :
<>
        <div className="details-container">
            <div className="first_name">
                <p className="first_name_paragraph">Ashtakvarga</p>
            </div>
            <div className="second_name">
             <p>{ kundliFormdata.data.username }</p>
            </div>
        </div>

 
        <section className="dasha_first_section">
                                <div className="container">
                                    <div className="row">
                                    
                                    {Ashtakvarga.ashtakvarga_order.length > 0 ? (
                                                // recentBlogs.slice(0, 3).map((recentblogs,index) => (

                                        Ashtakvarga.ashtakvarga_order.map((ashtakvargaOrder,index) => (
                                     <>
                                       <div className="col-lg-4 col-md-4 col-12">
                                            <div className="sav_bar_section_dasha">
                                                <h4>{ashtakvargaOrder}</h4>
                                       
                                            </div>
                                            <div className="kundli_image_design">
                                                {/* <div className="kundli_image_design_two"></div>
                                                <div className="kundli_image_design_two_line_first"></div>
                                                <div className="kundli_image_design_two_line_second"></div> */}
                                                <img src="./assets/images/asc.svg" alt=""></img>
                                                { Ashtakvarga.ashtakvarga_points[index].map((points,i)=> (
                                                <>
                                                <div className={myArray[i]}>{points}</div>
                                                </>
                                                ))
                                                }

                                            </div>
                                        </div>
                                       
                                      </>
                                      ))
                                      ) : ( '' )}

                                        {/* <div className="col-lg-4 col-md-4 col-12">
                                            <div className="sav_bar_section_dasha">
                                                <h4>Sav</h4>
                                            </div>
                                            <div className="kundli_image_design">
                                                <div className="kundli_image_design_two"></div>
                                                <div className="kundli_image_design_two_line_first"></div>
                                                <div className="kundli_image_design_two_line_second"></div>
                                                <div className="first_text">28</div>
                                                <div className="third_text">28</div>
                                                <div className="second_text">28</div>
                                                <div className="four_text">28</div>
                                                <div className="five_text">28</div>
                                                <div className="six_text">31</div>
                                                <div className="seven_text">12</div>
                                                <div className="eight_text">20</div>
                                                <div className="nine_text">30</div>
                                                <div className="ten_text">25</div>
                                                <div className="eleven_text">25</div>
                                                <div className="thirteen_text">26</div>
                                            </div>
                                        </div> */}


                                    </div>
                                </div>
                            </section>

</>
}
</>

  );
}
export default Ashtakvarga;