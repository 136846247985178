import { createReducer } from "@reduxjs/toolkit";

    const initialState ={
        walletAmount:0,
        // user:'',
        isAstoOnline:'login',
        loading:false,
        isAstroAuthenticated : false,
        astroCat : [],
        subCat : [],
        specialisation:[],
    }

export const authReducer = createReducer(initialState,{

    walletmoney : (state,action) =>{
      state.walletAmount = action.payload;
    }, 


    otpVerificationRequest: state => {
      state.loading = true;
    },
    otpSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user_details;
      state.hashToken = action.payload.hash_token;
      state.message = action.payload.responseMessage;
      window.localStorage.clear();
      window.sessionStorage.clear();

      window.localStorage.setItem('isLogedIn', true);
      window.localStorage.setItem('userId', action.payload.user_id);
      window.localStorage.setItem('hashToken', action.payload.hash_token);
      // window.sessionStorage.setItem('online', action.data.payload.online);
      // window.sessionStorage.setItem('profile_pic', action.payload.user_details.profile_pic);
      // window.sessionStorage.setItem('email', action.payload.user_details.email);
      // window.sessionStorage.setItem('mobile_no', action.payload.user_details.mobile_no);
      // window.sessionStorage.setItem('name', action.payload.user_details.first_name+' '+action.payload.user_details.last_name);
      // window.sessionStorage.setItem('first_name', action.payload.user_details.first_name);
      // window.sessionStorage.setItem('last_name', action.payload.user_details.last_name);

    },
    otpFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },


    astoLoginSuccess: (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.isAstroAuthenticated = true;
        state.user = action.payload.user_details;
        state.hashToken = action.payload.hash_token;
        state.message = action.payload.responseMessage;
        window.localStorage.clear();
        window.sessionStorage.clear();

        window.localStorage.setItem('isAstroLogedIn', true);
        window.localStorage.setItem('isLogedIn', true);
        window.localStorage.setItem('userId', action.payload.user_id);
        window.localStorage.setItem('hashToken', action.payload.hash_token);
        // window.sessionStorage.setItem('online', action.data.payload.online);
        // window.sessionStorage.setItem('profile_pic', action.payload.user_details.profile_pic);
        // window.sessionStorage.setItem('email', action.payload.user_details.email);
        // window.sessionStorage.setItem('mobile_no', action.payload.user_details.mobile_no);
        // window.sessionStorage.setItem('name', action.payload.user_details.first_name+' '+action.payload.user_details.last_name);
        // window.sessionStorage.setItem('first_name', action.payload.user_details.first_name);
        // window.sessionStorage.setItem('last_name', action.payload.user_details.last_name);

    },
    astoLoginFail: (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isAstroAuthenticated = false;
        state.error = action.payload;
        window.localStorage.clear();
        window.sessionStorage.clear();
    },

    logoutSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.isAstroAuthenticated = false;
      state.user = null;
      state.message = action.payload.responseMessage;
      window.localStorage.clear();
      window.sessionStorage.clear();
    },

    astoRegisSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    astoRegisFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },

    loadUserSuccess: (state, action) => {
          state.loading = false;
          state.isAuthenticated = true;
          state.user = action.payload.user_details;
    },
    loadUserFail: (state, action) => {
          state.loading = false;
          state.isAuthenticated = false;
          // state.error = action.payload;
    },

    astroCategorySuccess: (state, action) => {
          state.loading = false;
          state.astroCat = action.payload.cat.categories;
          state.specialisation= action.payload.cat.specialisation;
    },
    astroCategoryFail: (state, action) => {
          state.loading = false;
          state.astroCat = [];
          state.specialisation = [];
    },
    astroSubCategorySuccess: (state, action) => {
          state.loading = false;
          state.subCat = action.payload.subcat;
    },
    astroSubCategoryFail: (state, action) => {
          state.loading = false;
          state.subCat = [];
    },

    astoOnlineSuccess: (state, action) => {
          state.loading = false;
          state.isAstoOnline= action.payload.online;
         console.log(action.payload);
          state.message = action.payload.responseMessage;
    },

    astoOnlineFail: (state, action) => {
          state.loading = false;
          state.isAstoOnline=true;
          state.message = action.payload;
    },


    SessionExpired: (state, action) => { //Session expired.
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
      state.isAstroAuthenticated = false;
      state.user ='';
      window.localStorage.clear();
      window.sessionStorage.clear();
    },

    clearError: state => {
        state.error = null;
    },

    clearMessage: state => {
        state.message = null;
    },


});