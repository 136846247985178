import { configureStore } from "@reduxjs/toolkit";
import { modelReducer } from "./Reducer/modelReducer";
import { authReducer } from "./Reducer/AuthReducer";
import { userapiReducer } from "./Reducer/UserApiReducer";
import { HoroscopeReducer } from "./Reducer/HoroscopeReducer";
import { BlogReducer } from "./Reducer/BlogReducer";
import { WorldCityReducer } from "./Reducer/WorldCityReducer";
import { HomeContentReducer } from "./Reducer/HomeContentReducer";
 
const store = configureStore({
    reducer:{
        auth: authReducer,
        mymodel: modelReducer,
        user: userapiReducer,
        horoscope: HoroscopeReducer,
        blog: BlogReducer,
        worldcity: WorldCityReducer,
        home: HomeContentReducer,
    },

});

export default store;

export const server = 'https://manageonline.in/demo/mibhagya/WEB_V21/';
