import React , {  useState, useEffect } from "react";
// import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { astoOnline } from "../../redux/action/Auth";
// import { toast } from "react-toastify";


const UserSidbar = ({ user }) => {
  
  const dispatch = useDispatch();

  // console.log(user);  
  const isAstroAuthenticated = localStorage.getItem('isAstroLogedIn');
  // const isAstoOnline = localStorage.getItem('isAstoOnline');
  const [isUserOnline, setIsToggled] = useState(user.online);

  const { isAstoOnline } = useSelector(
    state => state.auth
  );

  useEffect(() => {
    if(isAstoOnline!='login'){
       setIsToggled(isAstoOnline);
    }
     //console.log(isAstoOnline);

  }, [user,dispatch,isAstroAuthenticated,isAstoOnline]);



const handleChange = () => {
   
    var postUserdata = {
    userId: localStorage.getItem('userId'),
    hash_token:localStorage.getItem('hashToken'),
    // online:isAstoOnline===1?0:1,
    online:isUserOnline==1?0:1, 
    };
    dispatch(astoOnline(postUserdata));

};



return (
<>
            <div className="wallet_left">
              <div className="wallet_id">
                <div className="profile_img">
                 <span><img src={ user.profile_pic?user.profile_pic:"./assets/images/profile.svg" } alt="user"/></span> 
                 <p> { user.first_name+' '+user.last_name }</p>
                </div>
                <div className="profile_name">

              {isAstroAuthenticated ?
                  
                  <p>
                  <div className="form-check form-switch">
                  <label className="form-check-label" for="flexSwitchCheckChecked">{isUserOnline==1 ? 'Online' : 'Offline'}</label>
                    <input onClick={handleChange} defaultChecked= {isUserOnline==1 ? 'checked' : '' } className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                  </div>
                  </p>
                  :

                  <p> { user.online } { user.online=='1'?'Online':'Offline' }</p>
               }




{/* <button onClick={handleChange} className={`form-check-label toggle-button ${isToggled ? 'on' : 'off'}`}>
  {isToggled ? 'ON' : 'OFF'}
</button> */}
                </div>
                <div className="user_since">
                  <p>User Since March 2022</p>
                </div>
              </div>
              <div className="profile_menus">

              {isAstroAuthenticated ?
                  <NavLink className="nav-link" to="/astro/profile" >
                  <span><img src="./assets/images/sattings.svg" alt=""/></span>Profile
                  </NavLink>:

                  <NavLink className="nav-link" to="/user/profile" >
                  <span><img src="./assets/images/sattings.svg" alt=""/></span>Profile
                  </NavLink>
               }

               

                <NavLink className="nav-link" to="/user/help-and-support" >
                <span><img src="./assets/images/help.svg" alt=""/></span>Help
                </NavLink>

                <NavLink className="nav-link" to="/" >
                <span><img src="./assets/images/logout.svg" alt=""/></span>Log Out
                </NavLink>
               
              </div>
            </div>
       
</>
);
}

export default UserSidbar;