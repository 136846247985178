import { NavLink } from "react-router-dom";
import React  from "react";

function Footer() {

  // const showMorning = () => toast("Morning! Have a good day.");
  // const showSuccess = () => toast.success("Message sent successfully!");
  // const showError = () => toast.error("Oops! Some error occurred.");
  // const showInfo = () => toast.info("Did you know about this feature?");
  // const showWarn = () => toast.warn("This may be dangerous!");

  return (
    <>
 
  <footer>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <div className="as_footer_logo1">
              <h3>Astro Solution</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="see text-center">
            <a href="/#" className="as_link as_link1">
              See More +
            </a>
          </div>
        </div>
      </div>
      <div className="row fot">
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <div className="as_footer_logo1">
              <h3>Top Cities</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          <div className="as_footer_widget">
            <h3 className="as_footer_heading">City Lorem Ipsum</h3>
            <ul>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
              <li>
                <a href="/#">City Lorem Ipsum</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="see2 text-center">
            <a href="/#" className="as_link as_link1">
              See More +
            </a>
          </div>
        </div>
      </div>
      <div className="row footer_navbar">
        <div className="footer_navs">
          <div className="as_footer_detail">
            <div className="row">
              <div className="col-md-2">
                <div className="as_logo1">
                  <a href="/#">
                    <img alt="test.jpg" src="./assets/images/logo-main.svg" />
                  </a>
                </div>
              </div>
              <div className="col-md-10">
                <div className="as_menu_wrapper1">
                  <div className="as_menu as_menu1">
                    <ul>
                      <li>
                      <NavLink  to="/">home</NavLink>
                      </li>
                      <li>
                      <NavLink  to="/our-inspiration">our inspiration</NavLink>
                      </li>
                      <li>
                      <NavLink  to="/expert">talk to astrologer</NavLink>
                      </li>
                      <li>
                      <NavLink  to="/">kundli</NavLink>
                      </li>
                      <li>
                      <NavLink  to="/horoscope">horoscope</NavLink>
                      </li>
                      <li>
                      <NavLink  to="/blog">astro blog</NavLink>
                      </li>
                     
                      <li>
                      <NavLink  to="/astro-login">Astro Login</NavLink>
                      </li>
                      <li>
                      <NavLink  to="/astro-registration">astro Registration</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <span className="as_body_overlay" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="as_share_box text-center">
            <ul className="social">
              <li>
                <a href="/#">
                  <img alt="test.jpg" src="./assets/images/insta.svg"  />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img alt="test.jpg" src="./assets/images/linkdin.svg"  />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img alt="test.jpg" src="./assets/images/youtube.svg"  />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img alt="test.jpg" src="./assets/images/facebook.svg"  />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img alt="test.jpg" src="./assets/images/whatspp.svg"  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div className="scroll-to-top" >
      <a href="https://play.google.com/store/apps/details?id=com.aks.mibhagyaUser"   rel="noopener noreferrer"  id="chat_button" target="_blank" className="chat">
      <img src="./assets/images/chat.png" alt="" />
      </a> 
   </div>
</>

  );
}

export default Footer;