import React, {useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { getKundliBasic, panchangChartImage, panchangChartImageD9 } from "../../redux/action/HoroscopeApi";
import Loader from "../Pages/Loader";
import ChartSvg from "../Pages/ChartSvg";

function KundliChart() {

const { kundliBasic, chartImage, chartD9Image, kundliFormdata } = useSelector(
            state => state.horoscope
  );

  const dispatch = useDispatch();

  useEffect(() => { /// het panchang
    var postdata = {
        dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
        tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
        lat:kundliFormdata.data.lat, //Latitude
        lon:kundliFormdata.data.lon, //Longitude
        api_key:kundliFormdata.data.api_key, //Your API key
        lang:kundliFormdata.data.lang, //language
        tz  :kundliFormdata.data.tz //TimeZone
    };
    const loadbasic = async () => {
        dispatch(getKundliBasic(postdata));
    }
    loadbasic();
    console.log('i fire once');


    const loadchart = async () => {
            
        var postdata1 = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz, //TimeZone
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            div:'D1',
            color:'%23ff3366',
            style:'north',
            font_size:'12',
            font_style:'Nunito',
            colorful_planets:'0',
            size:'300',
            stroke:'2',
        };
        dispatch(panchangChartImage(postdata1));
        var postdata2 = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz, //TimeZone
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            div:'D9',
            color:'%23ff3366',
            style:'north',
            font_size:'12',
            font_style:'Nunito',
            colorful_planets:'0',
            size:'300',
            stroke:'2',
        };
        dispatch(panchangChartImageD9(postdata2));
    }
    loadchart();


}, [dispatch, kundliFormdata]);


  return (
<>
{ kundliBasic === '' ? <Loader /> :
<>
    <div className="details-container">
            <div className="first_name">
            <p className="first_name_paragraph">Kundli Chart</p>
            </div>
            <div className="second_name">
                 <p>{ kundliFormdata.data.username }</p>
            </div>
    </div>

    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="lagna_bar_section">
                        <button type="button" className="btn btn-primary">Lagna</button>
                    </div>
                    <div className="lagna_bar_section_img">
                    { chartImage ? <ChartSvg chartsvgdata={ {svg:chartImage} }  /> :''}

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="navamsha_bar_section">
                        <button type="button" className="btn btn-primary">Navamsha</button>
                    </div>
                    <div className="navamsha_bar_section_img">
                    { chartD9Image ? <ChartSvg chartsvgdata={ {svg:chartD9Image} }  /> :''}
                    </div>
                </div>
            </div>
        </div>
    </section>
        <div className="details-container">
            <div className="first_name">
            <p className="first_name_paragraph">Planet Details</p>
            </div>
    </div>

    <section className="planet_details_section_bar">
        <div className="container-fluid">
            <div className="row planet_details_section_bar_table">
                <table>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Zodiac</th>
                            <th>Zodiac Lord</th>
                            <th>Nakshatra</th>
                            <th>Nakshatra Lord</th>
                            <th>Degree</th>
                            <th>Retro (R)</th>
                            <th>House</th>
                        </tr>
                        <tr className="ascendant_section_bar">
                            <td>{kundliBasic[0].full_name}</td>
                            <td>{kundliBasic[0].zodiac}</td>
                            <td>{kundliBasic[0].zodiac_lord}</td>
                            <td>{kundliBasic[0].nakshatra}</td>
                            <td>{kundliBasic[0].nakshatra_lord}</td>
                            <td>{kundliBasic[0].local_degree.toFixed(2)}</td>
                            <td>-</td>
                            <td>{kundliBasic[0].house}</td>
                        </tr>
                        <tr className="sun_section_bar">
                            <td>{kundliBasic[1].full_name}</td>
                            <td>{kundliBasic[1].zodiac}</td>
                            <td>{kundliBasic[1].zodiac_lord}</td>
                            <td>{kundliBasic[1].nakshatra}</td>
                            <td>{kundliBasic[1].nakshatra_lord}</td>
                            <td>{kundliBasic[1].local_degree.toFixed(2) }</td>
                            <td>{kundliBasic[1].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[1].house}</td>
                        </tr>
                        <tr className="moon_section_bar">
                            <td>{kundliBasic[2].full_name}</td>
                            <td>{kundliBasic[2].zodiac}</td>
                            <td>{kundliBasic[2].zodiac_lord}</td>
                            <td>{kundliBasic[2].nakshatra}</td>
                            <td>{kundliBasic[2].nakshatra_lord}</td>
                            <td>{kundliBasic[2].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[2].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[2].house}</td>
                        </tr>
                        <tr className="sun_section_bar">
                            <td>{kundliBasic[3].full_name}</td>
                            <td>{kundliBasic[3].zodiac}</td>
                            <td>{kundliBasic[3].zodiac_lord}</td>
                            <td>{kundliBasic[3].nakshatra}</td>
                            <td>{kundliBasic[3].nakshatra_lord}</td>
                            <td>{kundliBasic[3].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[3].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[3].house}</td>
                        </tr>
                        <tr className="moon_section_bar">
                            <td>{kundliBasic[4].full_name}</td>
                            <td>{kundliBasic[4].zodiac}</td>
                            <td>{kundliBasic[4].zodiac_lord}</td>
                            <td>{kundliBasic[4].nakshatra}</td>
                            <td>{kundliBasic[4].nakshatra_lord}</td>
                            <td>{kundliBasic[4].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[4].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[4].house}</td>
                        </tr>
                        <tr className="sun_section_bar">
                            <td>{kundliBasic[5].full_name}</td>
                            <td>{kundliBasic[5].zodiac}</td>
                            <td>{kundliBasic[5].zodiac_lord}</td>
                            <td>{kundliBasic[5].nakshatra}</td>
                            <td>{kundliBasic[5].nakshatra_lord}</td>
                            <td>{kundliBasic[5].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[5].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[5].house}</td>
                        </tr>
                        <tr className="moon_section_bar">
                            <td>{kundliBasic[6].full_name}</td>
                            <td>{kundliBasic[6].zodiac}</td>
                            <td>{kundliBasic[6].zodiac_lord}</td>
                            <td>{kundliBasic[6].nakshatra}</td>
                            <td>{kundliBasic[6].nakshatra_lord}</td>
                            <td>{kundliBasic[6].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[6].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[6].house}</td>
                        </tr>
                        <tr className="sun_section_bar">
                            <td>{kundliBasic[7].full_name}</td>
                            <td>{kundliBasic[7].zodiac}</td>
                            <td>{kundliBasic[7].zodiac_lord}</td>
                            <td>{kundliBasic[7].nakshatra}</td>
                            <td>{kundliBasic[7].nakshatra_lord}</td>
                            <td>{kundliBasic[7].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[7].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[7].house}</td>
                        </tr>
                        <tr className="moon_section_bar">
                            <td>{kundliBasic[8].full_name}</td>
                            <td>{kundliBasic[8].zodiac}</td>
                            <td>{kundliBasic[8].zodiac_lord}</td>
                            <td>{kundliBasic[8].nakshatra}</td>
                            <td>{kundliBasic[8].nakshatra_lord}</td>
                            <td>{kundliBasic[8].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[8].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[8].house}</td>
                        </tr>
                        <tr className="sun_section_bar">
                            <td>{kundliBasic[9].full_name}</td>
                            <td>{kundliBasic[9].zodiac}</td>
                            <td>{kundliBasic[9].zodiac_lord}</td>
                            <td>{kundliBasic[9].nakshatra}</td>
                            <td>{kundliBasic[9].nakshatra_lord}</td>
                            <td>{kundliBasic[9].local_degree.toFixed(2)}</td>
                            <td>{kundliBasic[9].retro===true?'1':'-'}</td>
                            <td>{kundliBasic[9].house}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
}
</>

  );
}
export default KundliChart;