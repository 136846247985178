import React, {useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { getPlanetreport, getPersonalcharacteristics, getKaalsarpDosh,  getMangalDosh, getManglikDosh, getPitraDosh,
     getGemSuggestion,   getGemNumeroTable } from "../../redux/action/HoroscopeApi";
import Loader from "../Pages/Loader";
import  CountUp  from "react-countup";
function KundliReport() {
    const { Planetreport, Characteristics, MangalDosh, KaalsarpDosh, ManglikDosh, PitraDosh, kundliFormdata,
           gemSuggestion,
            gemNumeroTable } = useSelector(
            state => state.horoscope
         );
      const dispatch = useDispatch();


      useEffect(() => { /// het panchang
        // console.log(kundliFormdata.data);

        var postdata = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz, //TimeZone
            planet:'Jupiter'
        };


        const loadbasic = async () => {
            dispatch(getPlanetreport(postdata));
        }
        loadbasic();

        var postdata1 = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz, //TimeZone
        };
        const loadbasicPersonal = async () => {
            dispatch(getPersonalcharacteristics(postdata1));
        }
        loadbasicPersonal();


        const getKaalsarpDoshdata = async () => {
            dispatch(getKaalsarpDosh(postdata1));
        }
        getKaalsarpDoshdata();

        const getMangalDoshdata = async () => {
            dispatch(getMangalDosh(postdata1));
        }
        getMangalDoshdata();

        const getManglikDoshdata = async () => {
            dispatch(getManglikDosh(postdata1));
        }
        getManglikDoshdata();

        const getPitraDoshdata = async () => {
            dispatch(getPitraDosh(postdata1));
        }
        getPitraDoshdata();

        
        // getGemSuggestion,
        const getDataGemSuggestion = async () => {
            dispatch(getGemSuggestion(postdata1));
        }
        getDataGemSuggestion();
        // getGemNumeroTable
        var postdata2 = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz, //TimeZone
            name  :kundliFormdata.data.username, //Name
            
        };
        const getDataGemNumeroTable = async () => {
            dispatch(getGemNumeroTable(postdata2));
        }
        getDataGemNumeroTable(); 

        console.log('i fire once');

  }, [dispatch, kundliFormdata]);
        
  console.log(gemSuggestion);
  console.log(gemNumeroTable);
 
  return (
<>
<div className="details-container">
                    <div className="first_name">
                        <p className="first_name_paragraph">Kundli Report</p>
                    </div>
                    <div className="second_name">
                      <p>{ kundliFormdata.data.username }</p>
                    </div>
                  </div>

                  <section className="kundali_report_tabs">
                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="pills-planet-tab" data-bs-toggle="pill"
                                  data-bs-target="#pills-planet" type="button" role="tab"
                                  aria-controls="pills-planet" aria-selected="true">Planet Report</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-Personal-tab" data-bs-toggle="pill"
                                  data-bs-target="#pills-Personal" type="button" role="tab"
                                  aria-controls="pills-Personal" aria-selected="false">Personal</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-dosha-tab" data-bs-toggle="pill"
                                  data-bs-target="#pills-dosha" type="button" role="tab"
                                  aria-controls="pills-dosha" aria-selected="false">Dosha</button>
                          </li>
                          <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-gems-tab" data-bs-toggle="pill"
                                  data-bs-target="#pills-gems" type="button" role="tab"
                                  aria-controls="pills-gems" aria-selected="false">Gems / Number</button>
                          </li>
                      
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-planet" role="tabpanel"
                              aria-labelledby="pills-planet-tab">
                            { !Planetreport ? <Loader /> :
                            <>
                              <section className="chart_section_bar">
                                  <div className="container-fluid">
                                      <div className="row">
                                          <div className="col-lg-12 col-md-12 col-12">
                                              <div className="planet_report_section planet_report_section_new-bg">
                                                  <div className=" planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Planet</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                          <p>{Planetreport[0].planet_considered}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Planet Zodiac</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{Planetreport[0].planet_zodiac}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Zodiac Lord</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{Planetreport[0].zodiac_lord}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Lord Strength</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{Planetreport[0].zodiac_lord_strength}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Planet Strength</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{Planetreport[0].planet_strength}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="col-lg-2 col-md-2 col-12">
                                              <div className="prediction_bar">
                                                  <h6>
                                                      Prediction
                                                  </h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-10 col-md-10 col-12">
                                              <div className="prediction_bar_paragraph">
                                              <p>{Planetreport[0].general_prediction}</p>

                                              </div>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="col-lg-2 col-md-2 col-12">
                                              <div className="planet_bar_section">
                                                  <h6>
                                                      Character
                                                  </h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-10 col-md-10 col-12">
                                              <div className="planet_bar_section_paragraph">
                                              <p>
                                              {Planetreport[0].character_keywords_positive.length > 0 ? (
                                        Planetreport[0].character_keywords_positive.map(character => (
                                          <span> {character}, </span> 
                                        ))
                                        ) : ( '' )}
                                        </p>

                                              </div>
                                          </div>
                                      </div>
                                      <div className="row ">
                                          <div className="col-lg-2 col-md-2 col-12 mantra_section_bar">
                                              <div className="planet_bar_section">
                                                  <h6>
                                                      Mantra
                                                  </h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-10 col-md-10 col-12 mantra_section_bar">
                                              <div className="planet_bar_section_paragraph">
                                              <p>{Planetreport[0].gayatri_mantra}</p>

                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </section>
                                </>
                                }
                          </div>

                          <div className="tab-pane fade show " id="pills-Personal" role="tabpanel"
                              aria-labelledby="pills-Personal-tab">
                              
                            { !Characteristics ? <Loader /> :
                            <>
                            <section className="chart_section_bar">

                                  <div className="container-fluid">
                                  {Characteristics.length > 0 ? (
                                        Characteristics.map(personal => (
                                     <>
                                     <div className="row">
                                          <div className="col-lg-12 col-md-12 col-12">
                                              <div className="planet_report_section planet_report_section_new-bg">
                                                  <div className=" planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>House</h5>
                                                      </div>
                                                      <div  className="planet_report_bar_section_paragraph ashtakvarga_one_paragraph_change">
                                                         <p>{personal.current_house}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Plane Zodiac</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{personal.current_zodiac}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Zodiac Lord</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{personal.lord_of_zodiac}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Lord Strength</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{personal.lord_strength}</p>
                                                      </div>
                                                  </div>
                                                  <div className="planet_report_bar_section">
                                                      <div className="planet_report_bar_section_h-bar">
                                                          <h5>Planet Strength</h5>
                                                      </div>
                                                      <div className="planet_report_bar_section_paragraph">
                                                      <p>{personal.lord_strength}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="col-lg-2 col-md-2 col-12">
                                              <div className="prediction_bar">
                                                  <h6>
                                                      Prediction
                                                  </h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-10 col-md-10 col-12">
                                              <div className="prediction_bar_paragraph">
                                                  <p>{personal.personalised_prediction}</p>
                                              </div>
                                          </div>
                                      </div>
                                      </>
                                      ))
                                      ) : ( '' )}
                                  </div>
                              </section>
                            </>
                            }
                          </div>

                          <div className="tab-pane fade" id="pills-dosha" role="tabpanel" aria-labelledby="pills-dosha-tab">

                              <section className="present_section_last">
                                  <div className="container-fluid">
                                      <div className="row">
                                          <div className="col-lg-12 col-md-12 col-12">
                                          { !MangalDosh ? <Loader /> :   
                                          <>
                                          <div className="present_bar_section">
                                                  <div className="mangal_dasha_bar new-dasha-bg-bar">
                                                      <div className="planet_report_bar_section">
                                                          <div className="planet_report_bar_section_h-bar">
                                                              <h5>Dosha</h5>
                                                          </div>
                                                          <div className="planet_report_bar_section_paragraph">
                                                              <p>Mangal Dosha</p>
                                                          </div>
                                                      </div>
                                                      <div className="planet_report_bar_section">
                                                          <div className="planet_report_bar_section_h-bar">
                                                              <h5>Dosha Present</h5>
                                                          </div>
                                                          <div className="planet_report_bar_section_paragraph">
                                                              <p>{MangalDosh.is_dosha_present?'Yes':'No'}</p>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="present_bar">
                                                      <h6>
                                                          Dosha Score
                                                      </h6>

                                                    <span className="dayspercent"><b>
                                                        <CountUp start={0} end={MangalDosh.score} delay={0}
                                                        enableScrollSpy={true}
                                                        scrollSpyDelay={0}
                                                        />%</b></span>
                                                      {/* <img src={"./assets/images/presentez.svg"} alt="" /> */}
                                                  </div>
  
                                              </div>
  
                                              <div className="col-lg-12 col-md-12 col-12">
                                                  <div className="present_paragraph">
                                                  <p>{MangalDosh.factors.mars}<br/>
                                                  {MangalDosh.factors.moon}<br/>
                                                  {MangalDosh.factors.saturn}<br />
                                                  {MangalDosh.factors.venus}</p>
                                                  </div>
                                             </div>
                                            </>
                                            }

                                       { !KaalsarpDosh ? <Loader /> :   
                                          <>
                                              <div className="col-lg-12 col-md-12 col-12">
                                              <div className="col-lg-8 col-md-8 col-12 mb-5">
                                                  <div className="mangal_dasha_bar new-dasha-bg-bar">
                                                      <div className="planet_report_bar_section">
                                                          <div className="planet_report_bar_section_h-bar">
                                                              <h5>Dosha</h5>
                                                          </div>
                                                          <div className="planet_report_bar_section_paragraph">
                                                              <p>Kalpaalsarp</p>
                                                          </div>
                                                      </div>
                                                      <div className="planet_report_bar_section">
                                                          <div className="planet_report_bar_section_h-bar">
                                                              <h5>Dosha Present</h5>
                                                          </div>
                                                          <div className="planet_report_bar_section_paragraph">
                                                              <p>{KaalsarpDosh.is_dosha_present?'Yes':'No'}</p>
                                                          </div>
                                                      </div>
                                                      {/* <div className="planet_report_bar_section">
                                                          <div className="planet_report_bar_section_h-bar">
                                                              <h5>Dosha Type</h5>
                                                          </div>
                                                          <div className="planet_report_bar_section_paragraph">
                                                              <p>Shankalp</p>
                                                          </div>
                                                      </div>
                                                      <div className="planet_report_bar_section">
                                                          <div className="planet_report_bar_section_h-bar">
                                                              <h5>Direction</h5>
                                                          </div>
                                                          <div className="planet_report_bar_section_paragraph">
                                                              <p>Descending</p>
                                                          </div>
                                                      </div> */}
                                                      <div>
  
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>
  
                                              <div className="col-lg-12 col-md-12 col-12">
                                                  <div className="present_paragraph">
                                                  <p>{KaalsarpDosh.bot_response}</p>
                                                  </div>
                                              </div>
                                        </>
                                        }
                                        { !ManglikDosh ? <Loader /> :   
                                        <>
                                              <div className="col-lg-12 col-md-12 col-12">
                                                  <div className="present_bar_section">
                                                      <div className="mangal_dasha_bar new-dasha-bg-bar">
                                                          <div className="planet_report_bar_section">
                                                              <div className="planet_report_bar_section_h-bar">
                                                                  <h5>Dosha</h5>
                                                              </div>
                                                              <div className="planet_report_bar_section_paragraph">
                                                                  <p>Manglik</p>
                                                              </div>
                                                          </div>
                                                          <div className="planet_report_bar_section">
                                                              <div className="planet_report_bar_section_h-bar">
                                                                  <h5>Mangil By Mars</h5>
                                                              </div>
                                                              <div className="planet_report_bar_section_paragraph">
                                                              <p>{ManglikDosh.manglik_by_mars?'Yes':'No'}</p>
                                                              </div>
                                                          </div>
                                                          <div className="planet_report_bar_section">
                                                              <div className="planet_report_bar_section_h-bar">
                                                                  <h5>Mangil By Saturn</h5>
                                                              </div>
                                                              <div className="planet_report_bar_section_paragraph">
                                                              <p>{ManglikDosh.manglik_by_saturn?'Yes':'No'}</p>
                                                              </div>
                                                          </div>
                                                          <div className="planet_report_bar_section">
                                                              <div className="planet_report_bar_section_h-bar">
                                                                  <h5>Mangil By Rahu/Ketu</h5>
                                                              </div>
                                                              <div className="planet_report_bar_section_paragraph">
                                                              <p>{ManglikDosh.manglik_by_rahuketu?'Yes':'No'}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="present_bar">
                                                          <h6>
                                                              Dosha Score
                                                          </h6>
                                                              <span className="dayspercent"><b><CountUp start={0} end={ManglikDosh.score} delay={0} />%</b></span>

                                                          {/* <img src={"./assets/images/presentez.svg"} alt="" /> */}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-lg-12 col-md-12 col-12">
                                                  <div className="present_paragraph">
                                                      <p> {ManglikDosh.factors[0]} </p>
                                                  </div>
                                              </div>
                                        </>
                                        }
                                        { !PitraDosh ? <Loader /> :   
                                        <>
                                              <div className="col-lg-12 col-md-12 col-12">
                                                  <div className="present_bar_section">
                                                      <div className="mangal_dasha_bar new-dasha-bg-bar">
                                                          <div className="planet_report_bar_section">
                                                              <div className="planet_report_bar_section_h-bar">
                                                                  <h5>Dosha</h5>
                                                              </div>
                                                              <div className="planet_report_bar_section_paragraph">
                                                                  <p>Pitra Dosha</p>
                                                              </div>
                                                          </div>
                                                          <div className="planet_report_bar_section">
                                                              <div className="planet_report_bar_section_h-bar">
                                                                  <h5>Dosha Present</h5>
                                                              </div>
                                                              <div className="planet_report_bar_section_paragraph">
                                                              <p>{PitraDosh.is_dosha_present?'Yes':'No'}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
  
                                              </div>
                                              <div className="col-lg-12 col-md-12 col-12">
                                                  <div className="present_paragraph">
                                                  <p>{PitraDosh.bot_response}</p>

                                                  </div>
                                              </div>
                                        </>
                                        }
                                          </div>

                                      </div>
                           
                                  </div>
                              </section>
                    
                          </div>

                          <div className="tab-pane fade" id="pills-gems" role="tabpanel" aria-labelledby="pills-dosha-tab">
                         
                          <section className="present_section_last">
                            <div className="container-fluid">
                                <div className="row">
                            
                                    <div className="col-lg-12 col-md-12 col-12">
                                    { !gemSuggestion ? <Loader /> :   
                                        <>
                                        <div className="present_bar_section">
                                            <div className="mangal_dasha_bar new-dasha-bg-bar">
                                                <div className="planet_report_bar_section">
                                                    <div className="planet_report_bar_section_h-bar">
                                                        <h5>Gem</h5>
                                                    </div>
                                                    <div className="planet_report_bar_section_paragraph">
                                                        <p>{gemSuggestion.name}</p>
                                                    </div>
                                                </div>
                                                <div className="planet_report_bar_section">
                                                    <div className="planet_report_bar_section_h-bar">
                                                        <h5>Planet</h5>
                                                    </div>
                                                    <div className="planet_report_bar_section_paragraph">
                                                    <p>{gemSuggestion.planet}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                    Description
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact_change content">
                                                    <p className="bb">{gemSuggestion.description}</p>
                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                    Positive Impact
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>
                                                    {gemSuggestion.good_results.length > 0 ? (
                                                    gemSuggestion.good_results.map(good_results => (
                                                    <span> {good_results}, </span> 
                                                    ))
                                                    ) : ( '' )}
                                                    </p>
                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                    Disease Cure
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>
                                                    {gemSuggestion.diseases_cure.length > 0 ? (
                                                    gemSuggestion.diseases_cure.map(diseases_cure => (
                                                    <span> {diseases_cure}, </span> 
                                                    ))
                                                    ) : ( '' )}
                                                    </p>
                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                    Time To Wear
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemSuggestion.time_to_wear}</p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                    How To Wear
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemSuggestion.methods}</p>
                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                    Mantra
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemSuggestion.mantra}</p>
                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        </>
                                    }

                                    </div>
                                </div>
                        </div>
                        </section>

                        <div className="mantra_section_bar_line">
                        </div>
                        

                        <section className="present_section_last">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                    { !gemNumeroTable ? <Loader /> :   
                                          <>
                                        <div className="present_bar_section">
                                            <div className="mangal_dasha_bar new-dasha-bg-bar">
                                                <div className="planet_report_bar_section">
                                                    <div className="planet_report_bar_section_h-bar">
                                                        <h5>Number</h5>
                                                    </div>
                                                    <div className="planet_report_bar_section_paragraph">
                                                    <p>{gemNumeroTable.radical_number}</p>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                Characteristics
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact_change content">
                                                    <p>
                                                    {gemNumeroTable.characteristics.length > 0 ? (
                                                    gemNumeroTable.characteristics.map(characteristics => (
                                                    <span> {characteristics}, </span> 
                                                    ))
                                                    ) : ( '' )}
                                                    </p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                Favorite Color
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemNumeroTable.fav_color}</p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                Favorite Day
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemNumeroTable.fav_day}</p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                Favorite Mantra
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemNumeroTable.fav_mantra}</p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                Favorite God
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemNumeroTable.fav_god}</p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    
                                        <div className="gems_first_bar">
                                            <div className="col-lg-2 col-md-2 col-12">
                                                <div className="gems_section_bar">
                                                <div className="gems_positive_impact">
                                                <h5>
                                                Favorite Stone
                                                </h5>
                                                </div>
                                                </div> 
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-12">

                                                <div className="gems_section_bar">
                                                    <div className="gems_positive_impact">
                                                    <p>{gemNumeroTable.fav_stone}</p>

                                                    </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    
                                        </>
                                    }
                                    </div>
                                </div>
                        </div>
                        </section>

                          </div>
                          
                      </div>
                  </section>
</>

  );
}
export default KundliReport;