import React, {  useEffect } from "react";
// import { useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Pages/Loader";
import moment from "moment";
import { toast } from "react-toastify";

// import { getHomeContentDetail } from "../../redux/action/HomeApi";

function DetailPage() {

  
  const {contentDetail, message, error, loading } = useSelector(
    state => state.home
  );

  const dispatch = useDispatch();
  // const params = useParams();
  
  useEffect(() => {

  //   var postdata = {
  //     slug: (params.slug)?params.slug:'',
  //   };
     
  //  dispatch(getHomeContentDetail(postdata));


    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }

  }, [dispatch, error, message]);

  return (
    <>
      <link rel="stylesheet" href={"./assets/css/new-style.css"} />

      { loading ? <Loader /> : 
      <section className="mt-4 blog_details_design">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="blog_detail_head">
                <h2>{contentDetail.title}</h2>
              </div>
            </div>
            <div className="col-11 col-md-11 col-lg-11">
              <div className="blog_detail_profile d-lg-flex mt-4">
                <div className="author_images">
                  
                <img alt="blog" style={{ width: '84px',height: '84px'}}  src='./assets/images/user.png' />

                </div>
                <div className="blog_detail_pdetails">
                  <p>
                    <b className="">
                      Posted by : <span>{contentDetail.created_by}</span>
                    </b>
                  </p>
                  <p className="published_txt">
                    {" "}
                    <span className="border-rightmini">
                      Published on : {  moment(contentDetail.published_date).format("MMM, DD-YYYY")  }
                    </span>
                  </p>
                </div>
              </div>

              <div className="blog_detail_banner">
              {
                contentDetail.image?
                (
                <img width="100%" alt={contentDetail.title} src={contentDetail.image_path} />
                )
                :(
                <img alt="lorem_lipsum" className="w-100" src='./assets/images/lorem_lipsum.svg' />
                )
              }
               
              </div>

              <div className="blog_det_data mt-4 mb-5">
                <div />{contentDetail.description}
              </div>
            </div>
          </div>
        </div>
      </section>
       }
    </>
  );
}
export default DetailPage;
