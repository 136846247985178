import { server } from '../store';
import axios from 'axios';

export const GetCountry = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"WorldcitiesApi/getCountry",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'getCountrySuccess', payload: data });
        }else{
            dispatch({ type: 'getCountryFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};


export const GetCity = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'loadingRequest' });
  
      const { data } = await axios({
          method: "post",
          url: server+"WorldcitiesApi/getCity",
          data: postdata,
          headers: { "Content-Type": "multipart/form-data" },
        })
          if (data.responseCode==='200') {
              dispatch({ type: 'getCitySuccess', payload: data });
          }else{
              dispatch({ type: 'getCityFail', payload: data.responseMessage });
          }
    } catch (error) {
      console.log(error);
    }
  };


