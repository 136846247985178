import React , {  useState, useEffect } from "react";
import  UserSidbar from '../../components/Layout/UserSidbar';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser,updateUser } from "../../redux/action/Auth";
import { toast } from "react-toastify";
import {  useNavigate} from "react-router-dom";
import Loader from "../Pages/Loader";


  const Profile = ({ isAuthenticated = false, user }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const params = useParams();
 
    const [firstname, setFirstname] = useState(user.first_name)
    const [lastname, setLastname] = useState(user.last_name)
    const [email, setEmail] = useState(user.email)
    const [phone, setMobile] = useState(user.mobile_no)
    const [password, setPassword] = useState("")
    const [profilePic, setProfilePic] = useState("")

    const { message, error, loading } = useSelector(
      state => state.user
    );

    useEffect(() => {
      if(!isAuthenticated){
        navigate('/');
      }else{

      }
      // console.log('i fire once');

      if (error) {
        toast.error(error);
        dispatch({ type: 'clearError' });
      }
  
      if (message) {
        toast.success(message);
        dispatch({ type: 'clearMessage' });
        var postUserdata = {
        userId: localStorage.getItem('userId'),
        hash_token:localStorage.getItem('hashToken')
        };

        dispatch(loadUser(postUserdata));
      }

   
    }, [navigate,dispatch,isAuthenticated, error, message]);

    const handleFileUpload = (event) => {

      // const profilePic = event.target.files[0];
      setProfilePic(event.target.files[0]);

// console.log(profilePic);
    };
  
    const inputChangeHandler = async (setFunction, event) => {
      setFunction(event.target.value)
    }


   let userId = localStorage.getItem('userId');

   const onSubmitHandler = (e) => {
    e.preventDefault();
    if(formValidate() && userId){
      var postcommentdata = {
        first_name: firstname,
        last_name: lastname,
        user_type: 'user',
        email: email,
        mobile:phone,
        password:password,
        userId:userId,
        profile_pic:profilePic,
        hash_token: localStorage.getItem('hashToken'),
      }; 

      console.log(postcommentdata);

      dispatch(updateUser(postcommentdata));
    }
  }
  

  const formValidate=()=>{
    let result =true;

      if(firstname==='' || firstname===null){
        result =false;
        toast.error("Please enter first name.");    
      } 
      else if(lastname==='' || lastname===null){
        result =false;
        toast.error("Please enter last name.");    
      } 
      else if(email==='' || email===null){
        result =false;
        toast.error("Please enter your email.");
      }
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        result =false;
        toast.error("Invalid email address.");
      }
      else if(phone==='' || phone===null){
        result =false;
        toast.error("Please enter phone number.");
      }
      else if(phone.length!==10){
        result =false;
        toast.error("Please enter 10 phone number.");
      }
      // else  if(password==='' || password===null){
      //   result =false;
      //   toast.error("Please enter your password.");    
      // } 
    return result;
  }

     //console.log(email);
return (
<>
<link rel="stylesheet" href={"./assets/css/user.css"} />

<section className="wallet_page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <UserSidbar user={user} />
          </div>


          <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12 tabs_bar_user">
        <div className="profile-head">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Personal Details</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Edit Details</button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">History</button>
            </li> */}
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="row">
                <div className="col-md-4">
                  <label>Full Name:</label>
                </div>
                <div className="col-md-6">
                  <p>{user.first_name + ' '+user.last_name}</p>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-4">
                  <label>Email:</label>
                </div>
                <div className="col-md-6">
                  <p>{user.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Phone:</label>
                </div>
                <div className="col-md-6">
                  <p>{user.mobile_no}</p>
                </div>
              </div>
            </div>
            
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            { loading ? <Loader /> :

             <form onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name:</label>
                    <input type="text" className="form-control" name="" onChange={(e)=>inputChangeHandler(setFirstname, e)} value={firstname} placeholder="Enter first name "/>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name:</label>
                    <input type="text" className="form-control" name="" onChange={(e)=>inputChangeHandler(setLastname, e)} value={lastname} placeholder="Enter last name "/>
                  </div>
                </div>


                <div className="col-md-6 pt-4">
                  <div className="form-group">
                    <label>Email:</label>
                    <input type="email" className="form-control" name="" onChange={(e)=>inputChangeHandler(setEmail, e)} value={email} placeholder="Enter email "/>
                  </div>
                </div>  

                <div className="col-md-6 pt-4">
                  <div className="form-group">
                    <label>Mobile No.:</label>
                    <input type="number" className="form-control" readOnly name="" onChange={(e)=>inputChangeHandler(setMobile, e)} value={phone} placeholder="Enter mobile number "/>
                  </div>
                </div>

                <div className="col-md-6 pt-4">
                  <div className="form-group">
                    <label>Password:</label>
                    <input type="password" className="form-control" name="" onChange={(e)=>inputChangeHandler(setPassword, e)} placeholder="Enter password "/>
                  </div>
                </div>

                <div className="col-md-6 pt-4">
                  <div className="form-group">
                    <label>Profile Picture:</label>
                    <input type="file" onChange={handleFileUpload} className="form-control" />
                  </div>
                </div>  

              </div>
           

              <div className="row mt-2 pt-4">
                <div className="col-md-12">
                  <button className="btn btn-primary primary-button pull-right">Submit</button>
                </div>
              </div>
               
              </form>

                }

            </div>
            {/* <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <div className="row setting_row">
                <div className="col-md-8 account_setting_section">
                  <div className="user_account_bar">
                    <a href="#">
                      <img src="./assets/images/setting.png" alt="" />
                      <h2>Account Setting</h2>
                    </a>
                  </div>
                </div>
                <div className="col-md-8 account_setting">
                  <div className="user_account_bar">
                    <a href="#">
                      <img src="./assets/images/setting.png" alt="" />
                      <h2>Privacy Setting</h2>
                    </a>
                  </div>
                </div>
                <div className="col-md-8 account_setting_section">
                  <div className="user_account_bar">
                    <a href="#">
                      <img src="./assets/images/setting.png" alt="" />
                      <h2>Notification Setting</h2>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
            
        </div>
      </div>

        </div>
      </div>
    </section>
</>
);
}

export default Profile;