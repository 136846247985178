import React from 'react';

const ChartSvg = ({ chartsvgdata }) => {
  // console.log(chartsvgdata);
  return (
    <>  
    <div>
        <img src={`data:image/svg+xml;utf8,${encodeURIComponent(chartsvgdata.svg)}`} alt="chart"/>
    </div>
  </>
  );
};

export default ChartSvg;
