import React, {useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { getKundliBasic } from "../../redux/action/HoroscopeApi";
import Loader from "../Pages/Loader";
function Basic() {

const { kundliBasic, kundliFormdata } = useSelector(
        state => state.horoscope
      );


      const dispatch = useDispatch();


      useEffect(() => { /// het panchang
        // console.log(kundliFormdata.data);

        var postdata = {
            dob:kundliFormdata.data.dob, ///Date of Birth - DD/MM/YYYY
            tob:kundliFormdata.data.tob , //Birth time Time of Birth in Hours - HH:MM
            lat:kundliFormdata.data.lat, //Latitude
            lon:kundliFormdata.data.lon, //Longitude
            api_key:kundliFormdata.data.api_key, //Your API key
            lang:kundliFormdata.data.lang, //language
            tz  :kundliFormdata.data.tz //TimeZone
        };

        const loadbasic = async () => {
            dispatch(getKundliBasic(postdata));
        }
        loadbasic();

        // console.log(postdata);
        console.log('i fire once');

  }, [dispatch, kundliFormdata]);


// console.log(kundliBasic);
  return (
    <>
    {/* <link rel="stylesheet" href={"./assets/css/panchang.css"} /> */}

    { kundliBasic === '' ? <Loader /> :
      <>
    <div className="details-container">
                                    
        <div className="first_name">
            <p className="first_name_paragraph">Basic Details</p>
            </div>
        <div className="second_name">
            <p>{ kundliFormdata.data.username }</p>
            </div>
    </div>

    <section className="rashi_sec rashi_sec_tab_bar">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="detail_tithi d-flex row rashi_sec_tab_bar_section">
                    <div className="list_tithi col-6  col-md-3 col-lg-3">
                        <ul>
                            <li>Name</li>
                            <li>Day Of Birth</li>
                            <li>Time</li>
                            <li>Place</li>
                            <li>Hora Lord</li>
                            <li>Day Lord </li>
                            <li>Birth Dasha</li>
                        </ul>
                    </div>
                    <div className="list_tithi_section_bar  col-6 col-md-3 col-lg-3">
                        <ul>
                            <li>{kundliFormdata.data.username }</li>
                            <li>{kundliFormdata.data.dob }</li>
                            <li>{kundliFormdata.data.tob }</li>
                            <li>{kundliFormdata.data.birthplace }</li>
                            <li>{kundliBasic.panchang.hora_lord}</li>
                            <li>{kundliBasic.panchang.day_lord}</li>
                            <li>{kundliBasic.birth_dasa}</li>
                        </ul>
                    </div>

                    <div className="list_tithi sunrice_bar_section col col-6 col-md-3 col-lg-3">
                        <ul>
                            <li>Sunrise <img src={"./assets/images/sunrice.svg"}  alt="" /></li>
                            <li>Sunset <img src={"./assets/images/sunset.svg"} alt=""/></li>
                            <li>Tithi</li>
                            <li>Yoga</li>
                            <li>Nakshatra</li>
                            <li>Ayanamsa</li>
                            <li>Current Dasha</li>
                        </ul>
                    </div>
                    <div className=" list_tithi_section_bar col-6  col-md-3 col-lg-3 p-0">

                        <ul>
                            <li>{kundliBasic.panchang.sunrise_at_birth}</li>
                            <li>{kundliBasic.panchang.sunset_at_birth}</li>
                            <li>{kundliBasic.panchang.tithi}</li>
                            <li>{kundliBasic.panchang.yoga}</li>
                            <li>{kundliBasic.nakshatra}</li>
                            <li>{kundliBasic.panchang.ayanamsa}</li>
                            <li>{kundliBasic.current_dasa_time}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
         }
    </>
  );
}
export default Basic;