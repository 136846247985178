import React from "react";

import OwlCarousel from 'react-owl-carousel';  

import 'owl.carousel/dist/assets/owl.carousel.css';  

import 'owl.carousel/dist/assets/owl.theme.default.css';  


function owlDemo() {
 
  
    return (
  <>
     <OwlCarousel className='owl-theme' loop >

        <div className=" item">
          <div className="as_customers_box text-center">
            <span className="customers_profiles"><img src="./assets/images/customers1.svg" alt="" /></span>
            <h3>Lorem Ipsum</h3>
            <p className="rating-icons mbzero">
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="not-rated"><i className="fa fa-star" aria-hidden="true" /></span>
            </p>
            <p className="mbzero">“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”</p>
          </div>
        </div>
        <div className="item">
          <div className="as_customers_box text-center">
            <span className="customers_profiles"><img src="./assets/images/customers2.svg" alt="" /></span>
            <h3>Lorem Ipsum</h3>
            <p className="rating-icons mbzero">
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="not-rated"><i className="fa fa-star" aria-hidden="true" /></span>
            </p>
            <p className="mbzero">“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”</p>
          </div>
        </div>
        <div className="item">
          <div className="as_customers_box text-center">
            <span className="customers_profiles"><img src="./assets/images/customers3.svg" alt="" /></span>
            <h3>Lorem Ipsum</h3>
            <p className="rating-icons mbzero">
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
              <span className="not-rated"><i className="fa fa-star" aria-hidden="true" /></span>
            </p>
            <p className="mbzero">“Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.”</p>
          </div>
        </div>
        <div className="item images_set">
          <div className="as_customers_box_1">
            <span><img src="./assets/images/customers4.svg" alt=""/></span>
            <div className="card-content">
              <div className="top-bar">
                <div className="user">
                  <div className="user-img">
                    <img src="https://testapplication.in/mibhagya-web/new_assets/images/video1.png" alt="" />
                  </div>
                  <div className="user-label">
                    <p className="user-name">Lorem Ipsum</p>
                    <p className="user-rating">
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="not-rated"><i className="fa fa-star" aria-hidden="true" /></span>
                    </p>
                  </div>
                </div>
                <div className="full-btn"><img src="https://testapplication.in/mibhagya-web/new_assets/images/full-btn.svg" alt="" />
                </div>
              </div>
              <div className="play-btn">
                <img src="https://testapplication.in/mibhagya-web/new_assets/images/play-btn.svg" alt="" />
              </div>
              <div className="video-controls">
                <img src="https://testapplication.in/mibhagya-web/new_assets/images/controls.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="item images_set">
          <div className="as_customers_box_1">
            <span><img src="./assets/images/customers5.svg" alt=""/></span>
            <div className="card-content">
              <div className="top-bar">
                <div className="user">
                  <div className="user-img">
                    <img src="https://testapplication.in/mibhagya-web/new_assets/images/video.png" alt="" />
                  </div>
                  <div className="user-label">
                    <p className="user-name">Lorem Ipsum</p>
                    <p className="user-rating">
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="not-rated"><i className="fa fa-star" aria-hidden="true" /></span>
                    </p>
                  </div>
                </div>
                <div className="full-btn"><img src="https://testapplication.in/mibhagya-web/new_assets/images/full-btn.svg" alt="" />
                </div>
              </div>
              <div className="play-btn">
                <img src="https://testapplication.in/mibhagya-web/new_assets/images/play-btn.svg" alt="" />
              </div>
              <div className="video-controls">
                <img src="https://testapplication.in/mibhagya-web/new_assets/images/controls.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="item images_set">
          <div className="as_customers_box_1">
            <span><img src="./assets/images/customers6.svg" alt="" /></span>
            <div className="card-content">
              <div className="top-bar">
                <div className="user">
                  <div className="user-img">
                    <img src="https://testapplication.in/mibhagya-web/new_assets/images/video2.png" alt="" />
                  </div>
                  <div className="user-label">
                    <p className="user-name">Lorem Ipsum</p>
                    <p className="user-rating">
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="rated"><i className="fa fa-star" aria-hidden="true" /></span>
                      <span className="not-rated"><i className="fa fa-star" aria-hidden="true" /></span>
                    </p>
                  </div>
                </div>
                <div className="full-btn"><img src="https://testapplication.in/mibhagya-web/new_assets/images/full-btn.svg" alt="" />
                </div>
              </div>
              <div className="play-btn">
                <img src="https://testapplication.in/mibhagya-web/new_assets/images/play-btn.svg" alt="" />
              </div>
              <div className="video-controls">
                <img src="https://testapplication.in/mibhagya-web/new_assets/images/controls.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      
</OwlCarousel>;
  
  </>
  
    );
  }
  export default owlDemo;