import { createReducer } from "@reduxjs/toolkit";

const initialState ={
    isLoginModel:false,
    isOtpModel:false,
    isRegisModel:false,
}

export const modelReducer = createReducer(initialState,{

    openRegisModel : (state,action) =>{
        state.isLoginModel=false;
        state.isOtpModel=false;

        state.isRegisModel=true;
    }, 

    cloaseRegisModel : (state,action) =>{
        state.isRegisModel=false;
    }, 

  
    openLoginModal : (state,action) =>{
        state.isOtpModel=false;
        state.isRegisModel=false;

        state.isLoginModel=true;
    }, 

    closeLoginModal : (state,action) =>{
        state.isLoginModel=false;
    }, 

  
    openOtpModal : (state,action) =>{
        state.isLoginModel=false;
        state.isRegisModel=false;

        state.isOtpModel=true;
    }, 

    closeOtpModal : (state,action) =>{
        state.isOtpModel=false;
    }, 

  

});