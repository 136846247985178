import { server } from '../store';
import axios from 'axios';

export const otpVerification = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    // const { data } = await axios.post(
    //   `${server}/AuthApi/otpVerification`,
    //   { phone, email },
    //   {
    //  headers: { "Content-Type": "multipart/form-data" },


    //   }
    // );
    const { data } = await axios({
        method: "post",
        url: server+"AuthApi/otpVerification",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'otpSuccess', payload: data });
            dispatch({ type:"closeOtpModal",  });
        }else{
            dispatch({ type: 'otpFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }
};

export const logoutUser = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"AuthApi/logout",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'logoutSuccess', payload: data });
        }else{
            dispatch({ type: 'otpFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }
};



export const loadUser = (postdata) => async dispatch => {
    try {
    //  dispatch({ type: 'loadUserRequest' });
  
      const { data } = await axios({
        method: "post",
        url: server+"AuthApi/getUserDetail",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
        
      })
     
        if (data.responseCode==='200') {
            dispatch({ type: 'loadUserSuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'loadUserFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
    // dispatch({ type: 'otpFail', payload: error.response.data.responseMessage });
  }

  };



export const updateUser = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"AuthApi/updateUserInfo",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
   
        if (data.responseCode==='200') {
            dispatch({ type: 'getUserUpdateSuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'getUserUpdateFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};


export const astoLogin = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"AuthApi/astroweblogin",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
   
        if (data.responseCode==='200') {
            dispatch({ type: 'astoLoginSuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'astoLoginFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};



export const astoCategory = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/getAstroCat_test",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
   
        if (data.responseCode==='200') {
            dispatch({ type: 'astroCategorySuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'astroCategoryFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};


export const astoSubCategory = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/getSubcat_test",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
   
        if (data.responseCode==='200') {
            dispatch({ type: 'astroSubCategorySuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'astroSubCategoryFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};



export const astoRegistration = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"AuthApi/counsellorAdd_test",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
   
        if (data.responseCode==='200') {
            dispatch({ type: 'astoRegisSuccess', payload: data.responseMessage });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'astoRegisFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};



export const astoOnline = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'otpVerificationRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"AuthApi/online",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
   
        if (data.responseCode==='200') {
          dispatch({ type: 'astoOnlineSuccess', payload: data });

            // dispatch({ type: 'astoOnlineSuccess', payload: data.responseMessage });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'astoOnlineFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

