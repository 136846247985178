import React , {  useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { astoCategory, astoSubCategory,astoRegistration } from "../../redux/action/Auth";
import { toast } from "react-toastify";
import { NavLink, useNavigate} from "react-router-dom";
import Loader from "../Pages/Loader";
import Select from "react-select";


  
const Astroregistration = ({ isAuthenticated }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { astroCat, specialisation, subCat, message, error, loading } = useSelector(
            state => state.auth
            );
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [email, setEmail] = useState("");
    const [mobile_no, setMobile_no] = useState("");
    const [alt_mobile_no, setAlt_mobile_no] = useState("");
    const [mastery, setSelectedCategory] = useState(""); //Category
    const [expertise, setExpertise] = useState([]); //Sub Category expertise[] 
    const [specialisations, setSpecialisation] = useState(null); //Specialisation[]
    // const [selectedOption, setSelectedOption] = useState(null);

    const [pro_content, setPro_content] = useState(""); //Profile Content
    const [award_forum, setAward_forum] = useState(""); //Awards & Forums
    const [dob, setDob] = useState(""); //Date Of Birth
    const [gender, setGender] = useState(""); //Gender
    const [experiance, setExperiance] = useState(""); //Experience
    const [working, setWorking] = useState(""); //other platform
    const [term, setTerm] = useState(""); 
     
    const inputChangeHandler = async (setFunction, event) => {
        setFunction(event.target.value)
    }
  

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if(formValidate()){
            var postdata = {
                first_name:first_name,
                last_name:last_name,
                email:email,
                mobile_no:mobile_no,
                alt_mobile_no:alt_mobile_no,
                mastery:mastery, //Category
                expertise:[expertise], //Sub Category expertise[] 
                specialisation :[specialisations],//Specialisation[]
                pro_content :pro_content,//Profile Content
                award_forum :award_forum,//Awards & Forums
                dob :dob,//Date Of Birth
                gender :gender,//Gender
                experiance :experiance,//Experience
                working :working,//other platform
                term :term,
            }; 
        //console.log(postdata);
        dispatch(astoRegistration(postdata));
        }
    }
  
  const formValidate=()=>{
    let result =true;
    if(first_name==='' || first_name===null){
        result =false;
        toast.error("Please enter first name");
      }
      else if(last_name==='' || last_name===null){
        result =false;
        toast.error("Please enter last name");
      }
      else if(email==='' || email===null){
        result =false;
        toast.error("Please enter valid email");
      }
      if(mobile_no==='' || mobile_no===null){
        result =false;
        toast.error("Please enter mobile number.");
      }
      else if(mobile_no.length!==10){
        result =false;
        toast.error("Please enter 10 Digit mobile number.");
      }
      else if(alt_mobile_no.length >0){
        if(alt_mobile_no.length!==10){
            result =false;
            toast.error("Please enter 10 alt mobile number.");
          }  
      }
      else if(mastery==='' || mastery===null){
        result =false;
        toast.error("Please select category.");
      }
      else  if(expertise==='' || expertise===null){
        result =false;
        toast.error("Please select sub category.");
      }
      else if(specialisations==='' || specialisations===null){
        result =false;
        toast.error("Please select specialisation.");
      }
      else if(pro_content==='' || pro_content===null){
        result =false;
        toast.error("Please enter profile content.");
      }
      else if(dob==='' || dob===null){
        result =false;
        toast.error("Please select date Of birth.");
      }
      else  if(gender==='' || gender===null){
        result =false;
        toast.error("Please select gender.");
      }
      else  if(experiance==='' || experiance===null){
        result =false;
        toast.error("Please enter experiance.");
      }
      else if(working==='' || working===null){
        result =false;
        toast.error("Please select other platform.");
      }
      else if(term==='' || term===null){
        result =false;
        toast.error("Please select terms and conditions.");
      }
// console.log(specialisations);
    
       return result;
  }
     
      useEffect(() => {
        if(mastery != null){ 
            setExpertise(null); //Sub Category expertise[] 

                var postUserdata = {
                    cat_id: mastery,
                    };     
                dispatch(astoSubCategory(postUserdata));
        }
      }, [dispatch,mastery]);


      useEffect(() => {
        if(isAuthenticated){
            navigate('/');
        }

       dispatch(astoCategory());
    
    
        if (error) {
          toast.error(error);
          dispatch({ type: 'clearError' });
        }
    
        if (message) {
          //toast.success(message);
          dispatch({ type: 'clearMessage' });
                setFirst_name("");
                setLast_name("");
                setEmail("");
                setMobile_no("");
                setAlt_mobile_no("");
                setSelectedCategory(""); //Category
                // setExpertise([]); //Sub Category expertise[] 
                // setSpecialisation([]); //Specialisation[]
                setExpertise(null); //Sub Category expertise[] 
                setSpecialisation(null); //Specialisation[]
                setPro_content(""); //Profile Content
                setAward_forum(""); //Awards & Forums
                //Date Of Birth
                setGender(""); //Gender
                setExperiance(""); //Experience
                setWorking(""); //other platform
                setTerm("");
        }
    
      }, [navigate,dispatch,isAuthenticated, error, message]);
//   console.log(expertise);

  return (
    <>  <link rel="stylesheet" href={"./assets/css/astro-registration.css"} /> 
 
<div className="Registration_section">
    <div className="container">
          <div className="text">
            <h2>Astrologer Registration</h2>


            {/* <section className="flash_alert content" style="display: none;"> </section> */}
                <form  onSubmit={onSubmitHandler}  autoComplete={"off"}>
                <fieldset>
                    <ul>
                        <li className="experience">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First Name <span className="error-red">* </span></label>
                                        <input type="text" required onChange={(e)=>inputChangeHandler(setFirst_name, e)} className="form-control" name="first_name" id="first_name" minength="3" maxLength="50" placeholder="Enter first name..." />
                                        <span className="first_name_error error-red"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Last Name <span className="error-red">* </span></label>
                                        <input type="text" required onChange={(e)=>inputChangeHandler(setLast_name, e)} className="form-control" name="last_name" id="last_name" minlength="3" maxlength="50" placeholder="Enter last name..." />
                                        <span className="last_name_error error-red"></span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="form-group">
                                <label>Email <span className="error-red">* </span></label>
                                <input type="email"  required onChange={(e)=>inputChangeHandler(setEmail, e)} className="form-control" name="email" id="email" maxlength="70" placeholder="Enter email..." />
                                <span className="email_error error-red"></span>
                            </div>
                        </li>
                        <li className="experience">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Mobile No. <span className="error-red">* </span></label>
                                        <input type="number" required onChange={(e)=>inputChangeHandler(setMobile_no, e)} className="form-control" pattern="[6789][0-9]{9}" name="mobile_no" id="mobile_no" minlength="10" maxlength="10" placeholder="Enter Contact No..."/>
                                        <span className="mobile_no_error error-red"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Alternate Mobile No.</label>
                                        <input type="number" onChange={(e)=>inputChangeHandler(setAlt_mobile_no, e)} className="form-control" pattern="[6789][0-9]{9}" name="alt_mobile_no" id="alt_mobile_no" minlength="10" maxlength="10" placeholder="Enter alternate contact no..." />
                                        <span className="alt_mobile_no_error error-red"></span>
                                    </div>
                                </div>
                            </div>
                        </li>                            
                        <li className="row gender">
                            <label className="col-md-2">Gender</label>
                            <div className="radio col-md-8">
                                <input id="male" onChange={(e)=>inputChangeHandler(setGender, e)} className="checkbox" name="gender" type="radio" value="male" />
                                <label for="male"> <span className="check"></span> Male</label>
                                <input id="female" onChange={(e)=>inputChangeHandler(setGender, e)} className="checkbox" name="gender" type="radio" value="female" />
                                <label for="female"><span className="check"></span> Female.</label>
                            </div>
                        </li>
                        <li className="experience">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Experience(in year) <span className="error-red">* </span></label>
                                        <input type="number" required onChange={(e)=>inputChangeHandler(setExperiance, e)} className="form-control" min="0" max="99" name="experiance" id="experiance" maxlength="10" placeholder="Enter total experience..." />
                                        <span className="experiance_error error-red"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Date Of Birth <span className="error-red">* </span></label>
                                    <input type="date" required onChange={(e)=>inputChangeHandler(setDob, e)} name="dob" className="date" max="2023-12-27" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="form-group">
                                <label>Awards &amp; Forums</label>
                                <textarea className="form-control" onChange={(e)=>inputChangeHandler(setAward_forum, e)} rows="2" name="award_forum" minlength="10" maxlength="200" id="award_forum" placeholder="Enter Awards &amp; Forums details..."></textarea>
                                <span className="award_forum_error error-red"></span>
                            </div>
                        </li>
                        <li className="space experience">
                            <div className="form-group">
                                <label>Specialisation <span className="error-red">* </span></label>
                                {/* <select required onChange={(e)=>inputChangeHandler(setSpecialisation, e)} name="specialisation[]" id="Select" className="form-select" multiple="multiple">  
                                    <option value="">Select Specialisation</option>
                                    {specialisation.map((spec) => (
                                  <option value="">Select Specialisation</option>

                                    // <option key={spec.id} value={spec.id}>
                                    //     {spec.name}
                                    // </option>
                                     ))}                                                                
                                </select> */}
                                            <Select
                                            className="multiselct"
                                            defaultValue={specialisations}
                                            onChange={setSpecialisation}
                                            options={specialisation}
                                            isMulti={true}
                                            placeholder="Select a Specialisation"
                                            isSearchable={true}   
                                            />

                                <span className="specialisation_error error-red"></span>
                            </div>
                        </li>
                        <li className="space experience">
                                    <div className="form-group">
                                        <label>Category <span className="error-red">* </span></label>
                                        <select required className="form-control" onChange={(e) => setSelectedCategory(e.target.value)}>  
                                        <option value=""> Select Category </option>
                                        {astroCat.map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                            {cat.category}
                                        </option>
                                        ))}                                                                
                                                                                                            
                                        </select>
                                        <span className="mastery_error error-red"></span>
                                    </div>
                        </li>
                        <li className="space experience">
                                        <div className="form-group">
                                            <label>Sub Category <span className="error-red">* </span></label>
                                                    {/* <select required onChange={(e)=>inputChangeHandler(setExpertise, e)} name="expertise[]" className="form-control expertise" id="expertise_id" multiple="multiple">
                                                    <option value=""> Select Sub Category </option>
                                                    {subCat.map((subcats) => (
                                                        <option key={subcats.id} value={subcats.id}>
                                                            {subcats.category}
                                                        </option>
                                                        ))}   

                                                        </select> */}

                                                            <Select
                                                            className="multiselct"
                                                            defaultValue={expertise}
                                                            onChange={setExpertise}
                                                            options={subCat}
                                                            isMulti={true}
                                                            placeholder="Select a Sub Category"
                                                            isSearchable={true}   
                                                            />
                                                  <span className="expertise_error error-red"></span>
                                        </div>
                        </li>
                        <li>
                            <label>Are You working on other platform ??</label>
                            <div className="radio">
                                <input id="yes" onChange={(e)=>inputChangeHandler(setWorking, e)} className="checkbox" name="working" type="radio" value="1" />
                                <label for="yes"> <span className="check"></span> Yes</label>
                                <input id="no" onChange={(e)=>inputChangeHandler(setWorking, e)} className="checkbox" name="working" type="radio" value="0" />
                                <label for="no"><span className="check"></span> No</label>
                            </div>
                        </li>
                        <li>
                            <div className="form-group">
                                <label>Profile Content <span className="error-red">* </span></label>
                                <textarea required onChange={(e)=>inputChangeHandler(setPro_content, e)} className="form-control" rows="3" name="pro_content" minlength="200" maxlength="500" id="pro_content" placeholder="Enter Profile Content...(Max 500 words description which should include qualification, location, mastery, expertise, vision on astrology, rewards &amp; recognition.)"></textarea>
                                <span className="pro_content_error error-red"></span>
                            </div>
                        </li>
                        <li className="checked_terms">
                            <div className="form-check">
                                <input required onChange={(e)=>inputChangeHandler(setTerm, e)} className="form-check-input" type="checkbox" value="1" id="flexCheckChecked"  />
                                <label for="terms" style={{ float: 'left', display: 'inline-block', width: 'auto', margin: '0 0 0 10px' }}>I accept the <NavLink to="/terms-and-conditions">terms and conditions</NavLink>.</label>
                              </div> 
                            
                        </li>
                         { loading ? <Loader /> :

                        <li>
                            <button type="submit" name="submit" value="add" id="add" className="submit_bar">Submit</button>
                        </li>
                        }
                    </ul>
                </fieldset>
                </form>        
            
          </div>
    </div>
</div>
</>
  
    );
};

export default Astroregistration;
