import React, {  useState, useEffect } from "react";
import {NavLink, useParams} from "react-router-dom";
import Loader from "../Pages/Loader";
import { toast } from "react-toastify";
// import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogDetails ,addComment, addUserLike} from "../../redux/action/BlogApi";
import moment from "moment";
import {FacebookShareButton, FacebookIcon, LinkedinShareButton,LinkedinIcon ,TwitterShareButton, TwitterIcon,WhatsappShareButton,WhatsappIcon} from "react-share";
import Modal from 'react-modal';

// import BlogComment from "./BlogComment";

Modal.setAppElement('#root');
const customStyles = {
  content: {
    display: 'block',
  },
};
const customStyle = {
  content: {
    display: 'none',
  },
};

function Details() {
  const params = useParams();
 
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [comment, setComment] = useState("")
  const [comment_id, setCommentId] = useState("")


  const {blogIpLike, blogtotalLike, isCommentModel,blogDetais, blogComments, recentBlogs, blogCategory, message, error, loading } = useSelector(
    state => state.blog
  );

  

   
 

  function addLike(e) {
    // setCommentId(e);
    // dispatch({
    //   type:"openCommnetModal",
    // });

    if(blogDetais ){
      var postLikedata = {
        change_status: blogIpLike===0?'like':'unlike',
        blog_id: blogDetais.id,
      };
      console.log(postLikedata);
     dispatch(addUserLike(postLikedata));
      //console.log(name);
    }



  }


  
  function showCommnetModal(e) {
    setCommentId(e);
    dispatch({
      type:"openCommnetModal",
    });

  }

  function closeCommentModel() {
    // setLoginModalIsOpen(true);
    dispatch({
      type:"closeCommentModel",
    });

  }

  

  // const location = useLocation()
  const current_url = window.location.href;

// console.log(location.pathname);
  const dispatch = useDispatch();
  
 
  useEffect(() => {
        
      
        var postdata = {
          longitude: 'test',
          latitude: 'testsmdlkasm',
          slug:params.slug,
        };
        console.log(postdata);
       dispatch(getBlogDetails(postdata));
     
  }, [dispatch,params.slug]);
  
  
  useEffect(() => {

    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }

  }, [dispatch, error, message]);

  // const regex = /(<([^>]+)>)/gi;
   const description = blogDetais.description;

  const inputChangeHandler = async (setFunction, event) => {
    setFunction(event.target.value)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if(formValidate() && blogDetais){
      var postcommentdata = {
        name: name,
        email: email,
        mobile:phone,
        comment:comment,
        blog_id:blogDetais.id,
      };
      //console.log(postcommentdata);
      dispatch(addComment(postcommentdata));
      //console.log(name);
    }
  }
  
 
  const onSubmitReplyHandler = (e) => {
    e.preventDefault();
    if(formValidate() && blogDetais && comment_id){
      var postcommentdata = {
        name: name,
        email: email,
        mobile:phone,
        comment:comment,
        blog_id:blogDetais.id,
        comment_id:comment_id,
      };
      //console.log(postcommentdata);
      dispatch(addComment(postcommentdata));
      //console.log(name);
    }
  }
 

  const formValidate=()=>{
    let result =true;
    if(name==='' || name===null){
      result =false;
      toast.error("Please enter your name.");    
    } 

    else if(email==='' || email===null){
      result =false;
      toast.error("Please enter your email.");
     // console.log('mobole number not valid');

    
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      result =false;
      toast.error("Invalid email address.");
      }

      else if(phone==='' || phone===null){
        result =false;
        toast.error("Please enter phone number.");
       // console.log('mobole number not valid');
  
      }
      else if(phone.length!==10){
        result =false;
  
        toast.error("Please enter 10 phone number.");
        }

        else  if(comment==='' || comment===null){
          result =false;
          toast.error("Please enter your comment.");    
        } 
    return result;
  }




//  console.log(blogDetais.description);
  return (
    
<>
 <link rel="stylesheet" href={"./assets/css/new-style.css"} />

<section className="mt-4 blog_details_design">
  <div className="container-fluid">
  { loading ? <Loader /> : 

    <div className="row">
       { blogDetais ? 
       <>
      <div className="col-md-12">
        <div className="blog_detail_head">
        <h2>{blogDetais.title}</h2>
        </div>
      </div>
      <div className="col-12 col-md-9 col-lg-9 blog_det_data_main">
        
      <div className="blog_detail_profile d-lg-flex mt-4">
        <div className="author_images">
          {
            blogDetais.profile_pic?
            (
            <img alt="blog" style={{ width: '84px',height: '84px'}}  src={blogDetais.profile_pic_path} />
            )
            :(
            <img alt="blog" style={{ width: '84px',height: '84px'}}  src='./assets/images/welcome-image.png' />
            )
          }
        </div>
        <div className="blog_detail_pdetails">
          <p><b  className="">Posted by : <span >{blogDetais.posted_by}</span></b></p>
          <p className="published_txt">  <span className="border-rightmini">Published on :  {  moment(blogDetais.created_at).format("MMM, DD-YYYY")  }</span> 
          {/* <span className="border-rightmini">210 People already read</span> 
          <span className="ps-1">Time: 20 min ago</span>  */}
          </p>
        </div>
      </div>

      <div className="blog_detail_banner">
      {
          blogDetais.blog_pic?
          (
          <img className="w-100" alt="blog" src={blogDetais.blog_pic_path} />
          )
          :(
          <img className="w-100" alt="blog" src='./assets/images/welcome-image.png' />
          )
        }
      </div>
      
        <div className="blog_det_data">
           <div dangerouslySetInnerHTML={{ __html: description }} />

        </div>


        <div className="social_blog">
      
          <div className="social_list d-flex">
            <h5>Share:</h5>
        
            <div className="share_icon">
                <FacebookShareButton 
                url={current_url}
                quote={"Mibhagya - Talk to Best Astrologer in India are Available Online Astrology Live Consultation."}
                hashtag="#camperstribe"
                >
                <FacebookIcon size={36} />
                </FacebookShareButton>

                <TwitterShareButton 
                url={current_url}
                quote={"Mibhagya - Talk to Best Astrologer in India are Available Online Astrology Live Consultation."}
                hashtag="#camperstribe"
                >
                <TwitterIcon size={36} />
                </TwitterShareButton>

                <LinkedinShareButton 
                url={current_url}
                quote={"Mibhagya - Talk to Best Astrologer in India are Available Online Astrology Live Consultation."}
                hashtag="#mibhagya"
                >
                <LinkedinIcon size={36} />
                </LinkedinShareButton>

                <WhatsappShareButton 
                url={current_url}
                quote={"Mibhagya - Talk to Best Astrologer in India are Available Online Astrology Live Consultation."}
                hashtag="#mibhagya"
                >
                <WhatsappIcon size={36} />
                </WhatsappShareButton>

          </div>
          </div>
            <div className="like_icon"> 
              <span  onClick={(e)=>addLike(blogDetais.id)}  className="" >
              <i className={ blogIpLike===1?"fa fa-thumbs-up":"fa fa-thumbs-o-up" }></i> </span>({blogtotalLike})
              {/* <i className="fa fa-thumbs-up"></i> */}
            </div>
        </div>
        
       <div className="comment_section_blog pt-3">
          <div className="comment_heading">
          <h2>Comments <span>Suggestions &amp; feedback</span></h2>
          </div>
      
          <div className="comments d-flex">
         <ul>
        
           
           {blogComments.length > 0 ? (
        blogComments.map((comments,index) => (
    
        <>
            <li key={index}  className="row">
           <div className="left_side col-md-2">
            <img className="rounded-circle shadow-1-strong me-3" src="./assets/images/comment-2.png" alt="" width="60px" height="60px"/>
            </div>
            <div className="right_side col-md-10">
              <h4> {comments.name}<span> {  moment(comments.date).format("MMM, DD-YYYY")  }</span></h4>
              <p>{comments.comment}</p>
              <span onClick={(e)=>showCommnetModal(comments.id)} className="share-icn comment_reply" ><i className="fa fa-share" aria-hidden="true"></i></span>
             
              {comments.reply.length > 0 ? (

              <div className="more_comments d-flex">

                <ul>
               { comments.reply.map((commentreply,i) => (
                <>
                  <li key={i}  className="row">
                  <div className="left_side col-md-2">
                  <img className="rounded-circle shadow-1-strong me-3" src="./assets/images/comment-1.png" alt="" width="60px" height="60px"/>
                  </div>
                  <div className="right_side col-md-10">
                  <h4> {commentreply.name}<span> {  moment(commentreply.date).format("MMM, DD-YYYY")  }</span></h4>
                  <p>{commentreply.comment}</p>
                  </div></li>
                </>
                ))
               }
                </ul>
                </div>
                ) : ('')}
              </div>
                 
           </li>
        </>
          ))
         ) : ('')}

         </ul>
          </div>

        </div>

      </div>
      </>
        : <div className="col-12 col-md-9 col-lg-9 blog_det_data_main">
        <div className="blog_detail_head">
          <h2>Not Found</h2>
        </div>
      </div>
    }

      <div className="col-12 col-md-3 col-lg-3">
        <div className="as_search_widget fontuser">
                  <form className="nosubmit" action="/blog">
                     <input className="nosubmit" type="search" name="search" placeholder="Search..."/>
                     <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                  </form>
               </div>


               <div className="blog_related_ar">
                <h4>Category</h4>
                <div className="releted_b_data_mini">
                  <ul> 
                      {blogCategory.length > 0 ? (
                        blogCategory.map((d,index) => (
                        <>
                          <li kay={index} >
                            <NavLink to={'/blog/'+d.slug.toLowerCase()}><span><i className="bi bi-arrow-right-short"></i></span>{d.name}</NavLink>
                          </li>
                        </>
                        ))
                        ) : ('')
                      }

                    </ul>
                </div>
               </div>

               <div className="blog_related_ar">
          <h4>RECENT NEWS</h4>


              {recentBlogs.length > 0 ? (
                recentBlogs.slice(0, 3).map((recentblogs,index) => (
                <>
                        {/* <NavLink to={"/blog/view/"+recentblogs.slug} > */}
                <div  kay={index} className="releted_b_data_mini">
                <div className="releted_b_data_img mb-2">
                  {
                    recentblogs.blog_pic?
                    (
                    <img alt="blog" src={recentblogs.blog_pic_path} />
                    )
                    :(
                    <img alt="blog" src='./assets/images/welcome-image.png' />
                    )
                  }
                </div>
                <div className="releted_b_data">
                  <b><NavLink to={"/blog/view/"+recentblogs.slug} >{recentblogs.title}</NavLink> </b>
                  <p>The colours present around you greatly affect your life and the state of your mind at large....  </p>
                </div>
              </div>
              {/* </NavLink>  */}

              </>
              ))
            ) : ('')}


        </div>
        
        
      </div>
    </div>
  }
  </div>
</section>
<section className="form-section"> 
  <div className="container">
  <div className="row ">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="leave_comment">
          <h2>Leave your comment <span>Submit your comments now</span></h2>
          <form onSubmit={onSubmitHandler}>
            <div className="form-group">
              <input type="text" name=""  onChange={(e)=>inputChangeHandler(setName, e)} placeholder="Name"/>
            </div>
             <div className="form-group">
              <input type="text" name=""  onChange={(e)=>inputChangeHandler(setEmail, e)} placeholder="Email Address"/>
            </div>
             <div className="form-group">
              <input type="number" name=""  onChange={(e)=>inputChangeHandler(setPhone, e)} placeholder="Phone Number"/>
            </div>
             <div className="form-group">
             <textarea  name="comment"  onChange={(e)=>inputChangeHandler(setComment, e)} placeholder="Comment"></textarea>
            </div>
            <div className="">
              <button type="submit">Submit Now</button>
            </div>
          </form>
        </div>

      </div>

    </div>
  </div>
</section>

{/* commnet Modal */}

<Modal
  isOpen={isCommentModel}
  className="modal fade comment login show"
  id="login"
  size="sm"
  tabIndex={-1}
  aria-labelledby="otpLabel"
  aria-hidden="true"
  style={isCommentModel?customStyles:customStyle}
  aria={{
    labelledby: "heading",
    describedby: "full_description"
  }}>

    <div className="modal-dialog">
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={closeCommentModel}
        >
        <img alt="test.jpg" src="./assets/images/close_btn.svg"  />
        </button>
        <div className="container">
     <div className="row ">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="leave_comment">
          <h2>Leave your comment <span>Submit your comments now</span></h2>
          <form onSubmit={onSubmitReplyHandler}>
            <div className="form-group">
              <input type="text" name=""  onChange={(e)=>inputChangeHandler(setName, e)} placeholder="Name"/>
            </div>
             <div className="form-group">
              <input type="text" name=""  onChange={(e)=>inputChangeHandler(setEmail, e)} placeholder="Email Address"/>
            </div>
             <div className="form-group">
              <input type="number" name=""   onChange={(e)=>inputChangeHandler(setPhone, e)} placeholder="Phone Number"/>
            </div>
             <div className="form-group">
             <textarea  name="comment"  onChange={(e)=>inputChangeHandler(setComment, e)} placeholder="Comment"></textarea>
            </div>
            <div className="">
              <button type="submit">Submit Now</button>
            </div>
          </form>
        </div>

      </div>

    </div>
  </div>
      </div>
    </div>
  </Modal>
  {/* commnet model */}
 
</>


  );
}
export default Details;