import React , {  useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { astoLogin } from "../../redux/action/Auth";
import { toast } from "react-toastify";
import { NavLink, useNavigate} from "react-router-dom";
import Loader from "../Pages/Loader";

const Astrologin = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, error, loading } = useSelector(
    state => state.user
  );

  const [remember, setRemember] = useState("")
  const [phone, setMobile] = useState("")
  const [password, setPassword] = useState("")


  useEffect(() => {
    if(isAuthenticated){
      navigate('/');
    }else{

    }
    console.log('i fire once');

    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }

 
  }, [navigate,dispatch,isAuthenticated, error, message]);

  const inputChangeHandler = async (setFunction, event) => {
    setFunction(event.target.value)
  }
 
  const onSubmitHandler = (e) => {
    e.preventDefault();
    if(formValidate()){
      var postdata = {
        user_type: 'counsellor',
        deviceType:'web',
        mobile:phone,
        password:password,
        remember:remember,
        device_id:'dsnkdksnad',
        fcm_key:'12332',
      }; 
      dispatch(astoLogin(postdata));
    }
  }
  

  const formValidate=()=>{
    let result =true;

       if(phone==='' || phone===null){
        result =false;
        toast.error("Please enter phone number.");
      }
      else if(phone.length!==10){
        result =false;
        toast.error("Please enter 10 phone number.");
      }
      else  if(password==='' || password===null){
        result =false;
        toast.error("Please enter your password.");    
      } 
     
    return result;
  }

  return (
    <>  <link rel="stylesheet" href={"./assets/css/astro-login.css"} /> 
 
    
    <section>
    <div className="login_page_detail">
        <div className="container">
            <div className="row">

                <div className="col-md-12">
                      <div className="login_page_main_detail">
                        <div>
                            <div className="form-tit">
                                <h4>Start for free</h4>
                                <h2>Sign in to Mibhagya</h2>
                                <p>
                                <NavLink to="/astro-registration">Sign up now</NavLink >
                                </p>
                            </div>
                            <div className="form-login">
                            { loading ? <Loader /> :

                                <form onSubmit={onSubmitHandler}>

                                    <div className="form-group">
                                        <label className="lb">Mobile Number:</label>
                                        <input type="number" className="form-control"  name="" onChange={(e)=>inputChangeHandler(setMobile, e)}  placeholder="Enter your mobile number "/>
                                    </div>
                                    <div className="form-group">
                                        <label className="lb">Password:</label>
                                        <input type="password" className="form-control" id="pwd" onChange={(e)=>inputChangeHandler(setPassword, e)} placeholder="Enter your password" name="pswd" />
                                    </div>
                                    <div className="form-group form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" onChange={(e)=>inputChangeHandler(setRemember, e)} value='1' type="checkbox" name="agree" /> Remember
                                            me
                                        </label>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Sign in</button>
                                    
                                    </form>
                                    }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
    </>
  );
};

export default Astrologin;
