import React, {  useState, useEffect } from "react";
import {NavLink, useParams} from "react-router-dom";
import Loader from "../Pages/Loader";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from 'react-redux';
import { getBlogList } from "../../redux/action/BlogApi";
 

function Blog() {
  const params = useParams();
  const [startList, setStartList] = useState('2');
  const [endList, setEndList] = useState('5');
 
  const searchParams = new URLSearchParams(document.location.search)
  const currentPage = searchParams.get('page')===null?1:searchParams.get('page');
  const getsearch = searchParams.get('search')===null?'': searchParams.get('search');

  // const [search, setSearch] = useState(getsearch);


  const {totalRows, perPage, blogList,recentBlogs,blogCategory, message, error, loading } = useSelector(
    state => state.blog
  );



  const dispatch = useDispatch();
  
  // const handleKeyPress = async (event) => {
  //       // const myString = event.target.value;
  //      /// const stringLength = myString.length;
  //       //   if(stringLength>2){
  //         setSearch(event.target.value);
  //       //}
  // }
 
  useEffect(() => {
    // if(getsearch){
    //   setSearch(getsearch);
    // }
        if(params.slug || getsearch !==''){
          setStartList('0');
          setEndList('12');
        }else{
          setStartList('2');
          setEndList('5');
        }
      
        var postdata = {
          longitude: 'test',
          latitude: 'testsmdlkasm',
          slug: (params.slug)?params.slug:'',
          search :getsearch,
          page : currentPage?currentPage:1
        };
        console.log(postdata);
       dispatch(getBlogList(postdata));
     
  }, [dispatch,params.slug,getsearch,currentPage]);
  
  
  useEffect(() => {

    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }

  }, [dispatch, error, message]);

  const regex = /(<([^>]+)>)/gi;

  
  const pages = [];
    for (let i = 1; i <= Math.ceil(totalRows / perPage); i++) {
      pages.push(i);
    }

  

// console.log(pages);
  return (
<> <link rel="stylesheet" href={"./assets/css/new-style.css"} />


<section className="blog_top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="blog_top_content">
            <h2>Blog<br/></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/> Sed tempus purus a varius consequat.....</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section className="blog_heading">
    <div className="container-fluid">
      <div className="row menus">
        <div className="col-lg-8 col-md-8 col-sm-6">
          <div className="as_menu as_menu1">
            <ul>
                {blogCategory.length > 0 ? (
                    blogCategory.map((d,index) => (
                    <>
                      <li kay={index} >
                        <NavLink to={'/blog/'+d.slug.toLowerCase()}>{d.name}</NavLink>
                      </li>
                    </>
                    ))
                    ) : ('')
                }
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="as_search_widget forblog fontuser">
            <form className="nosubmit" action="/blog">
              <input
                className="nosubmit"
                type="search"
                name="search"
                minLength={3}
                placeholder="Search..."
                // value={search}
                //  onKeyUp={handleKeyPress}
              />

              <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>

            </form>
          </div>
        </div>
      </div>
    
      { loading ? <Loader /> : 
      <div className="blog_contents">
      {blogList.length > 0 && startList==='2' ? (
        blogList.slice(0, 2).map((blogs,index) => (
          index==='0'?
        <>
        <div kay={index} className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="images_blog">
                {
                  blogs.blog_pic?
                  (
                  <img alt="blog" src={blogs.blog_pic_path} />
                  )
                  :(
                  <img alt="blog" src='./assets/images/welcome-image.png' />
                  )
                }
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="content_txt_right ps-5">
              <h2>{blogs.title}</h2>
              <p>
              { blogs.description.replace(regex, "").substring(0, 250) + "..." }
              </p>

              <NavLink to={"/blog/view/"+blogs.slug} className="read_more_btn">{'Read More'}</NavLink>

            </div>
          </div>
        </div>
         </>
         :
         <>
          <div kay={index} className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="content_txt_right pe-4">
                <h2>{blogs.title}</h2>
                <p>
                { blogs.description.replace(regex, "").substring(0, 250) + "..." }

                </p>
                <NavLink to={"/blog/view/"+blogs.slug} className="read_more_btn">{'Read More'}</NavLink>

              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="images_blog">
                  {
                    blogs.blog_pic?
                    (
                    <img alt="blog" src={blogs.blog_pic_path} />
                    )
                    :(
                    <img alt="blog" src='./assets/images/welcome-image.png' />
                    )
                  }
              </div>
            </div>
          </div>
          </>
         ))
         ) : ('' )}

        <div className="row">
        {blogList.length > 0 ? (
        blogList.slice(startList, endList).map((blogs,index) => (
         
        <>
          <div key={index} className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="vertical_image_box">
              <span>
                  {
                    blogs.blog_pic?
                    (
                    <img alt="blog" src={blogs.blog_pic_path} />
                    )
                    :(
                    <img alt="blog" src='./assets/images/welcome-image.png' />
                    )
                  }
              </span>
              <h4>{blogs.title}</h4>

              <p>
              { blogs.description.replace(regex, "").substring(0, 140) + "..." }
              </p>
              <NavLink to={"/blog/view/"+blogs.slug} className="read_more_btn">{'Read More'}</NavLink>

              
            </div>
          </div>
          </>
          ))
         ) : ('')}

        </div>
      </div>
      }
    </div>
  </section>

{ pages.length > 0 ? (

  <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
   
  
    { 
        pages.map((number) => (
          <>
           {/* <li className="page-item disabled">
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </a>
          </li> */}

          <li  key={number}  className={currentPage == number ? "page-item active" : 'page-item'}  >
{ getsearch.length > 0 ? (
<NavLink to={params.slug?"/blog/"+params.slug+"?page="+number+"&search="+getsearch:"/blog?page="+number+"&search="+getsearch} className="page-link">{number}</NavLink>
):
( <NavLink to={params.slug?"/blog/"+params.slug+"?page="+number:"/blog?page="+number} className="page-link">{number}</NavLink>
)

}

          </li>
   
          {/* <li className="page-item">
          <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </a>
          </li> */}

         </>
        ))
    }

  </ul>
</nav> )
: ('')
} 

  <section className="heading_blog_sec">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="heading_blog_text">
            <h4>
              Heading
              <br />
              Lorem ipsum dolar
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat. Mauris ligula ex, auctor vel
              tortor at, vulputate porta justo.
            </p>
            <a href="/#" className="as_linkk1">
              See More
            </a>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
    </div>
  </section>
  <section className="ast_service_wrapper ">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="ast_service_slider">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="ast_service_box">
                  <img alt="test.jpg" src="./assets/images/blog6.svg" />
                  <h4>TOP VASTU TIPS FOR TOILET OR WASHROOM. TRY THEM NOW!</h4>
                  <p>
                    The colours present around you greatly affect your life and
                    the state of your mind at large....
                  </p>
                  <div className="clearfix" />
                  <a href="/#" className="as_linkk">
                    Read More
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="ast_service_box">
                  <img alt="test.jpg" src="./assets/images/blog7.svg"  />
                  <h4>
                    DO YOU WANT YOUR NEWLY ESTABLISHED BUSINESS TO PROSPER?{" "}
                  </h4>
                  <p>
                    The colours present around you greatly affect your life and
                    the state of your mind at large....
                  </p>
                  <div className="clearfix" />
                  <a href="/#" className="as_linkk">
                    Read More
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="ast_service_box">
                  <img alt="test.jpg" src="./assets/images/blog7.svg"  />
                  <h4>UNIVERSE COMMUNICATE THROUGH NUMBERS !</h4>
                  <p>
                    The colours present around you greatly affect your life and
                    the state of your mind at large....
                  </p>
                  <div className="clearfix" />
                  <a href="/#" className="as_linkk">
                    Read More
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="ast_service_box">
                  <img alt="test.jpg" src="./assets/images/blog7.svg"  />
                  <h4>TOP VASTU TIPS FOR TOILET OR WASHROOM. TRY THEM NOW!</h4>
                  <p>
                    The colours present around you greatly affect your life and
                    the state of your mind at large....
                  </p>
                  <div className="clearfix" />
                  <a href="/#" className="as_linkk">
                    Read More
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="ast_service_box">
                  <img alt="test.jpg" src="./assets/images/blog7.svg" />
                  <h4>
                    DO YOU WANT YOUR NEWLY ESTABLISHED BUSINESS TO PROSPER?{" "}
                  </h4>
                  <p>
                    The colours present around you greatly affect your life and
                    the state of your mind at large....
                  </p>
                  <div className="clearfix" />
                  <a href="/#" className="as_linkk">
                    Read More
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="ast_service_box">
                  <img alt="test.jpg" src="./assets/images/blog7.svg"  />
                  <h4>UNIVERSE COMMUNICATE THROUGH NUMBERS !</h4>
                  <p>
                    The colours present around you greatly affect your life and
                    the state of your mind at large....
                  </p>
                  <div className="clearfix" />
                  <a href="/#" className="as_linkk">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="news_blogs">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h2 className="heading_news">New Blogs</h2>
        </div>
      </div>
      <div className="row">
      {recentBlogs.length > 0 ? (
        recentBlogs.slice(0, 3).map((recentblogs,index) => (
        <>
          <div kay={index} className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="news_blog_content">
              <div className="news_blogs_img">
                  {
                    recentblogs.blog_pic?
                    (
                    <img alt="blog" src={recentblogs.blog_pic_path} />
                    )
                    :(
                    <img alt="blog" src='./assets/images/welcome-image.png' />
                    )
                  }
              </div>
              <div className="news_content">
              <h4>{recentblogs.title}</h4>
              <NavLink to={"/blog/view/"+recentblogs.slug} style={ {display:'initial'}} className="read_more_btn">{'Read More'}</NavLink>

              </div>
            </div>
          </div>
        </>
          ))
         ) : ('')}

      </div>
    </div>
  </section>
  <section className="heading_blog_sec mb-5 ">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="heading_blog_text">
            <h4>
              Heading
              <br />
              Lorem ipsum dolar
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat. Mauris ligula ex, auctor vel
              tortor at, vulputate porta justo.
            </p>
            <a href="/#" className="as_linkk1">
              See More
            </a>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
    </div>
  </section>
</>


  );
}
export default Blog;