import React , { useState} from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
 import AuthService from '../../services/AuthService'
import Modal from 'react-modal';
import OtpInput from 'react-otp-input';
import { useDispatch,useSelector } from "react-redux";
import { otpVerification } from "../../redux/action/Auth";


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
const customStyles = {
  content: {
    display: 'block',
  },
};
const customStyle = {
  content: {
    display: 'none',
  },
};

function AuthModel() {

  const {isLoginModel,isOtpModel,isRegisModel} = useSelector(state=>state.mymodel);
  // const {isOtpModel} = useSelector(state=>state.mymodel);
  // const {isRegisModel} = useSelector(state=>state.mymodel);

  //  console.log(isOtpModel);
  const dispatch = useDispatch();

  // const navigate = useNavigate();

    function showLoginModal() {
      dispatch({
        type:"openLoginModal",
      });

    }
   
    const closeLoginModal =()=>{
      dispatch({
        type:"closeLoginModal",
      });
    };
  
    function showRegisModel() {
      dispatch({
        type:"openRegisModel",
      });

    }
    const cloaseRegisModel =()=>{
      dispatch({
        type:"cloaseRegisModel",
      });
    };
  
    const closeOtpModal =()=>{
      dispatch({
        type:"closeOtpModal",
      });
    };
  
    const [mobile, usermobile] = useState('');
    const [otp, userotp] = useState('');
    const [email, useremail] = useState('');
   
    var postdata = {
      deviceType: 'android',
      user_type: 'user',
      mobile: mobile,
      device_id: '368453658648',
      fcm_key: 'r793487dfhy8934675',
      social_type:'',
      otp:otp
      };


    const ProceedLogin = (e) => {
      e.preventDefault();
     if(mobilevalidate()){
        console.log(mobile);
        console.log('process');
    
        AuthService.login(postdata)
        .then(function (response) {
                  //handle success
                  if (response.data.responseCode==='301') {

                    dispatch({
                      type:"openOtpModal",
                    });

                    // openOtpModal() ;
                    // setLoginModalIsOpen(false);
                    toast.success(response.data.responseMessage);

                  }else{
                    toast.error(response.data.responseMessage);

                  }
                console.log(response.data);
                // setCounsellor_review(response.data.counsellor_review);
                  // setTotal_session(response.data.session);
                  // setTotal_review(response.data.total_review);
                      // responseCode
                      // responseMessage

                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });

    }
    }
  
    const mobilevalidate=()=>{
      let result =true;
      if(mobile==='' || mobile===null){
        result =false;
        toast.error("Please enter mobile number.");
       // console.log('mobole number not valid');

      }
      else if(mobile.length!==10){
        result =false;

        toast.error("Please enter 10 mobile number.");
        }

      return result;
    }

   
  //console.log(otp);
  

  const ProceedOtpConfirm = (e) => {
    e.preventDefault();
   if(orpvalidate()){
      console.log(otp);
      console.log('otp process');
  
      dispatch(otpVerification(postdata));

  }
  }


  // const ProceedOtpConfirm = (e) => {
  //   e.preventDefault();
  //  if(orpvalidate()){
  //     console.log(otp);
  //     console.log('otp process');
  
  //     AuthService.otpVerification(postdata)
  //     .then(function (response) {
  //               //handle success
  //               if (response.data.responseCode==='200') {
                  
  //                 // sessionStorage.setItem('isUser', JSON.stringify(response.data.user_details));

  //                 window.sessionStorage.setItem('userId', response.data.user_id);
  //                 window.sessionStorage.setItem('hashToken', response.data.hash_token);
  //                 window.sessionStorage.setItem('online', response.data.user_details.online);
  //                 window.sessionStorage.setItem('profile_pic', response.data.user_details.profile_pic);
  //                 window.sessionStorage.setItem('email', response.data.user_details.email);
  //                 window.sessionStorage.setItem('mobile_no', response.data.user_details.mobile_no);
  //                 window.sessionStorage.setItem('name', response.data.user_details.first_name+' '+response.data.user_details.last_name);
  //                 window.sessionStorage.setItem('first_name', response.data.user_details.first_name);
  //                 window.sessionStorage.setItem('last_name', response.data.user_details.last_name);

  //         dispatch({ type:"closeOtpModal",  }); /// OTP Model close


  //               // closeOtpModal();
  //                 toast.success(response.data.responseMessage);
  //                 navigate('/');

  //               }else{
  //                 toast.error(response.data.responseMessage);

  //               }
  //             console.log(response.data);
             
  //             })
  //             .catch(function (response) {
  //               //handle error
  //               console.log(response);
  //             });

  // }
  // }

  const orpvalidate=()=>{
    let result =true;
    if(otp==='' || otp===null){
      result =false;
      toast.error("Please enter OTP.");
     // console.log('mobole number not valid');

    }
    else if(otp.length!==4){
      result =false;
         toast.error("Please enter 4 digit OTP.");
      }

    return result;
  }
  
  const ProceedRegis = (e) => {
    e.preventDefault();
   if(mobilevalidate() && emailvalidate()){
    console.log(mobile);
    console.log(email);
    console.log('process');

    var postregisdata = {
      user_type: 'user',
      mobile: mobile,
      email: email,
      first_name:'abc',
      last_name:'xyz'
      };
console.log(postregisdata);
  
      AuthService.register(postregisdata)
      .then(function (response) {
                //handle success
                if (response.data.responseCode==='200') {

                  dispatch({ type:"openOtpModal",  }); /// OTP Model close


                  // openOtpModal() ;
                  // closeRegisModal();
                  toast.success(response.data.responseMessage);

                }else{
                  toast.error(response.data.responseMessage);

                }
              console.log(response.data);
          
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });

  }
  }
  // console.log(otp);

  const emailvalidate=()=>{
    let result =true;
    if(email==='' || email===null){
      result =false;
      toast.error("Please enter your email.");
     // console.log('mobole number not valid');

    
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      result =false;
      toast.error("Invalid email address.");
      }

    return result;
  }

// sessionStorage.setItem('key', 'value');
// const dataasa = sessionStorage.getItem();
// sessionStorage.removeItem('key');
// sessionStorage.clear()
  return (
    <>

 {/* login Modal */}

  <Modal
  isOpen={isLoginModel}
  className="modal fade login show"
  id="login"
  tabIndex={-1}
  aria-labelledby="otpLabel"
  aria-hidden="true"
  style={isLoginModel?customStyles:customStyle}
  aria={{
    labelledby: "heading",
    describedby: "full_description"
  }}>

    <div className="modal-dialog">
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={closeLoginModal}
        >
        <img alt="test.jpg" src="./assets/images/close_btn.svg"  />
        </button>
        <div className="login-bg"></div>
        <div className="login-content">
          <h3 className="login-heading">
            welcome to <span>Mibhagya</span>
          </h3>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </p>
          <form  onSubmit={ProceedLogin} className="login-form">
            <label htmlFor="phonenum">Enter Your Mobile Number</label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <img alt="test.jpg" src="./assets/images/flag.svg"  />
                <span>+91</span>
              </span>
              <input
                type="number"
                className="form-control"
                maxLength={10}
                placeholder="Enter Your Mobile Number"
                id="phonenum"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={mobile}
                onChange={e=>usermobile(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="login-next"
        
            >
              Next
            </button>
            {/* <button
              type="submit"
              className="login-next"
              data-bs-toggle="modal"
              data-bs-target="#loginotp"
            >
              Next
            </button> */}
            <p className="extra-text">
              Or You Can Create New Account !
              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#register"
                onClick={showRegisModel}
              >
                Register Now
              </button>{" "}
            </p>
          </form>
        </div>
      </div>
    </div>
  </Modal>
  {/* login model */}
 
<Modal
  isOpen={isOtpModel}
  className="modal fade login show"
  id="otpmodel"
  tabIndex={-1}
  aria-labelledby="otpLabel"
  aria-hidden="true"
  style={isOtpModel?customStyles:customStyle}
  aria={{
    labelledby: "heading",
    describedby: "full_description"
  }}>
     <div className="modal-dialog">
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={closeOtpModal}
         >
          <img alt="test.jpg" src="./assets/images/close_btn.svg"  />
        </button>
        <div className="login-bg"></div>
        <div className="login-content">
          <h3 id="heading" className="login-heading">
            welcome to <span>Mibhagya</span>
          </h3>
       

          <p  id="full_description" className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </p>
          <form onSubmit={ProceedOtpConfirm} className="login-form">
            <div className="otp-detail">
              <p className="otp-text">You will receive an OTP on</p>
              <p className="otp-num otp-send-num">+91-{mobile}</p>
            </div>
            <div className="">
              <p className="otp-label">Enter Your OTP Here</p>
         

              <div className="otp-group">
              <OtpInput
              value={otp}
              onChange={userotp}
              numInputs={4}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              />

              </div>
            </div>
            <p className="dont-recive">
              Don’t received the oTP?
              <a href="/#">Resend OTP</a>
            </p>
            <button type="submit" className="login-next" id="otp-confirm">
              Confirm
            </button>
            <p className="extra-text">
              Or You Can Create New Account !
              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#register"
                onClick={showRegisModel}
              >
                Register Now
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
   
</Modal>



  {/* login otp model */}
 
 
  {/* Register Modal */}
 
  <Modal
  isOpen={isRegisModel}
  className="modal fade login register show"
  id="register"
  tabIndex={-1}
  aria-labelledby="otpLabel"
  aria-hidden="true"
  style={isRegisModel?customStyles:customStyle}
  aria={{
    labelledby: "heading",
    describedby: "full_description"
  }}>

    <div className="modal-dialog">
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={cloaseRegisModel}
        >
          <img alt="test.jpg" src="./assets/images/close_btn.svg"  />
        </button>
        <div className="login-bg"></div>
        <div className="login-content">
          <h3 className="login-heading">
            welcome to <span>Mibhagya</span>
          </h3>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </p>
          <form onSubmit={ProceedRegis}  className="login-form">
            <label htmlFor="phonenum">Enter Your Mobile Number</label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <img alt="test.jpg" src="./assets/images/flag.svg"  />
                <span>+91</span>
              </span>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Your Mobile Number"
                id="phonenum"
                name="mobile_no"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={mobile}
                onChange={e=>usermobile(e.target.value)}
              />
            </div>
            <label htmlFor="email">Enter Your Email</label>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                id="email"
                aria-label="Username"
                name="email"
                value={email}
                aria-describedby="basic-addon1"
                onChange={e=>useremail(e.target.value)}

              />
            </div>
            {/* <label htmlFor="email">Enter Your Name</label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                id="name"
                aria-label="Username"
                name="name"
                aria-describedby="basic-addon1"
              />
            </div> */}
            <button
              type="submit"
              className="login-next"
              // data-bs-toggle="modal"
              // data-bs-target="#registerotp"
            >
              Next
            </button>
            <p className="extra-text">
              if you already Mibhagya customer? !
              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#login"
                onClick={showLoginModal}

              >
                Login Now
              </button>{" "}
            </p>
          </form>
          <div className="third-party-btns">
            <a href="/#" className="google-btn">
              <img alt="test.jpg" src="./assets/images/google_btn.svg"  />
            </a>
            <span className="or-section">—or—</span>
            <a href="/#" className="fb-btn">
              <img alt="test.jpg" src="./assets/images/facebook_btn.svg"  />
            </a>
          </div>
        </div>
      </div>
    </div>
    </Modal>
  {/* Register model */}



    </>
  );
}
export default AuthModel;