// import http from "../http-common";
import axios from "axios";

const baseURL ="https://manageonline.in/demo/mibhagya/WEB_V21/";


const login = postdata => {
    return axios({
        method: "post",
        url: baseURL+"AuthApi/login_v2",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })

  };



const register = postdata => {
    return axios({
        method: "post",
        url: baseURL+"AuthApi/register",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })

  };

  // const otpVerification = postdata => {
  //   return axios({
  //       method: "post",
  //       url: baseURL+"AuthApi/otpVerification",
  //       data: postdata,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  // };

  // const logout = postdata => {
  //   return axios({
  //       method: "post",
  //       url: baseURL+"AuthApi/logout",
  //       data: postdata,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  // };

  // const online = postdata => {
  //   return axios({
  //       method: "post",
  //       url: baseURL+"AuthApi/online",
  //       data: postdata,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  // };

  // const forgetPassword = postdata => {
  //   return axios({
  //       method: "post",
  //       url: baseURL+"AuthApi/forgetPassword",
  //       data: postdata,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  // };


  // const changePassword = postdata => {
  //   return axios({
  //       method: "post",
  //       url: baseURL+"AuthApi/changePassword",
  //       data: postdata,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  // };


  // const getUserDetail = postdata => {
  //   return axios({
  //       method: "post",
  //       url: baseURL+"AuthApi/getUserDetail",
  //       data: postdata,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  // };


const UserService = {
    login,
    register,
    // otpVerification,
    // logout,
    // online,
    // forgetPassword,
    // changePassword,
    // getUserDetail
};

export default UserService;