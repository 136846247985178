import React, {  useEffect } from "react";
import { NavLink, useNavigate, useParams} from "react-router-dom";
import UserService from '../../services/UserServices'
//import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { getCounsellorDetails } from "../../redux/action/UserApi";

const Detail = ({ isAuthenticated = false, user }) => {

    const params = useParams();
   
    const { counsellor_detail,counsellor_review,total_review,total_session } = useSelector(
      state => state.user
    );
    const {walletAmount} = useSelector(state=>state.auth);

    const dispatch = useDispatch();
    const navigate= useNavigate();


    useEffect(() => {
        var postdata = {
        counsellor_id: params.id,
        };
        dispatch(getCounsellorDetails(postdata)); //get user details
     
      //  const  counsDetails = async () => {

      //   await UserService.getCounsellor(postdata)
      //    .then(function (response) {
      //         //handle success
      //         if (response.data.counsellor_detail.length > 0) {
      //           setCounsellor_detail(response.data.counsellor_detail[0]);
      //         }
      //       // console.log(response.data);
      //         setCounsellor_review(response.data.counsellor_review);
      //         setTotal_session(response.data.session);
      //         setTotal_review(response.data.total_review);
      //             // responseCode
      //             // responseMessage
      
      //       })
      //       .catch(function (response) {
      //         //handle error
      //         console.log(response);
      //       });
      //    };

      // counsDetails();

     }, [dispatch,params.id]);

   
  const successConfirm =(responseMessage,responseRemainingMinutes) =>{

            confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='react-confirm-alert-body'>
                  <h1 className="text-success">Connection!!</h1>
                  <p>{responseMessage}</p>
                  <p>Call Duration:  {responseRemainingMinutes} minute</p>
                  <p style={{ fontSize:'12px' }} >In case no call reciveed. please select other astrologer</p>

                  <div className="react-confirm-alert-button-group">
                  <button onClick={onClose}>Close</button>
                  
                  </div>
                </div>
              );
            }
        });

  }

     
  const errorConfirm =(responseMessage) =>{

    confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='react-confirm-alert-body'>
          <h1 className="text-danger">Recharge!!</h1>
          <p>{responseMessage}</p>
          <div className="react-confirm-alert-button-group">
          <button onClick={onClose}>Close</button>
          <button
                    onClick={() => {
                      navigate('/user/wallet');
                        onClose();
                    }}
                    >
                    Recharge
                  </button>
          </div>
        </div>
      );
    }
});

}


    function CallNow() {
      if(!isAuthenticated){
        dispatch({
          type:"openLoginModal",
        });
     
      }
      else if(counsellor_detail.online !== '1'){ // Not onlne

              if(counsellor_detail.online === '2'){ //Busy
               toast.error('Astrologer is busy on another call.');

              }
              else{ //Ofline
                toast.error('Astrologer is offline.');
              }
      }
      else{ ///call confirmation
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='react-confirm-alert-body'>
                  <h1>Confirmation!!</h1>
                  <p>You have selected <b> {counsellor_detail.name}</b></p>
                  <p>For yor astro session.</p>
                  <div className="react-confirm-alert-button-group">
                  <button onClick={onClose}>Cancel</button>
                  <button
                    onClick={() => {
                      callNowConfirm();
                        onClose();
                    }}
                    >
                    Confirm
                  </button>
                  </div>
                </div>
              );
            }
        });
      }
    }

 

    async function  callNowConfirm() {

      if(!isAuthenticated){
        dispatch({
          type:"openLoginModal",
        });
      }else{

        var callpostdata={
              userId : window.localStorage.getItem('userId'),
              hash_token : window.localStorage.getItem('hashToken'),
              counsellor_id : params.id,
              minute : '',
              amount : walletAmount,
              call_type :'',//'free',
        }
       
        await UserService.callNow(callpostdata)
        .then(function (response) {
              //handle success
              
              if (response.data.responseCode==='200') {
              console.log(response.data);
              // $response['call_id'] = $insert_id;

              setTimeout(() => {
              // After 3 seconds set the show value to false
              successConfirm(response.data.responseMessage,response.data.responseRemainingMinutes);
              }, 1000)


            }else if(response.data.responseCode==='201'){

              setTimeout(() => {
                // After 3 seconds set the show value to false
                errorConfirm(response.data.responseMessage);
              }, 1000)


            }else{
              toast.error(response.data.responseMessage);

            }
      
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
               
           
     
      }
    }
    
     


const listItems = counsellor_review.map((d,index) => 
  <div className={ index%2 ===0 ? "reviews_content odd": "reviews_content even" } key={index}>
  <div className="container-fluid">
    <div className="reviews_txt">
      <div className="head_review">
        <h2>{d.name}</h2>
        <span>
          <img alt="test.jpg" src="../../assets/images/rating_reviews.svg" />
        </span>
      </div>
      <div className="d-flex couns_rating">
        <p>{d.couns_review}
        </p>
        <span className="timings">{d.start_time}</span>
      </div>
    </div>
  </div>
</div>
);




  return (
<>
  <section className="profile_page">
    <div className="profile_bg">
    <div className="profile_image">
            <img src='../../assets/images/profile_b.png' alt='' />
        </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <div className="left_profile">
            <div className="user_pro">
              <img alt="test.jpg" src={ counsellor_detail? counsellor_detail.pic:''} />
            </div>
            <div className="rating_s">
              <p className="rating_count">{ counsellor_detail? counsellor_detail.rating:'' }</p>
              <p>
                <span>
                  <img alt="test.jpg" src="../../assets/images/pro-rating.svg" />
                </span>
                <span className="reviews">{ total_review? total_review:'' }+ Reviews</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="right_profile">
            <div className="user_details">
              <div className="online_offline_btn">
                
              {counsellor_detail.online === '1'
                ?  <img alt="test.jpg" src="./assets/images/online_btn.svg"/>
                : 
                counsellor_detail.online === '2'
                  ? <><NavLink to="/expert-detail" className="disabledCursor">Busy</NavLink > 
                  <img alt="test.jpg" src="./assets/images/offline_btn.svg"/></>

                 : <><NavLink to="/expert-detail" className="disabledCursor">Notifiy</NavLink > 
                    <img alt="test.jpg" src="./assets/images/offline_btn.svg"/></>
                }
              </div>

              <div className="user_name">
                <h3>{ counsellor_detail? counsellor_detail.name:'' }</h3>
                <span className="verified_user">
                  <img alt="test.jpg" src="../../assets/images/verified_icon.svg" />
                </span>
              </div>
              <p className="user_sub">{ counsellor_detail? counsellor_detail.sub_category:'' }</p>
              <div className="user_experience">
                <NavLink to="/#" className="exp-btn disabledCursor">
                  <span className="exp-icon">
                    <img alt="test.jpg" src="../../assets/images/documents_icon.svg" />
                  </span>
                  <span className="exp-text">Exp- { counsellor_detail? counsellor_detail.experiance:'' } yr+</span>
                </NavLink >
                <NavLink to="/#" className="exp-btn disabledCursor">
                  <span className="exp-icon">
                    <img alt="test.jpg" src="../../assets/images/session_icon.svg" />
                  </span>
                  <span className="exp-text">{ total_session? total_session:'' }+ Session</span>
                </NavLink >
                <NavLink to="/#" className="exp-btn disabledCursor">
                  <span className="exp-icon">
                    <img alt="test.jpg" src="../../assets/images/hindi_eng_icon.svg" />
                  </span>
                  <span className="exp-text">Hindi, { counsellor_detail? counsellor_detail.language:'' }</span>
                </NavLink >
              </div>
              <div className="location_share disabledCursor">
                <span>
                  <NavLink to="/#" className="exp-btn disabledCursor">
                    <img alt="test.jpg" src="../../assets/images/location.svg" />
                    <span className="location_txt">{ counsellor_detail? counsellor_detail.address:'' }</span>
                  </NavLink >
                </span>
                <span className="share_btns">
                  <NavLink to="/#" className="disabledCursor">
                    <img alt="test.jpg" src="../../assets/images/share_btn.svg" />
                  </NavLink >
                </span>
              </div>
              <div className="awards_call">
                <div className="certificate">
                  <p>Awards &amp; Certificate</p>
                  <div className="certi_images">
                    <ul>
                      <li>
                        <span>
                          <NavLink to="/" className="disabledCursor">
                            <img alt="test.jpg" src="../../assets/images/cert_images.svg" />
                          </NavLink >
                        </span>
                      </li>
                      <li>
                        <span>
                          <NavLink to="/" className="disabledCursor">
                            <img alt="test.jpg" src="../../assets/images/cert_images.svg" />
                          </NavLink >
                        </span>
                      </li>
                      <li>
                        <span>
                          <NavLink to="/" className="disabledCursor">
                            <img alt="test.jpg" src="../../assets/images/cert_images.svg" />
                          </NavLink >
                        </span>
                      </li>
                      <li>
                        <span>
                          <NavLink to="/" className="disabledCursor">
                            <img alt="test.jpg" src="../../assets/images/cert_images.svg" />
                          </NavLink >
                        </span>
                      </li>
                    </ul>
                    <span className="more_btn disabledCursor">
                      <NavLink to="/#">+More</NavLink >
                    </span>
                  </div>
                </div>
               
                <div className="call_price" onClick={CallNow}>
                  <span>
                    <NavLink  className="call-btn disabledCursor">
                      <img alt="test.jpg" src="../../assets/images/calling-icon.png" />
                    </NavLink >
                  </span>
                  <div className="price_values" >
                    <i className="fa fa-inr" aria-hidden="true" />
                    <span className="rate">{ counsellor_detail? counsellor_detail.minute_price:''}</span>
                    <span>/</span>
                    <span className="minute">Min</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="about_me">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="about_me_txt">
            <div className="about_icon">
              <NavLink to="" className='disabledCursor'>
                <img alt="test.jpg" src="../../assets/images/about_me.svg" />
                About me
              </NavLink >
            </div>
            <p>{ counsellor_detail? counsellor_detail.pro_content:'' } {" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="reviews_section">
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-12">
          <h2>
            <span>
              <img alt="test.jpg" src="../../assets/images/reviews.svg" />
            </span>
            Reviews
          </h2>
        </div>
      </div>
    </div>
  </div>

  {listItems}


  
  <div className="show_more">
    <NavLink to="/#" className="disabledCursor">Show More+</NavLink >
  </div>
  {/*Main page content End  */}
</>


  );
}
export default Detail;