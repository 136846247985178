import { server } from '../store';
import axios from 'axios';


export const getCounsellors = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/getCounsellor_v2",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'getCounsellorsSuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'getCounsellorsFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};


export const getCounsellorDetails = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'loadingRequest' });
  
      const { data } = await axios({
          method: "post",
          url: server+"UsersApi/getCounsellor_v2",
          data: postdata,
          headers: { "Content-Type": "multipart/form-data" },
        })
          if (data.responseCode==='200') {
              dispatch({ type: 'getCounsellorDetailSuccess', payload: data });
          } else if (data.responseCode==='401') {
              dispatch({ type: 'SessionExpired', payload: data.responseMessage });
          }else{
              dispatch({ type: 'getCounsellorsFail', payload: data.responseMessage });
          }
    } catch (error) {
      console.log(error);
    }
  };
  

export const getUserwallet = (postdata) => async dispatch => {
    try {
      dispatch({ type: 'loadingRequest' });
  
      const { data } = await axios({
          method: "post",
          url: server+"UsersApi/getUserwallet",
          data: postdata,
          headers: { "Content-Type": "multipart/form-data" },
        })
          if (data.responseCode==='200') {
              dispatch({ type: 'getUserwalletSuccess', payload: data });
          } else if (data.responseCode==='401') {
              dispatch({ type: 'SessionExpired', payload: data.responseMessage });
          }else{
              dispatch({ type: 'getUserwalletFail', payload: data.responseMessage });
          }
    } catch (error) {
      console.log(error);
    }
  };
  


export const addMoney = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/addMoney_v3",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'getAddMoneySuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'getAddMoneyFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};



export const getCategoryLang = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/getCategory_v2",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {
            dispatch({ type: 'getCategoryLangSuccess', payload: data });
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'getCategoryLangFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};

export const PostContactUs = (postdata) => async dispatch => {
  try {
    dispatch({ type: 'loadingRequest' });

    const { data } = await axios({
        method: "post",
        url: server+"UsersApi/contact_us",
        data: postdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        if (data.responseCode==='200') {

          console.log('Your request has been submitted successfully !');
 
        } else if (data.responseCode==='401') {
            dispatch({ type: 'SessionExpired', payload: data.responseMessage });
        }else{
            dispatch({ type: 'getCategoryLangFail', payload: data.responseMessage });
        }
  } catch (error) {
    console.log(error);
  }
};
