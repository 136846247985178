import React ,{useEffect,useState, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import Loader from "../Pages/Loader";
import ChartSvg from "../Pages/ChartSvg";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { dailyPanchang, panchangChartImage} from "../../redux/action/HoroscopeApi";
import { GetCountry, GetCity } from "../../redux/action/WorldCityApi";

function Panchang() {
    const { dailypanchangs, chartImage, message, error } = useSelector(
        state => state.horoscope
      );
     
      const { allCountry, CityByountry ,getCountryStatus} = useSelector(
             state => state.worldcity
      );

      const [latno, setLatno] = useState('28.6139');
      const [lngno, setLngno] = useState('77.209');
      const [city, setCity] = useState('28.6139,77.209');
      const [country, setCountry] = useState('IND');
      const inputRef = useRef(null);

      const dispatch = useDispatch();

      function handleChange(e) {
        console.log(e.target.value);
         setCity(e.target.value);

      }

        async function Search(){
            var ffff =   inputRef.current.value;
            var strArray = ffff.split(",");
            console.log(ffff);
            console.log(strArray[0]);
            console.log(strArray[1]);
            await setLatno(strArray[0]);
            await setLngno(strArray[1]);
            await setCity(ffff);
        }


        async function handleCountryChange(event){
            //console.log(event.target.value);

            await setCountry(event.target.value); 
          }

      useEffect(() => { /// het panchang
            var postdata = {
                    date:moment().format("DD/MM/YYYY"), //current date
                    api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
                    lang:'en',
                    tz:'5.5',
                    lat:latno,//'28.556421',
                    lon:lngno,//'77.377832',
                    time:moment().format("h:s")
            };
       

            const loadpanchang = async () => {
                dispatch(dailyPanchang(postdata));
            }
            loadpanchang();

            const loadmuhurta = async () => {
            
                var postdata1 = {
                    dob:moment().format("DD/MM/YYYY"), //current date
                    api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
                    lang:'en',
                    tz:'5.5',
                    lat:'28.556421',
                    lon:'77.377832',
                    tob:moment().format("HH:MM"),
                    div:'D1',
                    color:'%23ff3366',
                    style:'north',
                    font_size:'12',
                    font_style:'Nunito',
                    colorful_planets:'0',
                    size:'300',
                    stroke:'2',
            };

    
    

                dispatch(panchangChartImage(postdata1));
            }
            loadmuhurta();

            console.log('i fire once');


      }, [dispatch,lngno,latno]);
    

      useEffect(() => { ///error msg
            if (error) {
            toast.error(error);
            dispatch({ type: 'clearError' });
            }
        
            if (message) {
          //  toast.success(message);
            dispatch({ type: 'clearMessage' });
            }
        
      }, [dispatch, error, message]);
    
      useEffect(() => { //get city
        
        if(getCountryStatus===false){
                var postdata = {
                        date:moment().format("DD/MM/YYYY"), //current date
                };
                const loadcountry = async () => {
                dispatch(GetCountry(postdata));
                }
              //  toast.success(country);

                loadcountry() ;
         }

         var postdata1 = {
            country:country, //current date
            };
            const loadCityByountry = async () => {
             dispatch(GetCity(postdata1));
            }

            loadCityByountry() ;
         
        
      }, [dispatch, country, getCountryStatus]);
      
    
    const todayDate = moment().format("LLLL")
    // const todayDates = moment().format("DD/MM/YYYY")
 //   console.log(CityByountry)

  return (
    
<>   
 {/* <link rel="stylesheet" href={"./assets/css/panchang.css"} /> */}

{/* {  console.log(allCountry) } */}

<section className="our_inspiration panchang_top">
         <div className="container-fluid">
            <div className="row">
               <div className="col-lg-12 text-center">
                 <h1>Today Panchang</h1>
                 <p>Check your today's horoscope</p>
               </div>
            </div>
         </div>
      </section>

     <section className="my_panchang">
        <div className="container-fluid">
            <div className="row todaypachang">
                <h3><span>Today’s </span> Panchang</h3>
                <div className="head_date">
                    <div className="left_clip">
                        <img src={"./assets/images/pleftarrow.svg"} alt=""/>
                    </div>
                    {/* December 27, Tuesday | 10:25 am, Delhi, India */}
                    <div className="internal_date">{todayDate}, Delhi, India </div>
                    <div className="right_clip">
                        <img src={"./assets/images/prightarrow.svg"} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        <section className="tim_panchag">
        <div className="container">
            <div className="weather_head">
                <div className="row ">
                    <div className="col col-12 col-md-6  d-flex columan_gap">
                        <div className="weather weather_sun col col-12 col-md-6 ">
                            <div className="sun"><img src={"./assets/images/moon.svg"} alt="#"/></div>
                            <div className="sunrise">
                                <div className="sunrise_text">sunrise</div>
                                <div className="date_text">{ dailypanchangs? dailypanchangs.advanced_details.sun_rise+'-'+ dailypanchangs.advanced_details.sun_set:''}</div>
                            </div>
                        </div>

                        <div className="weather_moon col col-12 col-md-6  d-flex">
                            <div className="moon"><img src={"./assets/images/sunrise.svg"} alt=""/></div>
                            <div className="moonrise">
                                <div className="moonrise_text">moonrise</div>
                                <div className="date_moon_text">{dailypanchangs? dailypanchangs.advanced_details.moon_rise+'-'+ dailypanchangs.advanced_details.moon_set:''}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col col-12 col-md-6 col-lg-6 d-flex columan_gap" >
                        <div className="search_btn col col-12 col-md-12 col-lg-12 d-flex">
                            <div className="form-group">
                                <select name="country"  value={country} onChange={handleCountryChange}>
                                    { allCountry.length > 0? 
                                     allCountry.map(allCountry => (
                                    <option key={allCountry.id} value={allCountry.iso3}>
                                        {allCountry.country}
                                    </option>
                                    ))
                                    
                                    :''
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                            {/* <input type="search" placeholder="New Delhi, India"/> */}
                                <select ref={inputRef} name="city" value={city} onChange={handleChange}>
                                { CityByountry.length > 0? 

                                    CityByountry.map(CityByountry => (
                                    <option key={CityByountry.id} value={CityByountry.lat+','+CityByountry.lng}>
                                        {CityByountry.city_ascii}
                                    </option>
                                    ))
                                    :''
                                    }
                                </select>
                                
                            </div>
                            <div className="input_btn ">
                                <button onClick={Search}>Get Started</button>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
   
{ dailypanchangs === '' ? <Loader /> :
    <>
    <section className="rashi_sec">
        <div className="container">
            <div className="row">
                <div className="tithi_heading">
                    <h3>Rashi: <span>{dailypanchangs?dailypanchangs.rasi.name:''}</span></h3>
                </div>
                <div className="detail_tithi d-flex row">
                    <div className="list_tithi col col-3 col-md-3 col-lg-3">
                        <ul>
                            <li>Tithi</li>
                            <li>Nakshatra</li>
                            <li>Yoga</li>
                            <li>Karana</li>
                            <li>Paksha</li>
                        </ul>
                    </div>
                    <div className="list_tithi col col-3 col-md-3 col-lg-3">
                        <ul>
                            <li>{dailypanchangs?dailypanchangs.tithi.name:''}</li>
                            <li>{dailypanchangs?dailypanchangs.nakshatra.name:''}</li>
                            <li>{dailypanchangs?dailypanchangs.yoga.name:''}</li>
                            <li>{dailypanchangs?dailypanchangs.karana.name:''}</li>
                            <li>{dailypanchangs?dailypanchangs.advanced_details.masa.paksha:''}</li>
                        </ul>
                    </div>
                    <div className="list_tithi col col-6 col-md-6 col-lg-6">
                        <ul>
                            <li>{dailypanchangs?dailypanchangs.tithi.start +' to '+ dailypanchangs.tithi.end:''}</li>
                            <li>{dailypanchangs?dailypanchangs.nakshatra.start+' to '+dailypanchangs.nakshatra.end:''}</li>
                            <li>{dailypanchangs?dailypanchangs.yoga.start+' to '+dailypanchangs.yoga.end:''}</li>
                            <li>{dailypanchangs?dailypanchangs.karana.start+' to '+dailypanchangs.karana.end:''}</li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="detail_tithi second_head row d-flex">
                    <div className="list_tithi col col-4 col-md-4 col-lg-4">
                        <ul>
                            <li>Next Full moon</li>
                            <li>Next New moon</li>
                            <li>Rahu kaal</li>
                        </ul>
                    </div>
                    <div className="list_tithi col col-8 col-md-8 col-lg-8">
                        <ul>
                            <li>{dailypanchangs?dailypanchangs.advanced_details.next_full_moon:''}</li>
                            <li>{dailypanchangs?dailypanchangs.advanced_details.next_new_moon:''}</li>
                            <li>{dailypanchangs?dailypanchangs.rahukaal:''}</li>
                        </ul>
                    </div>
                
                </div>
            </div>
        </div>
    </section>

    <section className="calender">
        <div className="container ">
            <div className="row month_year">
                <div className="col-md-12 heading_month ">
                    <h3>Hindu Calendar (month and year)</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Kali Samvat</h2>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.years.kali:''}</h5>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.years.kali_samvaat_number+', '+dailypanchangs.advanced_details.years.kali_samvaat_name:''}</h5>
                </div>
               
            </div>
             <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Shaka Samvat</h2>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.years.saka:''}</h5>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.years.saka_samvaat_number+', '+dailypanchangs.advanced_details.years.saka_samvaat_name:''}</h5>
                </div>
            </div>
             <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Vikram Samvat</h2>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.years.vikram_samvaat:''}</h5>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.years.vikram_samvaat_number+', '+dailypanchangs.advanced_details.years.vikram_samvaat_name:''}</h5>
                </div>
            </div>

            <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Ritu</h2>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.masa.ritu:''}</h5>
                </div>
            </div>
            <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Amanta</h2>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.masa.amanta_number+', '+dailypanchangs.advanced_details.masa.amanta_name:''}</h5>
                </div>
            </div>
            <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Purnimanta</h2>
                    <h5>{dailypanchangs?dailypanchangs.advanced_details.masa.purnimanta_number+', '+dailypanchangs.advanced_details.masa.purnimanta_name:''}</h5>
                </div>
            </div>
         </div>
        </div>
    </section>
 
 
     <section className="calender inauspicious_time">
        <div className="container ">
            <div className="row month_year">
                <div className="col-md-12 heading_month ">
                    <h3>Auspicious Timings (Shubh Muhurat)</h3>
                </div>
            </div>
            <div className="row sub_timeinau">
                <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                <h2>Abhijit Muhurta</h2>
                    <p>{dailypanchangs?dailypanchangs.advanced_details.abhijit_muhurta.start+' To'+dailypanchangs.advanced_details.abhijit_muhurta.end:''}</p>
                </div>
               
            </div>
             {/* <div className="col-12 col-md-4 ">
                 <div className="year_tithi">
                    <h2>Rahu Kaal</h2>
                    <p>27-12-22 <span>02:59:40 AM</span> To 27-12-22 <span>04:17:08 AM</span></p>
                </div>
            </div>
             <div className="col-12 col-md-4 ">
                 <div className="year_tithi">
                    <h2>Rahu Kaal</h2>
                    <p>27-12-22 <span>02:59:40 AM</span> To 27-12-22 <span>04:17:08 AM</span></p>
                </div>
            </div> */}
            
         </div>
        </div>
    </section>
    
    <section className="calender  auspicious_time">
       <div className="container ">
            <div className="row month_year">
                <div className="col-md-12 heading_month ">
                <h3>Inauspicious Timings (Ashubh Muhurat)</h3>
                </div>
            </div>
            <div className="row sub_timeinau">
                <div className="col-12 col-md-4 ">
                <div className="year_tithi">
                    <h2>Rahu Kaal</h2>
                    <p>{dailypanchangs?dailypanchangs.rahukaal:''}</p>
                </div>
               
            </div>
             <div className="col-12 col-md-4 ">
                 <div className="year_tithi">
                    <h2>Gulika</h2>
                    <p>{dailypanchangs?dailypanchangs.gulika:''}</p>
                </div>
            </div>
             <div className="col-12 col-md-4 ">
                 <div className="year_tithi">
                    <h2>Yamakanta</h2>
                    <p>{dailypanchangs?dailypanchangs.yamakanta:''}</p>

                    {/* <p>27-12-22 <span>02:59:40 AM</span> To 27-12-22 <span>04:17:08 AM</span></p> */}
                </div>
            </div>
            
         </div>
        </div>
    </section >
   
    <section className="chart">


    <div className="container" >
        <div className="row">
            <div className=" col col-12 col-md-5 insde_chart">
                <h3>Lagna Chart at Sunrise</h3>
                {/* <img src={chartImage?chartImage:"./assets/images/image 7.svg"} alt=""/> */}
                {/* {chartImage?chartImage:''} */}

                { chartImage ? <ChartSvg chartsvgdata={ {svg:chartImage} }  /> :''}

            </div>
            <div className="col col-12 col-md-7 insde_content">
                <h3>
                    Chandrabalam And Tarabalam
                </h3>
                <div className="table_chart table_chart_top d-flex">
                    <div className="col col-12 col-md-4 small_content">
                        <h4>Tara Bala</h4>
                    </div>
                    <div className="col col-12 col-md-6 big_content">
                        <p>Ashwini, Krittika, Mrigashirsha, Pushya, Magha, Uttara Phalguni, Chitra,Anuradha, Mula,Uttara Ashadha, Dhanishta, Uttara Bhadrapada </p>
                    </div>
                </div>
                <div className="table_chart d-flex">
                    <div className="col col-12 col-md-4 small_content">
                        <h4>Chandra Bala</h4>
                    </div>
                    <div className="col col-12 col-md-6 big_content">
                        <p>Ashwini, Krittika, Mrigashirsha, Pushya, Magha, Uttara Phalguni, Chitra,Anuradha, Mula,Uttara Ashadha, Dhanishta, Uttara Bhadrapada </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </section>
  
    <section className="our_astrologers">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 text-center astro as_padderTop50">
          <div className="astroo1">
            <p>
              {"For detail horoscope analysis, speak to our astrologers"}
              
              <br />
              <NavLink  to="/expert" >Click Here</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
</>

  );
}
export default Panchang;