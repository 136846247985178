import React ,{useEffect,useState} from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Pages/Loader";
import { dailySun, yearlyPrediction, weeklyPrediction } from "../../redux/action/HoroscopeApi";
import moment from "moment";
import YearlyPrediction from "./YearlyPrediction";
import WeeklyPrediction from "./weeklyPrediction";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import  CountUp  from "react-countup";

function Horoscope() {
  const { daily, weekly, yearly, message, error, loading } = useSelector(
    state => state.horoscope
  );

  const todayDate = moment().format("dddd, DD MMM, YYYY")
  const [todayZodiac, setTodatZodiac] = useState('');
  const [weeklyZodiac, setWeeklyZodiac] = useState('');
  const [yearlyZodiac, setYearlyZodiac] = useState('');
  const [zodiac, setZodiac] = useState('1');
  const [zodiacTitle, setZodiacTitle] = useState('aries');

  const [todayPercentageTitle, setTodayPercentageTitle] = useState('career');
  const [todayPercentageText, setTodayPercentageText] = useState('');

  const [yearlyPhase, setYearlyPhase] = useState('');
  const [phaseMonth, setPhaseMonth] = useState(1);

  const [predictionBy, setPredictionBy] = useState('today');

  const dispatch = useDispatch();
  
  async function yearlyPhaseFn(arrdata,months){
    await setYearlyPhase(arrdata);
    await setPhaseMonth(months);
    }

  async function todayPercentageFn(title,text){
      await setTodayPercentageTitle(title);
      await setTodayPercentageText(text);
    }

  async function zodiacFn(e,title){
      await setZodiac(e);
      await setZodiacTitle(title); 
    }

  async function predictionByFn(text){
    await setPredictionBy(text); 
  }
  

  // async function showYearlyPrediction() {
  //     var postyesrdata = {
  //       zodiac:zodiac,
  //       year:moment().format("YYYY"), //current date
  //       api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
  //       lang:'en',
  //     };
  //     await dispatch(yearlyPrediction(postyesrdata));

  //   }

    // async function showWeeklyPrediction() {
    //   var postweekdata = {
    //     zodiac:zodiac,
    //     week:'thisweek', //current week
    //     show_same:true, 
    //     api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
    //     lang:'en',
    //   };
    //   await dispatch(weeklyPrediction(postweekdata));

    // }

  
  //   const currentDate = moment().format("DD/MM/YYYY")
 
  useEffect(() => {
    // 1 Aries, 2 Taurus, 3 Gemini, 4 Cancer, 5 Leo, 6 Virgo,
    //  7 Libra, 8 Scorpio, 9 Sagittarius, 10 Capricorn, 11 Aquarius, 12 Pisces 
      if(predictionBy==='today' && todayZodiac!==zodiac){
          var postdata = {
                  zodiac:zodiac,
                  date:moment().format("DD/MM/YYYY"), //current date
                  show_same:true,
                  api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
                  lang:'en',
                  split:true,
                  type:'big',
          };
          dispatch(dailySun(postdata));
          setTodatZodiac(zodiac); 
      }
      if(predictionBy==='weekly' && weeklyZodiac!==zodiac){
        var postweekdata = {
          zodiac:zodiac,
          week:'thisweek', //current week
          show_same:true, 
          api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
          lang:'en',
        };
        dispatch(weeklyPrediction(postweekdata));
        setWeeklyZodiac(zodiac); 

      }
      if(predictionBy==='yearly' && yearlyZodiac!==zodiac){
        var postyesrdata = {
          zodiac:zodiac,
          year:moment().format("YYYY"), //current date
          api_key:'2885bcc2-c254-5fec-ad3b-7c19248d189e',
          lang:'en',
        };
        dispatch(yearlyPrediction(postyesrdata));
        setYearlyZodiac(zodiac); 
      }
    console.log('i fire once');
    // document.getElementById("v-pills-home-tab").click();
  }, [dispatch,zodiac,predictionBy,todayZodiac,yearlyZodiac,weeklyZodiac]);


  useEffect(() => {

    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }

  }, [dispatch, error, message]);

  


  return (

<>
{ daily.length === 0 ? <Loader /> :
<>
  <section className="horoscope_top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h1>
            Horoscope <span>Prediction</span>
          </h1>
          <p>Check Your Today's Horoscope</p>
        </div>
      </div>
    </div>
  </section>
  <section className="horoscope_tabs">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="horo_tabs">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={(zodiac===1)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===1)?"true":"false"}
                  onClick={e=>zodiacFn(1,'aries')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/caries.svg" />
                  </span>
                  <p>Aries</p>
                </button>
              </li>
          
              <li className="nav-item"   role="presentation">
              <button
                  className={(zodiac===2)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===2)?"true":"false"}
                  onClick={e=>zodiacFn(2,'taurus')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/ctaurus.svg" />
                  </span>
                  <p>Taurus</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===3)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===3)?"true":"false"}
                  onClick={e=>zodiacFn(3,'gemini')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/cgemini.svg" />
                  </span>
                  <p>Gemini</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===4)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===4)?"true":"false"}
                  onClick={e=>zodiacFn(4,'cancer')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/ccancer.svg" />
                  </span>
                  <p>Cancer</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===5)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===5)?"true":"false"}
                  onClick={e=>zodiacFn(5,'leo')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/cleo.svg" />
                  </span>
                  <p>Leo</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===6)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===6)?"true":"false"}
                  onClick={e=>zodiacFn(6,'virgo')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/cvirgo.svg" />
                  </span>
                  <p>Virgo</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===7)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===7)?"true":"false"}
                  onClick={e=>zodiacFn(7,'libra')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/clibra.svg" />
                  </span>
                  <p>Libra</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===8)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===8)?"true":"false"}
                  onClick={e=>zodiacFn(8,'scorpio')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/cscorpio.svg" />
                  </span>
                  <p>Scorpio</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===9)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===9)?"true":"false"}
                  onClick={e=>zodiacFn(9,'sagittarius')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/csagittarius.svg" />
                  </span>
                  <p>Sagittarius</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===10)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===10)?"true":"false"}
                  onClick={e=>zodiacFn(10,'capricorn')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/ccapricorn.svg" />
                  </span>
                  <p>Capricorn</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===11)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===11)?"true":"false"}
                  onClick={e=>zodiacFn(11,'aquarius')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/caquarius.svg" />
                  </span>
                  <p>Aquarius</p>
                </button>
              </li>
              <li className="nav-item" role="presentation">
              <button
                  className={(zodiac===12)?"nav-link active":"nav-link"} 
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={(zodiac===12)?"true":"false"}
                  onClick={e=>zodiacFn(12,'pisces')}
                >
                  <span>
                    <img alt="test.jpg" src="./assets/images/cpisces.svg" />
                  </span>
                  <p>Pisces</p>
                </button>
              </li>

            
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="three_tabs tab-content" id="pills-tabContent">
    <div className="container-fluid">
      <div className="row">
        <div className=" col-md-12">
          <div
            className="sub_tabs tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="">
              <div
                className="nav nav-pills me-3 seconds_tabs"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                  onClick={e=>predictionByFn('today')}

                >
                  Today Prediction
                </button>
                <button
                  className="nav-link"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                  onClick={e=>predictionByFn('weekly')}
                >
                  weekly Prediction
                </button>
                <button
                  className="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                  onClick={e=>predictionByFn('yearly')}
                >
                  yearly prediction
                </button>
              </div>
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  
                  { !daily || loading ? <Loader /> :
                   <>
                    <div className="row prediction_main">
                      <div className="col-md-2">
                        <div className="prediction_logos">
                          <h3 className="as_subheading">{zodiacTitle[0].toUpperCase()+ zodiacTitle.slice(1)}</h3>
                          <div className="imgg text-center">
                            <img alt="test.jpg" src={"./assets/images/c"+zodiacTitle+".svg"}  />
                          </div>
                          <div className="txtt">
                            <p>({todayDate})</p>
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-md-10">
                        <div className="prediction_content">
                          <div className="pre_content_txt">
                            <h6>
                              <p>
                                Lucky Color ( {daily?daily.lucky_color:''} ){" "}
                                {/* <span>
                                  <img alt="test.jpg" src="./assets/images/lucky_color.svg" />
                                </span> */}
                                <span className="color_circle" 
                                  style={{ color: daily?daily.lucky_color_code:'', background: daily?daily.lucky_color_code:'' }} >C</span>
                              </p>
                              <p>
                                Lucky Number{" "}
                                <span className="pre_content_span">{daily.lucky_number.join(",")}</span>
                              </p>
                            </h6>
                            <h5>
                              <p>
                                Overall Day
                                <span className="dayspercent" style={{ border: '0px dashed #161A66'}}>
                                  {/* <b>{daily?daily.bot_response.total_score.score:0}%</b> */}
                                <div className="dayspercent1" style={{ width:"50", height: 50 }}>
                                <CircularProgressbar value={daily?daily.bot_response.total_score.score:0}  text={`${daily?daily.bot_response.total_score.score:0}%`}  
                                styles={{
                                  text: {
                                  // Text color
                                  fill: '#161A66',
                                  // Text size
                                  fontSize: '3rem',
                                  fontWeight: '600',
                                },
                                path: {
                                  stroke: `rgb(255, 172, 117)`,
                                },
                              }}
                              />
                            </div>
                            </span>

                              </p>
                            </h5>
                          </div>
                          <p className="pre_aliqua">
                                {daily?daily.bot_response.total_score.split_response:''}
                          </p>
                        </div>
                      </div>

                    </div>
                  
                    <div className="row love_logos">
                      <div className="col-md-2">
                        <div className="love_icon">
                          <img alt="test.jpg" src={"./assets/images/per_"+todayPercentageTitle+".svg"} />
                          <h5>{todayPercentageTitle}</h5>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="love_content">
                          <p>
                          {todayPercentageText?todayPercentageText:daily.bot_response.career.split_response}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row customers_percentage">
                      <div className="col-md-2">
                        <div className={todayPercentageTitle==="career"?"as_sign_box active":"as_sign_box"}
                         onClick={e=>todayPercentageFn('career',daily.bot_response.career.split_response)}>
                          <div className="as_sign_txt">
                            <span>
                              <img alt="test.jpg" src="./assets/images/per_career.svg" />
                            </span>
                            <p>
                              <CountUp start={0} end={daily?daily.bot_response.career.score:''} delay={2}
                              enableScrollSpy={true}
                              scrollSpyDelay={0}
                              />%</p>
                          </div>
                          <h4>career</h4>
                          </div>
                        </div>
                      <div className="col-md-2">
                      <div className={todayPercentageTitle==="family"?"as_sign_box active":"as_sign_box"}
                         onClick={e=>todayPercentageFn('family',daily.bot_response.family.split_response)}>     
                          <div className="as_sign_txt">
                            <span>
                              <img alt="test.jpg" src="./assets/images/per_family.svg" />
                            </span>
                            <p>
                              <CountUp start={0} end={daily?daily.bot_response.family.score:''} delay={2}
                              enableScrollSpy={true}
                              scrollSpyDelay={0}
                              />%</p>
                          </div>
                          <h4>family</h4>
                        </div>
                      </div>
                      <div className="col-md-2">
                      <div className={todayPercentageTitle==="love"?"as_sign_box active":"as_sign_box"}
                         onClick={e=>todayPercentageFn('love',daily.bot_response.relationship.split_response)}>    
                            <div className="as_sign_txt">
                            <span>
                              <img alt="test.jpg" src="./assets/images/per_love.svg" />
                            </span>
                            <p>
                              <CountUp start={0} end={daily?daily.bot_response.relationship.score:''} delay={2}
                              enableScrollSpy={true}
                              scrollSpyDelay={0}
                              />%</p>
                          </div>
                          <h4>Love</h4>
                        </div>
                      </div>
                      <div className="col-md-2">
                      <div className={todayPercentageTitle==="friends"?"as_sign_box active":"as_sign_box"}
                         onClick={e=>todayPercentageFn('friends',daily.bot_response.friends.split_response)}>    
                            <div className="as_sign_txt">
                            <span>
                              <img alt="test.jpg" src="./assets/images/per_friends.svg" />
                            </span>
                            <p>
                              <CountUp start={0} end={daily?daily.bot_response.friends.score:''} delay={2}
                              enableScrollSpy={true}
                              scrollSpyDelay={0}
                              />%</p>
                          </div>
                          <h4>friends</h4>
                        </div>
                      </div>
                      <div className="col-md-2">
                      <div className={todayPercentageTitle==="finances"?"as_sign_box active":"as_sign_box"}
                         onClick={e=>todayPercentageFn('finances',daily.bot_response.finances.split_response)}>    
                            <div className="as_sign_txt">
                            <span>
                              <img alt="test.jpg" src="./assets/images/per_finances.svg" />
                            </span>
                            <p>
                              <CountUp start={0} end={daily?daily.bot_response.finances.score:''} delay={2}
                              enableScrollSpy={true}
                              scrollSpyDelay={0}
                              />%</p>
                          </div>
                          <h4>finances</h4>
                        </div>
                      </div>
                      <div className="col-md-2">
                      <div className={todayPercentageTitle==="travel"?"as_sign_box active":"as_sign_box"}
                         onClick={e=>todayPercentageFn('travel',daily.bot_response.travel.split_response)}>    
                            <div className="as_sign_txt">
                            <span>
                              <img alt="test.jpg" src="./assets/images/per_travel.svg" />
                            </span>
                            <p>
                              <CountUp start={0} end={daily?daily.bot_response.travel.score:''} delay={2}
                              enableScrollSpy={true}
                              scrollSpyDelay={0}
                              />%</p>
                          </div>
                          <h4>travel</h4>
                        </div>
                      </div>
                    </div>
                    </> 
                  }

                </div>

                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  { weekly.length === 0  || loading ? <Loader /> :
                    <>
                    <WeeklyPrediction zodiacTitle={zodiacTitle} weeklydata={weekly} />
                    </>
                  }
                </div>
                
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  { yearly.length === 0  || loading ? <Loader /> :
                    <>
       
                  <YearlyPrediction  zodiacTitle={zodiacTitle} phase={yearlyPhase?yearlyPhase:yearly.phase_1}  />

                  <div className="row progress_bar_section">
                    <div className="col-md-2"></div>
                    <div className="col-md-2">
                      <div className="prog_content" onClick={e=>yearlyPhaseFn(yearly.phase_1,1)}>
                        <CircularProgressbar value={90} text={`${90}%`} 
                         styles={{
                          text: {
                          // Text color
                          fill: '#161A66',
                          // Text size
                          fontSize: '3rem',
                          fontWeight: '600',
                        },
                        path: {
                          stroke: `rgb(255, 172, 117)`,
                        },
                      }}
                      />

                        <p className={phaseMonth===1?'costom_color':''}>Jan - Mar.</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="prog_content" onClick={e=>yearlyPhaseFn(yearly.phase_2,2)}>
                        
                        <CircularProgressbar  value={68} text={`${68}%`} 
                         styles={{
                          text: {
                          // Text color
                          fill: '#161A66',
                          // Text size
                          fontSize: '3rem',
                          fontWeight: '600',
                        },
                        path: {
                          stroke: `rgb(255, 172, 117)`,
                        },
                      }}
                      />

                        <p className={phaseMonth===2?'costom_color':''}>Apr - jun.</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="prog_content" onClick={e=>yearlyPhaseFn(yearly.phase_3,3)}>
                      <CircularProgressbar value={80} text={`${80}%`} 
                         styles={{
                          text: {
                          // Text color
                          fill: '#161A66',
                          // Text size
                          fontSize: '3rem',
                          fontWeight: '600',
                        },
                        path: {
                          stroke: `rgb(255, 172, 117)`,
                        },
                      }}
                      />

                     <p className={phaseMonth===3?'costom_color':''} >jul - sep.</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="prog_content" onClick={e=>yearlyPhaseFn(yearly.phase_4,4)} >
                      <CircularProgressbar value={50} text={`${50}%`} 
                         styles={{
                          text: {
                          // Text color
                          fill: '#161A66',
                          // Text size
                          fontSize: '3rem',
                          fontWeight: '600',
                        },
                        path: {
                          stroke: `rgb(255, 172, 117)`,
                        },
                      }}
                      />

                        <p className={phaseMonth===4?'costom_color':''}>oct - dec.</p>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  </>
                  }
                </div>

              </div>
            </div>
          </div>
    
        </div>
      </div>
    </div>
  </section>
  <section className="our_astrologers">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 text-center astro as_padderTop50">
          <div className="astroo1">
            <p>
              {" "}
              For detail horoscope analysis, speak to our astrologers
              <br />
              <a href="/#">Click Here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  }
</>
  

  );
}
export default Horoscope;