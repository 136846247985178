import { createReducer } from "@reduxjs/toolkit";

const initialState ={
    allCountry:[],
    CityByountry: [],
    getCountryStatus:false,
}

export const WorldCityReducer = createReducer(initialState,{

    getCountrySuccess: (state, action) => {
        state.loading = false;
        state.allCountry = action.payload.country;
        state.loading = false;
        state.getCountryStatus=true;

    },
    
    getCountryFail: (state, action) => {
        state.loading = false;
        state.allCountry =[];
        state.getCountryStatus=false;

        // state.error = action.payload;
      },

     getCitySuccess: (state, action) => {
        state.loading = false;
        state.CityByountry = action.payload.city;
    },
    
    getCityFail: (state, action) => {
        state.loading = false;
        state.CityByountry =[];
        // state.error = action.payload;
      },
    ////////////
    
      loadingRequest: state => {
        state.loading = true;
    },

    clearError: state => {
    state.error = null;
    },
    clearMessage: state => {
    state.message = null;
    },
  

});