import React, {  useEffect } from "react";
import {NavLink} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
// import Loader from "../Pages/Loader";
import { toast } from "react-toastify";

 import ExpertlistData from '../Expert/ExpertList';
 import CustomerTrustCarouselData from '../Carousel/CustomerTrustCarousel';
 import { getHomeContentList } from "../../redux/action/HomeApi";

function Home() {
  // const Home = ({ isAstro }) => {

    const isAstro = localStorage.getItem('isAstroLogedIn');


    // const {contentList, message, error, loading } = useSelector(
      const {contentList, message, error } = useSelector(
        state => state.home
  );


  const dispatch = useDispatch();
  
  useEffect(() => {

   dispatch(getHomeContentList());


    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: 'clearMessage' });
    }

  }, [dispatch, error, message]);


  return (
<>
  <section className="home_page">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="as_banner_slider">
            <div className="as_banner_detail">
              <h1>
                The Most <span>Premium</span> <br /> Astro Platform
              </h1>
              <p>One-Stop Astro solution </p>
            </div>
          </div>
          <div className="row banner-row banners_row1">
            <div className="col-md-2 col-xs-6">
              <div className="icons text-center">
                
                <img alt="horoscope"
                  src="https://testapplication.in/mibhagya-web/new_assets/images/horoscope.png"
                  
                />
                <p>Vedic</p>
              </div>
            </div>
            <div className="col-md-2 col-xs-6">
              <div className="icons text-center">
                
                <img alt="tarot"
                  src="https://testapplication.in/mibhagya-web/new_assets/images/tarot.png"
                />
                <p>Tarot</p>
              </div>
            </div>
            <div className="col-md-2 col-xs-6">
              <div className="icons text-center">
                
                <img alt="compass"
                  src="https://testapplication.in/mibhagya-web/new_assets/images/compass.png"
                />
                <p>Vastu</p>
              </div>
            </div>
            <div className="col-md-2 col-xs-6">
              <div className="icons text-center">
                
                <img alt="numerology"
                  src="https://testapplication.in/mibhagya-web/new_assets/images/numerology.png"
                />
                <p>Numerology</p>
              </div>
            </div>
          </div>
          <div className="row banner-row banners_row2">
            <div className="col-md-2 col-xs-6 text-center">
              <div className="icons">
                
                <img alt="Kundali"
                  src="https://testapplication.in/mibhagya-web/new_assets/images/astrology.png"
                />
                <p>Kundali</p>
              </div>
            </div>
            <div className="col-md-2 col-xs-6 text-center">
              <div className="icons">
                
                <img alt="Personal-Healing"
                  src="https://testapplication.in/mibhagya-web/new_assets/images/meditation.png"
                />
                <p>Personal Healing</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="as_banner_img">
            <img alt="home_right" src="./assets/images/home_right.svg"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  {!isAstro ? <ExpertlistData/> :''}

  <section className="why_mibhagya">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 heading_astro text-center">
          <h1 className="as_heading">
            Why <span>MiBhagya</span>
          </h1>
          <p className="mbzero">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            purus a varius
            <br /> consequat. Mauris ligula ex, auctor vel tortor at, vulputate
            porta justo.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="as_whychoose_box premium_astro text-center">
            <span className="as_number">
              <img alt="premium_bar"
              src="./assets/images/premium_bar.svg"                 
              />
            </span>
            <h4>
              Premium Astrologers <br /> <span className="mi">100+</span>
            </h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="as_whychoose_box loved_customers text-center">
            <span className="as_number">
              <img alt="thumb"
                src="./assets/images//thumb.svg"                 

              />
            </span>
            <h4>
              
              Loved By Customer
              <br />
              <span className="mi">4.5+</span>
              <small>Google Rating</small>
            </h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="as_whychoose_box availablity_txt text-center">
            <span className="as_number">
              <img alt="service"
                src="./assets/images/service.svg"                 

              />
            </span>
            <h4>
              Availablity
              <br />
              <span className="mi">24x7</span>
            </h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="as_whychoose_box  text-center security_privacy">
            <span className="as_number">
              <img alt="icon"
                src="./assets/images/security_icon.webp"                 

              />
            </span>
            <h4>
              Security &amp; Privacy
              <br />
              <span className="mi">100% </span>
            </h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 offset-lg-3">
          <div className="as_whychoose_box text-center astrol_solution">
            <span className="as_number">
              <img alt="Security"
                src="./assets/images/solution.svg"                 

              />
            </span>
            <h4>
              One-Stop
              <br />
              <span>Astro Solution</span>
            </h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="as_whychoose_box text-center customers_care">
            <span className="as_number">
              <img alt="Solution"
                src="./assets/images/lap.svg"                 

              />
            </span>
            <h4>
              Quick
              <br />
              <span>Customer Care</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="customer_trusted">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 text-center astro">
          <h1 className="as_heading as_heading_center">
            customer <span>trust &amp; love</span>
          </h1>
          <p className="as_font14 as_margin0 as_padderBottom50">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            purus a varius <br />
            consequat. Mauris ligula ex, auctor vel tortor at, vulputate porta
            justo.
          </p>
        </div>
      </div>
<CustomerTrustCarouselData/>
    </div>
  </section>
  <section className="how_its_work">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 text-center astro">
          <h1 className="as_heading">
            <span>How It </span>Works ?
          </h1>
          <p className="as_font14 as_padderBottom30">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            purus a varius <br />
            consequat. Mauris ligula ex, auctor vel tortor at, vulputate porta
            justo.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="howit_worksimg">
            <img alt="how_it" src="./assets/images/how_it.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="expertis_in">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 text-center ">
          <h1 className="as_heading as_heading_center">
            Expertise <span>In</span>
          </h1>
          <p className="as_font14 as_margin0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            purus a varius <br />
            consequat. Mauris ligula ex, auctor vel tortor at, vulputate porta
            justo.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="Kundli" src="./assets/images/experties1.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="Kundli" src="./assets/images/experties2.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="Kundli" src="./assets/images/experties3.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="Kundli" src="./assets/images/experties4.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="Kundli" src="./assets/images/experties6.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="test" src="./assets/images/experties7.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="test" src="./assets/images/experties8.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="expertis_in_box">
            <div className="expertis_in_img">
              <span>
                <img alt="test" src="./assets/images/experties9.svg" />
              </span>
            </div>
            <div className="expertis_in_content">
              <h4>Kundli Reading</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                tempus purus a varius
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="lorem_lpsum">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 text-center astro">
          <h1 className="as_heading as_heading_center">
            Lorem <span>Ipsum</span>
          </h1>
          <p className="as_font14 as_margin0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            purus a varius <br /> consequat. Mauris ligula ex, auctor vel tortor
            at, vulputate porta justo.
          </p>
        </div>
      </div>
      <div className="row">
      {/* { loading ? <Loader /> : 
        <> */}
          {contentList.length > 0 ? (
                    contentList.map((d,index) => (
                    <>

                      <div className="col-md-2">
                        <div className="lorem_lpsum_box">
                            <NavLink className="nav-link" to={'/detail-page/'+d.slug.toLowerCase()}>
                            {
                                d.image?
                                (
                                <img width="175px" height="210px" alt={d.title} src={d.image_path} />
                                )
                                :(
                                <img alt="lorem" src='./assets/images/lorem_lipsum.svg' />
                                )
                              }

                           </NavLink >
                        </div>
                      </div>

                    </>
                    ))
                    ) : ('')
                }
       {/*  </> 
     // } */}
      </div>
    </div>
  </section>
</>

  );
}
export default Home;