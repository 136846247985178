import React from "react";

function OurInspiration() {
  return (
<>
<section className="our_inspiration">
   <div className="container-fluid">
      <div className="row">
         <div className="col-lg-12 text-center">
           <h1><b>"</b><span className="f-l">S</span>tar  
                  <span className="f-l">i</span>n  
                  <span className="f-l">t</span>he  
                  <span className="f-l">h</span>eavens  
                  <span className="f-l">s</span>ing  
                   <span className="f-l">A</span> 
                  <span className="f-l">m</span>usic  
                  <span className="f-l">i</span>f 
                  <span className="f-l">o</span>nly <br/> 
                  <span className="f-l">w</span>e 
                  <span className="f-l">h</span>ad  
                  <span className="f-l">e</span>ars  
                  <span className="f-l">t</span>o 
                  <span className="f-l">h</span>ear <b>"</b></h1>
         </div>
      </div>
   </div>
</section>

  <section className="inspiration_from">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="inspiration_top">
            <h3>
              Inspiration <span>From</span>
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat. Mauris ligula ex, auctor vel
              tortor at, vulputate porta justo.
            </p>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row left_inspiration_about">
        <div className="col-lg-4 col-md-4">
          <div className="inspiration_about_img">
            <img alt="test.jpg" src="./assets/images/ins_box.svg" />
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="inspiration_about_content">
            <h3>
              <span className="hf-l">L</span>t.{" "}
              <span className="tri">
                <span className="hf-l">S</span>hri 
                <span className="hf-l">T</span>
                <span className="hf-l">P </span>
                <span className="hf-l">T</span>rivedi 
              </span>
            </h3>
            <p className="content_sub">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="inspiration_boxes">
                  <span>
                    <img alt="test.jpg" src="./assets/images/ins_abt_img.svg" />{" "}
                  </span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inspiration_boxes">
                  <span>
                    <img alt="test.jpg" src="./assets/images/ins_abt_img.svg" />{" "}
                  </span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inspiration_boxes">
                  <span>
                    <img alt="test.jpg" src="./assets/images/ins_abt_img.svg" />{" "}
                  </span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row empty_boxing">
        <div className="col-md-3">
          <div className="inspi_box_main">
            <span>
              <img alt="test.jpg" src="./assets/images/round_box.svg" />
            </span>
            <div className="empty_boxing_content">
              <h2>Books</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="inspi_box_main">
            <span>
              <img alt="test.jpg" src="./assets/images/round_box.svg" />
            </span>
            <div className="empty_boxing_content">
              <h2>Articles</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="inspi_box_main">
            <span>
              <img alt="test.jpg" src="./assets/images/round_box.svg" />
            </span>
            <div className="empty_boxing_content">
              <h2>Awards &amp; Recoginition</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="inspi_box_main">
            <span>
              <img alt="test.jpg" src="./assets/images/round_box.svg" />
            </span>
            <div className="empty_boxing_content">
              <h2>Association</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our_astro_tabs">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="inspiration_top">
            <h3>
              Our <span>Astrologers</span>
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat. Mauris ligula ex, auctor vel
              tortor at, vulputate porta justo.
            </p>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row astro_tabs">
        <div className="col-md-12">
          <div className="d-flex align-items-start">
            <div
              className="nav flex-column nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                <span className="hf-l">L</span>orem{" "}
                <span className="hf-l">I</span>psum
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                <span className="hf-l">L</span>orem{" "}
                <span className="hf-l">I</span>psum
              </button>
              <button
                className="nav-link"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                <span className="hf-l">L</span>orem{" "}
                <span className="hf-l">I</span>psum
              </button>
              <button
                className="nav-link"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <span className="hf-l">L</span>orem{" "}
                <span className="hf-l">I</span>psum
              </button>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabIndex={0}
              >
                <div className="row">
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_img">
                      <img alt="test.jpg" src="./assets/images/ins_box.svg" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia{" "}
                      </p>
                      <ul>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {" "}
                <div className="row">
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_img">
                      <img alt="test.jpg" src="./assets/images/ins_box.svg" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia{" "}
                      </p>
                      <ul>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
                tabIndex={0}
              >
                {" "}
                <div className="row">
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_img">
                      <img alt="test.jpg" src="./assets/images/ins_box.svg" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia{" "}
                      </p>
                      <ul>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
                tabIndex={0}
              >
                {" "}
                <div className="row">
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_img">
                      <img alt="test.jpg" src="./assets/images/ins_box.svg" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-lg-6">
                    <div className="astro_tabs_content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia{" "}
                      </p>
                      <ul>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                        <li>
                          <span>
                            <img alt="test.jpg" src="./assets/images/round_box.svg" />
                          </span>
                          <h4>Lorem Ipsum</h4>
                          <p>
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="founder_story text-end">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <div className="inspiration_top text-end">
            <h3>
              Founder’s <span>Story</span>
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tempus purus a varius consequat. Mauris ligula ex, auctor vel
              tortor at, vulputate porta justo.
            </p>
          </div>
        </div>
      </div>
      <div className="row text-end">
        <div className="col-md-8">
          <div className="founder_story_content">
            <h3>
              <span className="hf-l">D</span>EEKSHA{" "}
              <span className="tri">
                <span className="hf-l">B</span>ajpai
              </span>{" "}
              <small>( Founder )</small>
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia{" "}
            </p>
            <div className="row">
              <div className="col-md-3">
                <div className="founder_story_box">
                  <span>
                    <img alt="test.jpg" src="./assets/images/round_box.svg" />
                  </span>
                  <h4>Lorem Ipsum</h4>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="founder_story_box">
                  <span>
                    <img alt="test.jpg" src="./assets/images/round_box.svg" />
                  </span>
                  <h4>Lorem Ipsum</h4>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="founder_story_box">
                  <span>
                    <img alt="test.jpg" src="./assets/images/round_box.svg" />
                  </span>
                  <h4>Lorem Ipsum</h4>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="founder_story_box">
                  <span>
                    <img alt="test.jpg" src="./assets/images/round_box.svg" />
                  </span>
                  <h4>Lorem Ipsum</h4>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="founder_story_image">
            <img alt="test.jpg" src="./assets/images/ins_box.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="what_to_ignore">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="quote text-center">
            <h3>
              “<span className="bf-l">A</span>rt 
              <span className="bf-l">o</span>f<span className="bf-l">K</span>
              nowing 
              <span className="bf-l">I</span>s<span className="bf-l">W</span>hat 
              <span className="bf-l">t</span>o<span className="bf-l">I</span>
              gnore”
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  );
}
export default OurInspiration;