import { createReducer } from "@reduxjs/toolkit";

const initialState ={
    daily:[],
    weekly:[],
    yearly:[],
    loading:false,
    dailypanchangs:'',
    chartImage:'',
    kundliBasic:'',
    kundliFormdata:[],
    kundliFormdata2:[],
    kundliDataGetSuccess:[],
    GetKundliDataById:'',

    kundliForm:false,
    kundliForm2:false,
    KundliMahaDashaPredictions:'',
    KundliAntarDasha:'',
    KundliMahaDasha:'',
    Ashtakvarga:'',
    Planetreport:'',
    Characteristics:'',
    MangalDosh:'',
    ManglikDosh:'',
    PitraDosh:'',
    KaalsarpDosh:'',
    gemSuggestion:'',
    gemNumeroTable:'',
    chartD9Image:''

}

export const HoroscopeReducer = createReducer(initialState,{

        ////start kundli////


        GemSuggestionSuccess: (state, action) => {
          state.loading = false;
          state.gemSuggestion = action.payload.response;
        },
        GemSuggestionFail: (state, action) => {
          state.gemSuggestion = '';
          state.error = action.payload;
          state.loading = false;
        },

        GemNumeroTableSuccess: (state, action) => {
          state.loading = false;
          state.gemNumeroTable = action.payload.response;
        },
        GemNumeroTableFail: (state, action) => {
          state.gemNumeroTable = '';
          state.error = action.payload;
          state.loading = false;
        },

////////////////////
        KaalsarpDoshSuccess: (state, action) => {
          state.loading = false;
          state.KaalsarpDosh = action.payload.response;
        },
        KaalsarpDoshFail: (state, action) => {
          state.KaalsarpDosh = '';
          state.error = action.payload;
          state.loading = false;
        },


        MangalDoshSuccess: (state, action) => {
          state.loading = false;
          state.MangalDosh = action.payload.response;
        },
        MangalDoshFail: (state, action) => {
          state.MangalDosh = '';
          state.error = action.payload;
          state.loading = false;
        },

        ManglikDoshSuccess: (state, action) => {
          state.loading = false;
          state.ManglikDosh = action.payload.response;
        },
        ManglikDoshFail: (state, action) => {
          state.ManglikDosh = '';
          state.error = action.payload;
          state.loading = false;
        },


        PitraDoshSuccess: (state, action) => {
          state.loading = false;
          state.PitraDosh = action.payload.response;
        },
        PitraDoshFail: (state, action) => {
          state.PitraDosh = '';
          state.error = action.payload;
          state.loading = false;
        },



        CharacteristicsSuccess: (state, action) => {
          state.loading = false;
          state.Characteristics = action.payload.response;
        },
        CharacteristicsFail: (state, action) => {
          state.Characteristics = '';
          state.error = action.payload;
          state.loading = false;
        },

        PlanetreportSuccess: (state, action) => {
          state.loading = false;
          state.Planetreport = action.payload.response;
        },
        PlanetreportFail: (state, action) => {
          state.Planetreport = '';
          state.error = action.payload;
          state.loading = false;
        },


        AshtakvargaSuccess: (state, action) => {
          state.loading = false;
          state.Ashtakvarga = action.payload.response;
        },
        AshtakvargaFail: (state, action) => {
          state.Ashtakvarga = '';
          state.error = action.payload;
          state.loading = false;
        },


        KundliMahaDashaSuccess: (state, action) => {
          state.loading = false;
          state.KundliMahaDasha = action.payload.response;
        },
        KundliMahaDashaFail: (state, action) => {
          state.KundliMahaDasha = '';
          state.error = action.payload;
          state.loading = false;
        },

        KundliAntarDashaSuccess: (state, action) => {
          state.loading = false;
          state.KundliAntarDasha = action.payload.response;
        },
        KundliAntarDashaFail: (state, action) => {
          state.KundliAntarDasha = '';
          state.error = action.payload;
          state.loading = false;
        },

        KundliMahaDashaPredictionsSuccess: (state, action) => {
          state.loading = false;
          state.KundliMahaDashaPredictions = action.payload.response;
        },
        KundliMahaDashaPredictionsFail: (state, action) => {
          state.KundliMahaDashaPredictions = '';
          state.error = action.payload;
          state.loading = false;
        },


        kundliBasicSuccess: (state, action) => {
          state.loading = false;
          state.kundliBasic = action.payload.response;
        },
        kundliBasicFail: (state, action) => {
          state.kundliBasic = '';
          state.error = action.payload;
          state.loading = false;
        },

        kundliDataSuccess: (state, action) => {
          state.kundliForm2=true;
          state.kundliFormdata2 = action.payload;
        },

        kundliDataGetSuccess: (state, action) => {
          state.kundliDataGetSuccess = action.payload;
        },

        kundliDataById: (state, action) => {
        
          state.GetKundliDataById = action.payload;
        },


        kundliDataFail: (state, action) => {
          state.kundliData = '';
          state.error = action.payload;
          state.loading = false;
        },


        kundliFormSet : (state,data) =>{
            state.kundliForm=true;
            state.kundliFormdata=data;
            state.kundliFormNext=true;

        }, 
    
     
        kundliFormNextStatus: state => {
          state.kundliFormNext = false;
          },
  

        ////end kundli////
            
        chartSuccess: (state, action) => {
          state.loading = false;
          // state.message = "sdhskhd";
          state.chartImage = action.payload;
        },
        chartFail: (state, action) => {
          state.loading = false;
          state.chartImage ='';
          state.error = action.payload;
        },

        chartD9Success: (state, action) => {
          state.loading = false;
          // state.message = "sdhskhd";
          state.chartD9Image = action.payload;
        },
        chartD9Fail: (state, action) => {
          state.loading = false;
          state.chartD9Image ='';
          state.error = action.payload;
        },

        dailypanchangSunSuccess: (state, action) => {
          state.loading = false;
          // state.message = "sdhskhd";
          state.dailypanchangs = action.payload.response;
        },
        dailypanchangSunFail: (state, action) => {
          state.loading = false;
          state.dailyPanchangs ='';
          state.error = action.payload;
        },


        dailySunSuccess: (state, action) => {
            state.loading = false;
            // state.message = "sdhskhd";
            state.daily = action.payload.response;
          },
        dailySunFail: (state, action) => {
            state.loading = false;
            state.daily =[];
            state.error = action.payload;
          },

        yearlyPredictionSuccess: (state, action) => {
            state.loading = false;
            // state.message = "sdhskhd";
            state.yearly = action.payload.response;
          },
        yearlyPredictionFail: (state, action) => {
            state.loading = false;
            state.yearly =[];
            state.error = action.payload;
          },


        weeklyPredictionSuccess: (state, action) => {
            state.loading = false;
            // state.message = "sdhskhd";
            state.weekly = action.payload.response;
          },
        weeklyPredictionFail: (state, action) => {
            state.loading = false;
            state.weekly =[];
            state.error = action.payload;
          },

        horoscopeLoadingRequest: state => {
            state.loading = true;
        },

        clearError: state => {
        state.error = null;
        },
        clearMessage: state => {
        state.message = null;
        },
});